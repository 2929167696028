import request from '@/utils/request'

// 角色列表
export function list(data) {
  return request({
    url: '/role/list',
    method: 'post',
    data
  })
}

// 角色详细
export function detail(id) {
  return request({
    url: '/role/detail',
    method: 'post',
    data: { id }
  })
}

// 保存角色
export function save(data) {
  return request({
    url: '/role/save',
    method: 'post',
    data: data
  })
}
// 删除
export function del(id) {
  return request({
    url: '/role/del',
    method: 'post',
    data: { id }
  })
}

// 角色列表
export function queryRoleAll() {
  return request({
    url: '/role/queryRoleAll',
    method: 'post',
    data: {}
  })
}



// 角色数据权限
export function dataScope(data) {
  return request({
    url: '/role/dataScope',
    method: 'put',
    data: data
  })
}

// 角色状态修改
export function changeRoleStatus(roleId, status) {
  const data = {
    roleId,
    status
  }
  return request({
    url: '/role/changeStatus',
    method: 'put',
    data: data
  })
}

