<template>
  <!-- 添加或修改参数配置对话框 -->
  <el-dialog
    :title="title"
    :visible.sync="show.userDetail"
    width="800px"
    append-to-body
    class="trace-detail"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item label="运动轨迹文件" prop="trackFile">
        <div class="upload-area">
          <video-file-single :fileObj="fileObj"></video-file-single>
        </div>
      </el-form-item>
      <el-form-item label="其他文件" prop="otherFile">
        <div class="upload-area">
          <video-file-single :fileObj="otherFileObj"></video-file-single>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">提 交</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as dataManageApi from '@/api/data-manage'
import VideoFileSingle from '@/views/order/components/video-info/VideoFileSingle.vue'
import { deepClone } from '@/utils/index'
export default {
  name: 'TrackDetail',
  components: { VideoFileSingle },
  props: {
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      fileObj: {
        limit: ['csv', 'jawmotion'],
        files: []
      },
      otherFileObj: {
        limit: ['rar', 'tiff', 'png', 'stl', 'pdf'],
        files: []
      },
      initForm: {
        name: undefined
      },
      form: {},
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
      }
    }
  },
  watch: {
    show: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal.userDetail) {
          this.init()
        }
      }
    }
  },
  methods: {
    init() {
      this.reset()
      this.detail(this.row)
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.form = deepClone(this.initForm)
      this.fileObj.files = []
      this.otherFileObj.files = []
    },
    detail(row) {
      if (row && row.id) {
        dataManageApi.detail(row.id).then((res) => {
          const { trackFile, otherFile, name, id } = res.data
          this.form = { name, id }
          this.fileObj.files = trackFile || []
          this.otherFileObj.files = otherFile || []
        })
      }
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          //赋值文件信息
          this.form.trackFile = this.fileObj.files
          this.form.otherFile = this.otherFileObj.files
          dataManageApi.edit(this.form).then((res) => {
            this.$message({ showClose: true, message: '保存成功', type: 'success' })
            this.$emit('flushList')
          })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.show.userDetail = false
    }
  }
}
</script>

<style lang="scss" scoped>
.trace-detail {
  .upload-area {
    margin-right: 40px;
    width: 240px;
    ::v-deep .el-upload {
      display: block;
    }
  }
  .popup-box {
    .el-button {
      margin-top: 20px;
    }
  }
}
</style>
