<template>
  <div class="batch-upload-list">
    <upload-file @handleFileList="handleFileList" :limit="limit" :progress="progress" uploadType="image">
      <el-button class="com-primary-btn" type="primary" icon="el-icon-circle-plus-outline">上传图片</el-button>
      <span class="com-msg">上传照片成后，拖拽到左侧指定位置保存</span>
    </upload-file>
    <div class="photo-arr">
      <div class="photo" v-for="(item, i) in fileList" :key="i" @dragstart="dragStartHandle(item)" @dragend="dragEndHandle(item)" draggable="true">
        <img :src="item.path" alt="" />
        <i class="iconfont icon-delete" @click="handleDelete(i)"></i>
        <el-progress :width="60" type="circle" :percentage="progress.value" v-if="!(progress.value == 100)"></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from '@/components/UploadFile/index'
export default {
  name: 'BatchUploadList',
  components: {
    UploadFile
  },
  data() {
    return {
      fileList: [],
      isDragging: false,
      limit: ['png', 'jpg', 'jpeg', 'gif'],
      progress: {
        value: ''
      }
    }
  },
  mounted() {},
  methods: {
    dragStartHandle(item) {
      if (!this.isDragging) {
        this.isDragging = true
        this.$bus.$emit('getDragImgInfo', item)
      }
    },
    dragEndHandle(item) {
      this.isDragging = false
      console.log(item, '结束')
    },
    // 删除图片
    handleDelete(i) {
      this.fileList.splice(i, 1)
    },
    // 上传图片列表
    handleFileList(file) {
      this.fileList.push(file)
    }
  }
}
</script>

<style lang="scss" scoped>
.batch-upload-list {
  margin-left: 20px;
  padding: 20px 20px 0px 20px;
  // width: 29%;
  width: 400px;
  box-sizing: border-box;
  background: #f9f9f9;
  // 一行显示几列
  $columnNum: 2;
  // 列间距
  $columnSpace: 16px;
  .com-msg {
    margin-left: 10px;
    font-size: 13px;
    color: #999999;
  }
  .photo-arr {
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: scroll;
    height: 420px;
    .photo {
      margin: 16px $columnSpace 0 0;
      height: 160px;
      width: calc((100% - #{($columnNum - 1) * $columnSpace}) / #{$columnNum});
      cursor: move;
      position: relative;
      &:nth-of-type(#{$columnNum}n) {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
      .icon-delete {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
      }
      ::v-deep .el-progress {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .el-progress__text {
          // display: none;
          color: #fff;
        }
      }
    }
  }
}
</style>
