<template>
  <div class="workbench-view">
    <div v-if="!showDialogDetail.dialogVisible">
      <div class="tab-bar">
        <el-tabs v-show="user.admin || user.amanager" v-model="tabName" @tab-click="tabHandleClick">
          <el-tab-pane label="待处理" name="first"></el-tab-pane>
          <el-tab-pane label="已处理" name="second"></el-tab-pane>
          <el-tab-pane label="未支付" name="three"></el-tab-pane>
        </el-tabs>

        <el-tabs v-show="user.aengineer" v-model="tabName" @tab-click="tabHandleClick">
          <el-tab-pane label="待处理" name="first"></el-tab-pane>
          <el-tab-pane label="处理中" name="second"></el-tab-pane>
          <el-tab-pane label="已处理" name="three"></el-tab-pane>
        </el-tabs>

        <el-tabs v-show="user.adoctor" v-model="tabName" @tab-click="tabHandleClick">
          <el-tab-pane label="待处理" name="first"></el-tab-pane>
          <el-tab-pane label="已处理" name="second"></el-tab-pane>
        </el-tabs>

        <!-- <ul class="second-tab-list" v-if="(user.admin || user.amanager) && isShow">
          <li :class="{ active: isChoose === 1 }" @click="selectTab(1)">订单跟踪</li>
          <li :class="{ active: isChoose === 2 }" @click="selectTab(2)">订单未付款</li>
        </ul> -->
      </div>
      <el-table :data="tableData" style="width: 100%" show-overflow-tooltip>
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="orderNumber" align="center" label="订单号" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="patientName" label="患者姓名" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="gender" label="性别"> </el-table-column>
        <el-table-column prop="phone" label="手机号" show-overflow-tooltip />
        <el-table-column prop="doctor" align="center" label="医生" show-overflow-tooltip />
        <el-table-column prop="engineer" label="工程师" show-overflow-tooltip />
        <el-table-column prop="orderType" label="订单类型" show-overflow-tooltip :formatter="(row) => typeFormatter(row, 'orderType', ORDER_TYPE)" />
        <el-table-column prop="status" label="交付状态" show-overflow-tooltip :formatter="(row) => typeFormatter(row, 'status', STATUS)" />
        <el-table-column v-if="selectTabType === 2" prop="payStatus" label="支付状态" show-overflow-tooltip />
        <el-table-column prop="createdAt" label="创建时间" show-overflow-tooltip />
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button v-if="tabName == 'first'" @click="handleClick(scope.row)" type="primary" plain size="small" style="background: #E0202020;">处理</el-button>
            <el-button v-else @click="handleClick(scope.row)" type="primary" plain size="small">详情</el-button>
            <el-button v-show=" scope.row && scope.row.status===1 && (user.admin || user.amanager)" @click="handleAssignClick(scope.row)" v-hasPermi="['order:allot']" type="primary" plain
              size="small">立即分配</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="!showDialogDetail.dialogVisible" :total="total" :page.sync="current" :limit.sync="size" @pagination="getList" />
    </div>
    <order-detail v-if="showDialogDetail.dialogVisible" :showDialogDetail="showDialogDetail"></order-detail>
    <assign v-if="showAssignDialog.dialogVisible" :showDialog="showAssignDialog" :operate="true" @callbackFunc="callbackFunc"></assign>
  </div>
</template>

<script>
import * as workbench from '@/api/workbench'
import OrderDetail from '../order/order-detail/OrderDetail.vue'
import Assign from '../order/components/order-assign/OrderAssign.vue'
import { ORDER_TYPE, STATUS } from '@/utils/constants.js'
import { loginConfirm } from '@/api/login.js'
export default {
  components: { OrderDetail, Assign },
  name: 'WorkbenchView',
  data() {
    return {
      ORDER_TYPE,
      STATUS,
      user: {},
      isShow: true,
      selectTypeName: '1',
      selectTabType: 1, //1 管理员-待处理-订单跟踪 2 管理员-待处理-订单付款 3 管理员-已完成 4 工程师-待处理 5 工程师-处理中
      //6 工程师-已完成 7 医生-待* 8 医生-已完成
      total: 0,
      current: 0,
      size: 10,
      tabName: 'first',
      showDialogDetail: {
        dialogVisible: false,
        orderType: 1,
        orderId: null
      },
      showAssignDialog: {
        dialogVisible: false,
        orderId: null,
        operateMode: null
      },
      tableData: [
        {
          orderNumber: '',
          patientName: '',
          gender: '',
          phone: '',
          doctor: '',
          engineer: '',
          status: 0,
          orderType: 0,
          createdAt: ''
        }
      ],
      firstTabs: [],
      secondTabs: [
        {
          name: '订单跟踪'
        },
        {
          name: '订单付款'
        }
      ],
      isChoose: 1
    }
  },
  mounted() {
    this.user = this.getUser()
    this.getList()
    this.$bus.$off('backList')
    this.$bus.$on('backList', (item) => {
      this.showDialogDetail.dialogVisible = item
      this.getList()
    })
    this.showConfirmLogin()
  },
  methods: {
    // 格式化状态值
    typeFormatter(row, prop, arr, filterKey, returnKey) {
      if (!row[prop] && row[prop] !== 0) {
        return ''
      }
      const item = arr.find((item) => Number(item[filterKey || 'value']) === Number(row[prop]))
      return item[returnKey || 'label']
    },
    getUser() {
      return JSON.parse(localStorage.getItem('userInfo'))
    },
    isDoctor() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      return userInfo.currentRole === '医生'
    },
    handleClick(row) {
      this.showDialogDetail.orderType = row.orderType
      this.showDialogDetail.orderId = row.id
      this.showDialogDetail.dialogVisible = true
      // this.showDialogDetail.showReport = false
      this.showDialogDetail.showReport = this.isDoctor()
    },
    handleAssignClick(data) {
      this.showAssignDialog.orderId = data.id
      this.showAssignDialog.dialogVisible = true
      this.showAssignDialog.operateMode = data.status
    },
    tabHandleClick() {
      this.showDialogDetail.dialogVisible = false
      if (this.user.admin || this.user.amanager) {
        if (this.tabName === 'first') {
          this.selectTabType = 1
          this.isChoose = 1
        } else if (this.tabName === 'three') {
          this.selectTabType = 1
          this.isChoose = 2
        }
      }
      this.getList()
    },
    selectTab(type) {
      this.showDialogDetail.dialogVisible = false
      this.selectTabType = type
      this.isChoose = type
      this.getList()
    },
    getList() {
      this.tableData = null
      const tabName = this.tabName
      if (this.user.admin || this.user.amanager) {
        if (tabName === 'first') {
          this.isShow = true
        } else if (tabName === 'three') {
          this.isShow = true
          this.selectTabType = 2
        } else {
          this.isShow = false
          console.log(this.isShow, '-----')
          this.selectTabType = 3
        }
      } else if (this.user.aengineer) {
        this.isShow = false
        if (tabName === 'first') {
          this.selectTabType = 4
        } else if (tabName === 'second') {
          this.selectTabType = 5
        } else {
          this.selectTabType = 6
        }
      } else if (this.user.adoctor) {
        this.isShow = false
        if (tabName === 'first') {
          this.selectTabType = 7
        } else {
          this.selectTabType = 8
        }
      } else {
        //不是管理员 医生 工程师 直接返回null
        return null
      }
      let params = { current: this.current, size: this.size, type: this.selectTabType }
      workbench.workBenchList(params).then((res) => {
        this.tableData = res.data.rows
        this.current = res.data.current
        this.total = res.data.total
        this.size = res.data.size
      })
    },
    callbackFunc() {
      this.showAssignDialog.dialogVisible = false
      this.getList()
    },
    // 显示修改初始密码
    showConfirmLogin() {
      if (!this.user.confirmLogin) {
        this.$confirm('为了账户安全，请您修改初始密码?', '提示', {
          confirmButtonText: '不在提示',
          cancelButtonText: '知道了',
          cancelButtonClass: 'btn-custom-cancel',
          type: 'warning'
        })
          .then(() => {
            this.user.confirmLogin = true
            localStorage.setItem('userInfo', JSON.stringify(this.user))
            loginConfirm({})
              .then((res) => {})
              .finally(() => {})
          })
          .catch(() => {
            this.user.confirmLogin = true
            localStorage.setItem('userInfo', JSON.stringify(this.user))
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.workbench-view {
  .tab-bar {
    margin-bottom: 12px;
    padding: 10px 20px 16px;
    background-color: #fff;
    ::v-deep .el-tabs {
      margin-left: 20px;
    }
    .second-tab-list {
      padding: 8px 20px;
      height: 40px;
      display: flex;
      box-sizing: border-box;
      background: #f9f9f9;
      li {
        margin-right: 24px;
        line-height: 24px;
        cursor: pointer;
        &.active {
          font-weight: bold;
          color: $deepPrimaryColor;
        }
      }
    }
  }
}
</style>

