<template>
  <ul class="video-img-list">
    <li v-for="(item, i) in imgArr" :key="i" >
      <video-img-single :currentImgInfo="item.files[0]" :mode="item.mode"></video-img-single>
      <p class="title">{{ item.mode }}</p>
    </li>
  </ul>
</template>

<script>
import VideoImgSingle from './VideoImgSingle.vue'
export default {
  name: 'VideoImgList',
  props: {
    imgArr: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    VideoImgSingle
  },
  data() {
    return {
      isDragging: false
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.video-img-list {
  margin-top: -24px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  li {
    margin: 24px 40px 0 0;
    text-align: center;
    li:nth-child(3n) {
      margin-right: 0;
    }
    .photo {
      margin-bottom: 8px;
      width: 240px;
      height: 160px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dddddd;
      position: relative;
      > img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .title {
      line-height: 20px;
      &:before {
        content: '*';
        color: #e02020;
        margin-right: 4px;
      }
    }
  }
}
</style>
