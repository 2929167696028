<template>
  <div class="video-info">
    <!-- 图片上传 -->
    <div class="photo-show">
      <!-- 左侧图片 -->
      <div :class="formData.isCanUpload ? 'left-area left-area-scroll' : 'left-area'">
        <div class="grey-box grey-box-mar" v-for="(item, i) in formData.imageReport.slice(0, 1)" :key="i">
          <h2 class="h2-title">{{ item.mode }}</h2>
          <video-img-list :imgArr="item.children"></video-img-list>
        </div>
        <div class="merge-show">
          <div class="grey-box" v-for="(item, i) in formData.imageReport.slice(1, formData.imageReport.length)" :key="i">
            <h2 class="h2-title">{{ item.mode }}</h2>
            <video-img-list :imgArr="item.children"></video-img-list>
          </div>
        </div>
      </div>
      <!-- 右侧照片墙 -->
      <batch-upload-list v-if="formData.isCanUpload"></batch-upload-list>
    </div>
    <!-- 文件上传 -->
    <template v-if="orderType === 4">
      <div class="file-show" style="padding-top:0;">
        <div class="grey-box" v-for="(item, i) in formData.fileReport.slice(0, 1)" :key="i">
          <h2 class="h2-title-border"><span>{{ item.mode }}</span></h2>
          <video-file-list :fileArr="item.children" :mode="item.mode" :formData="formData" :isSend="item.isSend"></video-file-list>
        </div>
      </div>
      <div class="file-show merge-show">
        <div class="grey-box" v-for="(item, i) in formData.fileReport.slice(1, formData.fileReport.length)" :key="i + 1">
          <h2 class="h2-title-border"><span>{{ item.mode }}</span></h2>
          <video-file-list :fileArr="item.children" :mode="item.mode" :formData="formData" :isSend="item.isSend"></video-file-list>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="file-show merge-show">
        <div class="grey-box" v-for="(item, i) in formData.fileReport" :key="i">
          <h2 class="h2-title-border"><span>{{ item.mode }}</span></h2>
          <video-file-list :fileArr="item.children" :mode="item.mode" :formData="formData" :isSend="item.isSend"></video-file-list>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BatchUploadList from './BatchUploadList.vue'
import VideoImgList from './VideoImgList.vue'
import VideoFileList from './VideoFileList.vue'
export default {
  name: 'VideoInfo',
  components: {
    BatchUploadList,
    VideoImgList,
    VideoFileList
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    orderType: {
      type: Number,
      default: null
    }
  },
  provide() {
    const { isCalDownload, isCanUpload, isCanDel } = this.formData
    return {
      // 获取字段数据时需要sourceId
      isDisabled: this.isDisabled,
      isCalDownload,
      isCanUpload,
      isCanDel,
      formData: this.formData
    }
  },

  computed: {
    isDisabled() {
      // isCanEdit：0: 不可编辑； 1: 可编辑
      return this.formData.isCanEdit === 0
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.video-info {
  padding: 0 20px 20px;
  .merge-show {
    display: flex;
    background-color: #f9f9f9;
    position: relative;
    .h2-title {
      border: none !important;
    }
    &::before {
      position: absolute;
      left: 0;
      top: 56px;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #dddddd;
      content: '';
    }
  }
  .grey-box {
    background: #f9f9f9;
    &-mar {
      & + .grey-box {
        margin-top: 16px;
      }
    }
    .h2-title,
    .h2-title-border {
      padding: 16px 20px;
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      line-height: 25px;
      border-bottom: 1px solid #dddddd;
    }
  }
  .photo-show {
    margin-bottom: 24px;
    display: flex;
    .left-area {
      flex: 1;
      .merge-show {
        margin-top: 24px;
        .grey-box {
          // flex-grow: 1;
          ::v-deep .video-img-list {
            li:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .left-area-scroll {
      overflow: scroll;
      height: 500px;
    }
  }
  .file-show {
    padding: 16px 0;
    .h2-title-border {
      span {
        display: block;
        padding-left: 8px;
        border-left: 8px solid $primaryColor;
        line-height: 20px;
        margin-top: 5px;
      }
    }
    &.merge-show {
      padding: 0;
    }
  }
}
</style>
