<template>
  <el-form-item label="上传附件" style="margin-top: 15px">
    <el-upload
      class="image-uploader-box"
      :http-request="ossUpload"
      :show-file-list="false"
      :before-upload="beforeUpload"
      action="/api/upload/sts"
      multiple
    >
      <el-button type="primary">上传</el-button>
    </el-upload>
  </el-form-item>
</template>

<script>
import { sts } from '@/api/upload'
const OSS = require('ali-oss')
const options = {
  // 获取分片上传进度、断点和返回值。
  progress: (p, cpt, res) => {
    console.log(p)
  },
  // 设置并发上传的分片数量。
  parallel: 4,
  // 设置分片大小。默认值为1 MB，最小值为100 KB。
  partSize: 1024 * 1024
}
export default {
  name: 'UploadFile',
  props: {
    file: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  methods: {
    beforeUpload(file) {
      // console.log(file)
      // const fileTypeList = ['image/jpeg', 'image/png', 'image/gif'];
      // const isLimitType = fileTypeList.indexOf(file.type) > -1;
      // const isLimitM = file.size / 1024 / 1024 < this.limitM;
      // if (!isLimitType) {
      //   this.$message.error('支持格式为jpeg、jpg、png、gif');
      // }
      // if (!isLimitM) {
      //   this.$message.error(`最大可上传${this.limitM}MB`);
      // }
      // return isLimitType && isLimitM;
    },
    /**
     * 分片上传
     *
     * @param {文件} file
     */
    ossUpload(fileInfo) {
      const file = fileInfo.file
      sts()
        .then((res) => {
          const { data } = res
          const ossClient = this.newOssClient(data)
          const folder = data.folder || ''
          if (ossClient) {
            // 分片上传
            ossClient
              .multipartUpload(folder + file.name, file, options)
              .then((result) => {
                this.file = {
                  size: file.size,
                  name: file.name,
                  path: result.res.requestUrls[0]
                }
              })
              .catch((err) => {
                console.log('上传失败', err)
              })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    /**
     * 创建ossClient
     *
     * @param {临时秘钥} data
     * @returns
     */
    newOssClient({ bucket, regionId, keyId, keySecret, stsToken }) {
      return new OSS({
        region: regionId,
        accessKeyId: keyId,
        accessKeySecret: keySecret,
        secure: true,
        refreshSTSToken: async () => {
          // 向您搭建的STS服务获取临时访问凭证。
          const res = await sts()
          const { keyId, keySecret, stsToken } = res.data
          return {
            accessKeyId: keyId,
            accessKeySecret: keySecret,
            stsToken: stsToken
          }
        },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 1200000,
        stsToken,
        bucket
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
