<template>
  <div class="doctor-statistic">
    <div class="com-page-nav"><span>数据统计 &gt;</span> 医生业绩统计</div>
    <el-tabs :value="tagItem.value" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tags"
        :key="item.value"
        :label="item.label"
        :name="item.value"
      ></el-tab-pane>
    </el-tabs>

    <div class="main-area">
      <div class="filter-bar">
        <div class="filter-box">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="日期" style="width: 400px">
              <el-date-picker
                v-model="searchForm.start"
                :value-format="tagItem.valueFormat"
                :type="tagItem.dateType"
                placeholder="开始时间"
              >
              </el-date-picker>
              至
              <el-date-picker
                v-model="searchForm.end"
                :value-format="tagItem.valueFormat"
                :type="tagItem.dateType"
                placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="searchForm.doctor"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
              <export-button
                :params="searchForm"
                url="/web/analysis/doctor/export"
                v-hasPermi="['doctor:export']"
                fileName="医生业绩统计"
              ></export-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table v-loading="loading" :data="list" style="width: 100%">
        <el-table-column prop="date" label="统计日期" width="150">
          <template slot="header">
            <el-popover placement="bottom" trigger="hover">
              <div style="display: flex; flex-direction: column;"><span>按订单创建日期进行统计</span></div>
              <span style="font-size: 14px; display: flex;" slot="reference">统计日期
                <img style="margin-top: 4px; margin-left: 5px;" src='../../assets/img/question/tip-question.png' width="15px" height="15px" /></span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="doctor" label="医生"></el-table-column>
        <el-table-column prop="receivedAmount" label="已付款金额">
          <template slot="header">
            <el-popover placement="bottom" trigger="hover">
              <div style="display: flex; flex-direction: column;"><span>在统计日期范围内，支付状态为“已支付”的全部订单“应收金额”的总额</span></div>
              <span style="font-size: 14px; display: flex;" slot="reference">已收款金额
                <img style="margin-top: 4px; margin-left: 5px;" src='../../assets/img/question/tip-question.png' width="15px" height="15px" /></span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="uncollectedAmount" label="未付款金额">
          <template slot="header">
            <el-popover placement="bottom" trigger="hover">
              <div style="display: flex; flex-direction: column;"><span>在统计日期范围内，支付状态为“未支付”的全部订单“应收金额”的总额</span></div>
              <span style="font-size: 14px; display: flex;" slot="reference">未收款金额
                <img style="margin-top: 4px; margin-left: 5px;" src='../../assets/img/question/tip-question.png' width="15px" height="15px" /></span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="totalOrders" label="订单总数">
          <template slot="header">
            <el-popover placement="bottom" trigger="hover">
              <div style="display: flex; flex-direction: column;"><span>在统计日期范围内，创建的订单总数量。</span></div>
              <span style="font-size: 14px; display: flex;" slot="reference">订单总数
                <img style="margin-top: 4px; margin-left: 5px;" src='../../assets/img/question/tip-question.png' width="15px" height="15px" /></span>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import * as analysisApi from '@/api/analysis.js'
import { tags, resetStartEnd, validTime } from './statistics'
import ListUtils from '@/mixins/list-utils'
export default {
  name: 'DoctorStatistics',
  mixins: [ListUtils],
  data() {
    return {
      tags,
      searchForm: {
        granularity: 1,
        doctor: undefined,
        start: undefined,
        end: undefined
      },
      tagItem: {}
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // 初始化标签选项
      this.tagItem = this.tags[0]
      this.searchForm.granularity = this.tagItem.value
      // 重置开始时间，结束时间
      resetStartEnd(this.tagItem, this.searchForm)
      // 查询列表
      this.getList()
    },
    handleClick(tab) {
      // 切换标签页
      this.tagItem = this.tags.find((item) => item.value == tab.name)
      this.searchForm.granularity = this.tagItem.value
      // 重置开始时间，结束时间
      resetStartEnd(this.tagItem, this.searchForm)
      // 查询数据
      this.getList()
    },
    // 获取标签列表
    getList() {
      this.loading = true
      analysisApi.doctor(this.searchForm).then((res) => {
        const { list } = res.data
        this.list = list || []
        this.loading = false
      })
    },
    onSearch() {
      // 1- 校验开始时间，结束时间
      validTime(this.searchForm.start, this.searchForm.end, (result, msg) => {
        if (result) {
          this.getList()
        } else {
          this.$message({
            showClose: true,
            message: msg,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.doctor-statistic {
}
</style>
