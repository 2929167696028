<template>
  <el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="demo-ruleForm">
    <el-form-item prop="phone">
      <el-input class="phone" v-model="ruleForm.phone" placeholder="*手机号"></el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input class="code" v-model="ruleForm.code" placeholder="*验证码"></el-input>
      <el-button v-if="codeState" type="primary" @click="sendSmsForUserValidation">
        发送验证码
      </el-button>
      <el-button v-else type="info" disabled>{{ second }} s</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import * as loginApi from '@/api/login'
export default {
  name: 'FillPhoneNumber',
  data() {
    return {
      ruleForm: {
        phone: '',
        code: ''
      },
      rules: {
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            pattern: /^1(1|2|3|4|5|6|7|8|9|0)\d{9}$/,
            message: '手机号格式错误',
            trigger: 'blur'
          }
        ]
      },
      codeRules: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      codeState: true,
      second: 60
    }
  },
  mounted() {},
  methods: {
    // 发送验证码
    sendSmsForUserValidation() {
      try {
        this.rules.code = undefined
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            loginApi.getCode(this.ruleForm.phone).then((res) => {
              this.rules.code = this.codeRules
              this.timeStart()
              this.$message.success('发送成功')
            })
          } else {
            this.$message.error('发送失败')
            return false
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 校验验证码
    validCode(callback) {
      try {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            loginApi.checkCode(this.ruleForm).then((res) => {
              if (callback) {
                callback(res.data)
                this.$message.success('验证成功')
              }
            })
          } else {
            this.$message.error('验证失败')
            return false
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    timeStart() {
      this.codeState = false
      var time = setInterval(() => {
        if (this.second <= 0) {
          clearInterval(time)

          this.codeState = true
          this.second = 60
        }
        this.second--
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  ::v-deep .el-form-item {
    .el-form-item__content {
      display: flex;
      justify-content: space-between;
      .el-button {
        margin-left: 10px;
        width: 180px;
      }
    }
  }
}
</style>
