<template>
  <div class="tag-view">
    <div class="com-page-nav">{{ $route.meta.title || '标签管理' }}</div>
    <div class="main-area com-panel-area">
      <el-tabs v-model="type" @tab-click="handleClick">
        <el-tab-pane label="数据标签" name="1"></el-tab-pane>
        <el-tab-pane label="图片标签" name="2"></el-tab-pane>
      </el-tabs>
      <div class="search-box" v-if="type == 1">
        <el-input
          placeholder="请输入关键词"
          suffix-icon="el-icon-search"
          v-model="pageParam.tagName"
          clearable
          @change="getList"
        >
        </el-input>
        <el-button
          class="com-primary-btn"
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="addTag"
          >新建标签</el-button
        >
      </div>
      <div class="sys-tag">
        <h2 class="h2-title">系统标签</h2>
        <ul class="sys-tag-list">
          <li v-for="(item, index) in systemTags" :key="index">{{ item.tagName }}</li>
        </ul>
      </div>
      <div class="customer-tag" v-if="type == 1">
        <h2 class="h2-title">自定义标签</h2>
        <ul class="customer-tag-list">
          <li v-for="item in customTags" :key="item.id">
            <el-popover popper-class="opt-popper" placement="right" width="80" trigger="hover">
              <ul class="operate-btn-list">
                <li class="rename-btn" @click="handleRename(item)">重命名</li>
                <li class="delete-btn" @click="handleDelete(item)">删除</li>
              </ul>
              <el-button type="primary" plain slot="reference">
                <!-- <el-input v-model="item.name" placeholder=""></el-input> -->
                <p>{{ item.tagName }}<i class="el-icon-more"></i></p>
              </el-button>
            </el-popover>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog :title="dialogName" :visible.sync="dialogVisible" width="300px" @close="handleClose">
      <el-input v-model="form.tagName" maxlength="10" placeholder="请输入标签名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmModify" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as tags from '@/api/tags.js'
export default {
  name: 'TagView',
  data() {
    return {
      type: '1', // tab
      customTags: [
        {
          id: '1',
          tagName: '标签5个字'
        }
      ],
      systemTags: [],
      dialogVisible: false,
      dialogName: '新增',
      // 增删改查参数
      form: {
        tagName: ''
      },
      pageParam: {
        tagName: '', // 数据名称
        type: 1 // 类型 1数据标签 2 商品标签
      },
      editTag: 'add',
      loading: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleClick(tab) {
      this.pageParam.type = Number(tab.name)
      this.getList()
    },
    // 获取标签列表
    getList() {
      tags.getTagsList(this.pageParam).then((res) => {
        const tagObj = this.convertTags(res.data)
        this.systemTags = tagObj[0] || []
        this.customTags = tagObj[1] || []
      })
    },
    convertTags(rows) {
      const tagObj = {}
      rows.forEach((item) => {
        if (!tagObj[item.channel]) {
          tagObj[item.channel] = []
        }
        tagObj[item.channel].push(item)
      })
      return tagObj
    },
    // 新增标签
    addTag() {
      this.dialogName = '新增'
      this.dialogVisible = true
    },
    // 重命名
    handleRename(item) {
      this.dialogName = '重命名'
      this.dialogVisible = true
      this.form = Object.assign(this.form, item)
    },
    // 删除标签
    handleDelete(item) {
      const params = Object.assign(item, { deleteStatus: 1, type: this.pageParam.type })
      tags.editTags(params).then((res) => {
        this.$message.success('删除' + res.msg)
      })
      setTimeout(() => {
        this.getList()
      }, 1000)
    },
    // 提交确认
    confirmModify() {
      if (!this.form.tagName) {
        this.$message.warning('标签名称不能为空')
      } else {
        this.loading = true
        // 调用编辑接口
        const url = this.dialogName === '新增' ? 'addTags' : 'editTags'
        tags[url]({ ...this.form, type: this.pageParam.type })
          .then((res) => {
            this.$message.success(this.dialogName + res.msg)
            // 调用列表接口
            this.getList()
          })
          .finally(() => {
            this.loading = false
            this.dialogVisible = false
          })
      }
    },
    handleClose() {
      this.form.tagName = ''
      delete this.form.id
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-view {
  .main-area {
    padding-left: 40px;
    ::v-deep .el-tabs {
      margin-bottom: 24px;
      .el-tabs__header {
        margin-bottom: 0;
      }
    }
    .search-box {
      margin-bottom: 24px;
      display: flex;
      ::v-deep .el-input {
        margin-right: 24px;
        width: 200px;
        .el-input__inner {
          height: 36px;
          line-height: 36px;
        }
      }
    }
    .h2-title {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 20px;
    }
    %tagStyle {
      min-width: 72px;
      height: 32px;
      font-size: 14px;
      font-weight: 400;
      color: $fontDeepColor;
      line-height: 32px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 3px;
    }
    .sys-tag {
      margin: 0 0 20px -20px;
      padding: 10px 20px 24px;
      background: #f9f9f9;
      &-list {
        display: flex;
        flex-wrap: wrap;
        li {
          padding: 0 15px;
          margin: 5px 5px;
          @extend %tagStyle;
          background: #eeeeee;
        }
      }
    }
    .customer-tag {
      &-list {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-right: 8px;
          position: relative;
          @extend %tagStyle;
          background: #eff7f7;
          ::v-deep .el-button {
            border: none;
          }
          .el-icon-more {
            position: absolute;
            right: 1px;
            top: 50%;
            color: #cccccc;
            transform: translate(0, -50%) rotate(90deg);
          }
          .el-button {
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.opt-popper {
  padding: 8px 10px;
  min-width: auto !important;
  box-sizing: border-box;
  .operate-btn-list {
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    li {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .rename-btn {
      margin-bottom: 6px;
      padding-bottom: 6px;
      color: $primaryColor;
      border-bottom: 1px solid #dddddd;
    }
    .delete-btn {
      color: $--color-danger;
    }
  }
}
</style>
