<!--
 * @Author: Ellie
 * @Date: 2022-02-10
 * @LastEditTime: 2022-02-11
 * @LastEditors: Please set LastEditors
 * @Description: 订单管理 > 订单列表
 * @FilePath: src/views/order/order-list/OrderList.vue
-->
<template>
  <div class="order-list">
    <div class="com-page-nav" v-if="!showDialogDetail.dialogVisible">
      <span>订单管理 &gt;</span> 订单列表
    </div>
    <div class="com-page-nav" v-else>
      <span>订单管理 &gt;</span><span class="nav-link" @click="backToList(false)"> 订单列表 &gt;</span> 订单详情
    </div>
    <div class="main-area" v-if="!showDialogDetail.dialogVisible">
      <div class="filter-bar">
        <div class="btn-box" v-hasPermi="['order:list:batchSettle']">
          <el-button @click="openMultiPay" class="com-primary-btn" type="primary"><i class="iconfont icon-pay"></i>批量结账</el-button>
        </div>
        <div class="filter-box">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline" @keyup.enter.native="getList">
            <el-form-item label="订 单 号" label-width="70px">
              <el-input v-model="searchForm.orderNumber" clearable placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="患 者" label-width="70px">
              <el-input v-model="searchForm.patientName" clearable placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="医 生" label-width="70px">
              <el-input v-model="searchForm.doctorName" clearable placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="工 程 师" label-width="70px">
              <el-input v-model="searchForm.engineerName" clearable placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="业 务 员" label-width="70px">
              <el-input v-model="searchForm.salesmanName" clearable placeholder="请输入"></el-input>
            </el-form-item>
            <template v-if="expand">
              <el-form-item label="机构名称" label-width="70px">
                <el-input v-model="searchForm.doctorCompany" clearabl placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="订单类型" label-width="70px">
                <el-select v-model="searchForm.orderType" clearable placeholder="请选择">
                  <el-option v-for="item in ORDER_TYPE" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="小类型">
                <el-input v-model="searchForm.littleType" clearable></el-input>
              </el-form-item> -->
              <el-form-item label="交付状态" label-width="70px">
                <el-select v-model="searchForm.status" placeholder="请选择" clearable>
                  <el-option v-for="item in STATUS" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付状态" label-width="70px">
                <el-select v-model="searchForm.payStatus" clearable placeholder="请选择">
                  <el-option v-for="item in PAY_STATUS" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单状态" label-width="70px">
                <el-select v-model="searchForm.orderStatus" clearable placeholder="请选择">
                  <el-option v-for="item in ORDER_STATUS" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="收费方式" label-width="70px">
                <el-select v-model="searchForm.payMethod" clearable placeholder="请选择">
                  <el-option v-for="item in PAY_METHOD" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="标 签" label-width="70px">
                <el-select v-model="searchForm.tagsId" multiple clearable placeholder="请选择">
                  <el-option v-for="item in customTags" :key="item.id" :label="item.tagName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="应收金额" label-width="70px">
                <el-input class="small-input" v-model="searchForm.realAmountMix" placeholder="最小值" type="number"></el-input>
                -
                <el-input class="small-input" v-model="searchForm.realAmountMax" placeholder="最大值" type="number"></el-input>
              </el-form-item>
              <el-form-item label="收款时间" label-width="70px">
                <el-date-picker v-model="collectionTime" prefix-icon="el-icon-date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" @change="handleCollectionTime">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="订单创建时间" clearable>
                <el-date-picker v-model="createTime" prefix-icon="el-icon-date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" @change="handleCreateTime">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="订单确认时间" clearable>
                <el-date-picker v-model="confirmTime" prefix-icon="el-icon-date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" @change="handleConfirmTime">
                </el-date-picker>
              </el-form-item>
            </template>
            <el-form-item>
              <el-button type="primary" @click="getList">查询</el-button>
              <export-button :params="searchForm" url="/web/order/list/export" v-hasPermi="['order:list:export']" fileName="订单列表"></export-button>
            </el-form-item>
            <div :class="{ 'order-list-expand': true, 'order-list-fold': expand }" @click="handleExpand">
              <img src="@/assets/img/order/xiala.png" />
              <img src="@/assets/img/order/xiala.png" />
            </div>
          </el-form>
        </div>
      </div>
      <el-table ref="singleTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange" v-loading="loading">
        <el-table-column type="selection" width="55" v-if="userInfo.currentRole === '管理员'"></el-table-column>
        <el-table-column prop="orderNumber" label="订单编号" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createdAt" label="创建时间" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="doctor" label="医生" show-overflow-tooltip></el-table-column>
        <!-- 未对接 -->
        <el-table-column prop="company" label="机构名称" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="patientName" label="患者" show-overflow-tooltip></el-table-column>
        <el-table-column prop="orderType" label="订单类型" width="150" :formatter="(row) => typeFormatter(row, 'orderType', ORDER_TYPE)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="orderTypeTwo" label="小类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="engineer" label="工程师" show-overflow-tooltip></el-table-column>
        <el-table-column prop="salesman" label="业务员" show-overflow-tooltip></el-table-column>
        <el-table-column prop="amountPaid" label="应收金额" show-overflow-tooltip width="90"></el-table-column>
        <el-table-column prop="payStatus" label="支付状态" width="90" :formatter="(row) => typeFormatter(row, 'payStatus', PAY_STATUS)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="payMethod" label="收费方式" width="90" :formatter="(row) => typeFormatter(row, 'payMethod', PAY_METHOD)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" width="90" :formatter="(row) => typeFormatter(row, 'orderStatus', ORDER_STATUS)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="交付状态" width="120" :formatter="(row) => typeFormatter(row, 'status', STATUS)" show-overflow-tooltip></el-table-column>
        <!-- 未对接 -->
        <el-table-column prop="confirmTime" label="订单确认时间" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="collectionAt" label="订单收款时间" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="tagName" label="标签" width="160">
          <template slot-scope="scope">
            <order-list-tags :tagName="scope.row.tagName"></order-list-tags>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="340" align="left">
          <template slot-scope="scope">
            <el-button @click="viewDetail(scope.row)" type="primary" plain size="small">详情</el-button>
            <el-button @click="viewFiles(scope.row)" type="primary" plain size="small">文件</el-button>
            <el-button @click="viewTags(scope.row)" type="primary" plain size="small" v-hasPermi="['order:list:tag']">标签</el-button>
            <!-- <br /> -->
            <el-button v-if="scope.row.showReport" @click="viewReport(scope.row)" type="primary" plain size="small">报告</el-button>
            <el-button @click="view3DFile(scope.row)" type="primary" plain size="small" v-if="scope.row.show3D">3D</el-button>
            <el-button @click="openSharePay(scope.row)" type="primary" plain size="small" v-if="scope.row.showBill">结账</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.size" @pagination="getList" />
    </div>
    <!-- 详情 -->
    <order-detail v-if="showDialogDetail.dialogVisible" :showDialogDetail="showDialogDetail"></order-detail>
    <!-- 查看文件 -->
    <order-file-list v-if="showDialogFile.dialogVisible" :showDialog="showDialogFile"></order-file-list>
    <!-- 编辑标签 -->
    <order-tags v-if="showDialogTags.dialogVisible" :showDialog="showDialogTags" @flushList="getList()"></order-tags>
    <!-- 3D查看 -->
    <order-model-stl v-if="showDialog3D.dialogVisible" :showDialog="showDialog3D"></order-model-stl>
    <!-- 批量结账 -->
    <el-dialog title="订单结账" :visible.sync="dialogVisible" width="300px">
      <p style="margin-bottom: 8px">已勾选未支付订单： {{ multipleSelection.length }} 个</p>
      <el-select v-model="payMethod" placeholder="">
        <el-option v-for="item in PAY_METHOD" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="multiPay">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分享结账 -->
    <order-share-pay v-if="showDialogShare.dialogVisible" :showDialog="showDialogShare"></order-share-pay>
  </div>
</template>

<script>
import OrderFileList from './OrderFileList.vue'
import OrderDetail from '../order-detail/OrderDetail.vue'
import OrderModelStl from './OrderModelStl.vue'
import OrderSharePay from './OrderSharePay.vue'
import OrderTags from './OrderTags.vue'
import ExportButton from '@/components/ExportButton/index.vue'
import { orderList, settlePre } from '@/api/order.js'
import * as tagsApi from '@/api/tags.js'
import ListUtils from '@/mixins/list-utils'
import { getArrIds } from '@/utils/index.js'
import { ORDER_TYPE, PAY_STATUS, ORDER_STATUS, STATUS, PAY_METHOD } from '@/utils/constants.js'
import OrderListTags from './OrderListTags.vue'
export default {
  name: 'OrderList',
  mixins: [ListUtils],
  components: {
    OrderFileList,
    OrderModelStl,
    OrderDetail,
    OrderSharePay,
    OrderTags,
    ExportButton,
    OrderListTags
  },
  data() {
    return {
      // 状态值
      ORDER_TYPE,
      PAY_STATUS,
      ORDER_STATUS,
      STATUS,
      PAY_METHOD,
      showDialogFile: {
        dialogVisible: false,
        id: null
      },
      showDialogDetail: {
        dialogVisible: false,
        orderType: 1,
        orderId: null
      },
      showDialogTags: {
        dialogVisible: false
      },
      showDialog3D: {
        dialogVisible: false,
        fileUrl: '',
        url3DList: [],
        fileUrlList: []
      },
      showDialogShare: {
        dialogVisible: false,
        orderNumber: '',
        payMethod: '',
        id: ''
      },
      multipleSelection: [],
      payMethod: 1,
      dialogVisible: false,
      searchForm: {
        orderNumber: null, // 订单号
        patientName: null, // 患者姓名
        doctorName: null, // 医生姓名
        engineerName: null, // 工程师姓名
        salesmanName: null, // 业务员姓名
        doctorCompany: null, // 医生工作单位
        realAmountMix: null, // 应收金额最小
        realAmountMax: null, // 应收金额最大
        // realAmount: null, // 应收金额
        orderType: null, // 订单类型 1外科订单 2阻鼾器订单 3颌垫订单  4辅助分析分析
        littleType: null, // 小类型 直接写文本
        status: null, // 交付状态 1 待分配  2 订单待处理  3 设计手术方案中 4 设计导板中 5 审核过单 6 确认方案和价格中 7 手术模板和导板打印中 8 交付已完成（手术模板和导板打印已完成）  9 物品寄送中  10 物品已签收 11 制作阻鼾器中 12 交付已完成（方案和价格已确认）13 设计颌垫方案中 14 制作颌垫中 15 交付已完成（颌垫制作已完成） 16 辅助分析处理中 17 审核过单（2次审核）
        payStatus: null, // 支付状态 1已支付 0未支付
        orderStatus: null, // 订单状态 1已完成 0未完成
        payMethod: null, // 收费方式
        orderCreatedStart: null, // 订单创建时间开始
        orderCreatedEnd: null, // 订单创建时间结束
        orderConfirmStart: null, // 订单确认时间开始
        orderConfirmEnd: null, // 订单确认时间结束
        collectionAtStartTime: null, // 订单收款开始时间
        collectionAtEndTime: null, // 订单收款结束时间
        tagsId: null // 标签
      },
      createTime: [],
      confirmTime: [],
      collectionTime: [],
      loadingExport: false,
      expand: false
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    this.getList()
    this.getTagList()
    // 卸载掉事件，防止多次注册
    this.$bus.$off('backList')
    this.$bus.$on('backList', (item) => {
      this.showDialogDetail.dialogVisible = item
      this.getList()
    })
  },
  methods: {
    // 格式化状态值
    typeFormatter(row, prop, arr, filterKey, returnKey) {
      if (!row[prop] && row[prop] !== 0) {
        return ''
      }
      const item = arr.find((item) => Number(item[filterKey || 'value']) === Number(row[prop]))
      return item[returnKey || 'label']
    },
    // 获取标签列表
    getTagList() {
      let params = { type: 1 }
      tagsApi.getTagsList(params).then((res) => {
        this.customTags = res.data
      })
    },
    // 获取列表数据
    getList() {
      this.loading = true
      this.list = []
      orderList({ ...this.listQuery, ...this.searchForm })
        .then((res) => {
          const { rows, total } = res.data
          this.list = rows
          this.total = total
        })
        .finally(() => {
          this.loading = false
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout() // 解决表格错位
          })
        })
    },
    // 批量结账
    openMultiPay() {
      if (this.multipleSelection.length === 0) {
        this.$alert('请先勾选未支付订单', '提示', {
          confirmButtonText: '知道了',
          type: 'warning',
          callback: () => {}
        })
        // this.$message.warning('请先勾选未支付订单')
        return
      }
      this.dialogVisible = true
    },
    multiPay() {
      const orderIds = getArrIds(this.multipleSelection)
      settlePre({
        orderIds,
        payMethod: this.payMethod
      }).then((res) => {
        this.$message.success('批量结账' + res.msg)
        this.dialogVisible = false
        this.getList()
      })
    },
    // 结账二维码
    openSharePay(row) {
      this.showDialogShare.orderNumber = row.orderNumber
      this.showDialogShare.id = row.id
      this.showDialogShare.payMethod = row.payMethod
      this.showDialogShare.dialogVisible = true
    },
    // 选择订单
    handleSelectionChange(val) {
      if (val.length && val[val.length - 1].payStatus === 1) {
        this.$alert('存在已支付订单，请先去除勾选，再结账', '提示', {
          confirmButtonText: '知道了',
          type: 'warning',
          callback: () => {}
        })
        return
      }
      if (val.length && !val[val.length - 1].amountPaid) {
        this.$alert('存在医生未确定价格的订单，不可以结账', '提示', {
          confirmButtonText: '知道了',
          type: 'warning',
          callback: () => {}
        })
        return
      }
      this.multipleSelection = val
    },
    // 处理选择时间
    handleCreateTime(val) {
      this.searchForm.orderCreatedStart = val[0]
      this.searchForm.orderCreatedEnd = val[1]
    },
    handleConfirmTime(val) {
      this.searchForm.orderConfirmStart = val[0]
      this.searchForm.orderConfirmEnd = val[1]
    },
    handleCollectionTime(val) {
      if (val === null) {
        this.searchForm.collectionAtStartTime = null
        this.searchForm.collectionAtEndTime = null
      } else {
        this.searchForm.collectionAtStartTime = val[0]
        this.searchForm.collectionAtEndTime = val[1]
      }
    },
    // 详情
    viewDetail(row) {
      this.showDialogDetail.orderType = row.orderType
      this.showDialogDetail.orderId = row.id
      this.showDialogDetail.dialogVisible = true
      this.showDialogDetail.showReport = false
    },
    // 报告
    viewReport(row) {
      this.showDialogDetail.orderType = row.orderType
      this.showDialogDetail.orderId = row.id
      this.showDialogDetail.dialogVisible = true
      this.showDialogDetail.showReport = true
    },
    // 查看文件
    viewFiles(row) {
      this.showDialogFile.id = row.id
      this.showDialogFile.dialogVisible = true
    },
    // 编辑标签
    viewTags(row) {
      this.showDialogTags.id = row.id
      this.showDialogTags.dialogVisible = true
    },
    // 3D查看
    view3DFile(row) {
      this.showDialog3D.fileUrl = row.url3D[0]
      this.showDialog3D.url3DList = row.url3D
      this.showDialog3D.fileUrlList = row.file3D
      // console.log(row.url3D[0], 'row.url3D[0]')
      this.showDialog3D.dialogVisible = true
      // console.log(row)
    },
    // 结账二维码
    payQrCode(row) {
      console.log(row)
    },
    backToList() {
      this.showDialogDetail.dialogVisible = false
    },
    handleExpand() {
      this.expand = !this.expand
    }
  }
}
</script>

<style lang="scss" scoped>
.order-list {
  .main-area {
    .filter-bar {
      padding: 22px;
      background-color: #fff;
      .btn-box {
        margin-bottom: 12px;
        i {
          margin-right: 4px;
        }
        .com-primary-btn {
          background-color: $deepPrimaryColor;
        }
      }
      .small-input {
        width: 78px;
        ::v-deep .el-input__inner {
          padding: 0 10px;
        }
      }
    }
  }
  .demo-form-inline {
    .el-form-item {
      // width: 240px;
    }
  }
  &-button {
    width: 80px;
    margin-top: 5px;
  }
  &-button-top {
    width: 80px;
  }
  &-expand {
    display: grid;
    float: right;
    margin-top: 11px;
    cursor: pointer;
    img {
      width: 14px;
      height: 7px;
      background: #b9b9b9;
    }
  }
  &-fold {
    transform: rotate(180deg);
  }
  ::v-deep .el-table__fixed-body-wrapper {
    .is-left {
      .cell {
        margin: -5px 0 0 -5px;
        .el-button {
          margin: 5px 5px 0 !important;
        }
      }
    }
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
  // ::v-deep .el-table tr {
  //   height: 50px; //每行的高度
  // }
  // ::v-deep .el-table {
  //   .el-table__fixed,
  //   .el-table__fixed-right {
  //     .cell {
  //       height: 30px!important; //重点在于给这个设置一个固定高度即可
  //     }
  //   }
  // }
}
</style>