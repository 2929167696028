<template>
  <div class="question-info">
    <el-form
      class="elForm"
      ref="elForm"
      :model="questionReport"
      :rules="rules"
      size="medium"
      :disabled="isDisabled"
      label-position="left"
    >
      <!-- <p class="title"><span>*</span>1.全身情况(多选)</p> -->
      <el-form-item label="1.全身情况(多选)" prop="field101">
        <br />
        <el-checkbox-group v-model="questionReport.field101" size="medium">
          <el-checkbox
            v-for="(item, index) in qsqkOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <p class="title">2.主观信息</p>
      <el-form-item label="牙齿有无特殊敏感？" prop="field102">
        <el-radio-group v-model="questionReport.field102" size="medium">
          <el-radio
            v-for="(item, index) in field102Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否有头痛？" prop="field104">
        <el-radio-group v-model="questionReport.field104" size="medium">
          <el-radio
            v-for="(item, index) in field104Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否主观疼痛？" prop="field105">
        <el-radio-group v-model="questionReport.field105" size="medium">
          <el-radio
            v-for="(item, index) in field105Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否有咀嚼不适？" prop="field106">
        <el-radio-group v-model="questionReport.field106" size="medium">
          <el-radio
            v-for="(item, index) in field106Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="field107">
        <el-input
          v-model="questionReport.field107"
          placeholder="请输入"
          clearable
          :style="{ width: '100%' }"
        >
        </el-input>
      </el-form-item>
      <p class="title">3. 病史记录</p>
      <el-form-item label-width="180px" label="自己感觉是否需要治疗？" prop="field108">
        <el-radio-group v-model="questionReport.field108" size="medium">
          <el-radio
            v-for="(item, index) in field108Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="最后一次看牙时间是？" prop="field109">
        <el-input
          v-model="questionReport.field109"
          placeholder="请输入"
          :style="{ width: '100%' }"
        ></el-input>
      </el-form-item>
      <el-form-item label="前牙开合（mm）" prop="field111">
        <el-input
          v-model="questionReport.field111"
          placeholder="请输入"
          :style="{ width: '100%' }"
        ></el-input>
      </el-form-item>
      <el-form-item label="前牙深覆盖（mm）" prop="field113">
        <el-input
          v-model="questionReport.field113"
          placeholder="请输入"
          :style="{ width: '100%' }"
        ></el-input>
      </el-form-item>
      <el-form-item label="做过什么治疗？" prop="field114">
        <el-input
          v-model="questionReport.field114"
          placeholder="请输入"
          :style="{ width: '100%' }"
        ></el-input>
      </el-form-item>
      <el-form-item label-width="180px" label="是否做过正畸治疗？" prop="field116">
        <el-radio-group v-model="questionReport.field116" size="medium">
          <el-radio
            v-for="(item, index) in field116Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label-width="180px" label="是否带过牙合垫？" prop="field117">
        <el-radio-group v-model="questionReport.field117" size="medium">
          <el-radio
            v-for="(item, index) in field117Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <p class="title">4.口腔副功能</p>
      <el-form-item label-width="80px" label="紧咬牙？" prop="field118">
        <el-radio-group v-model="questionReport.field118" size="medium">
          <el-radio
            v-for="(item, index) in field118Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label-width="80px" label="夜磨牙？" prop="field119">
        <el-radio-group v-model="questionReport.field119" size="medium">
          <el-radio
            v-for="(item, index) in field119Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <p class="title">5. 颞下颌关节检查</p>
      <el-form-item label-width="200px" label="大张口是否受限？" prop="field120">
        <el-radio-group v-model="questionReport.field120" size="medium">
          <el-radio
            v-for="(item, index) in field120Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label-width="200px" label="关节是否弹响？" prop="field121">
        <el-radio-group v-model="questionReport.field121" size="medium">
          <el-radio
            v-for="(item, index) in field121Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label-width="200px" label="关节是否卡顿？" prop="field122">
        <el-radio-group v-model="questionReport.field122" size="medium">
          <el-radio
            v-for="(item, index) in field122Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label-width="200px" label="关节盘是否做过手术？" prop="field123">
        <el-radio-group v-model="questionReport.field123" size="medium">
          <el-radio
            v-for="(item, index) in field123Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label-width="200px" label="关节区是否有疼痛？" prop="field124">
        <el-radio-group v-model="questionReport.field124" size="medium">
          <el-radio
            v-for="(item, index) in field124Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label-width="200px" label="关节是否有噪音？" prop="field125">
        <el-radio-group v-model="questionReport.field125" size="medium">
          <el-radio
            v-for="(item, index) in field125Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label-width="200px" label="关节区触诊是否疼痛不适？" prop="field126">
        <el-radio-group v-model="questionReport.field126" size="medium">
          <el-radio
            v-for="(item, index) in field126Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <p class="title">6. 咀嚼肌触诊：肌肉触诊疼痛情况</p>
      <el-form-item label-width="80px" label="颞肌：" prop="field127">
        <el-checkbox-group v-model="questionReport.field127" size="medium">
          <el-checkbox
            v-for="(item, index) in field127Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label-width="80px" label="咬肌：" prop="field128">
        <el-checkbox-group v-model="questionReport.field128" size="medium">
          <el-checkbox
            v-for="(item, index) in field128Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label-width="80px" label="翼外肌：" prop="field129">
        <el-checkbox-group v-model="questionReport.field129" size="medium">
          <el-checkbox
            v-for="(item, index) in field129Options"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import order from '@/mixins/order.js'
export default {
  mixins: [order],
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      questionReport: {
        field101: [],
        field102: '',
        field104: '',
        field105: '',
        field106: '',
        field107: '',
        field108: '',
        field109: '',
        field111: '',
        field113: '',
        field114: '',
        field116: '',
        field117: '',
        field118: '',
        field119: '',
        field120: '',
        field121: '',
        field122: '',
        field123: '',
        field124: '',
        field125: '',
        field126: '',
        field127: [],
        field128: [],
        field129: []
      },
      rules: {
        field101: [
          {
            required: true,
            type: 'array',
            message: '请至少选择一个',
            trigger: 'change'
          }
        ],
        field102: [
          {
            required: true,
            message: '牙齿有无特殊敏感？不能为空',
            trigger: 'change'
          }
        ],
        field104: [
          {
            required: true,
            message: '是否有头痛？不能为空',
            trigger: 'change'
          }
        ],
        field105: [
          {
            required: true,
            message: '是否主观疼痛？不能为空',
            trigger: 'change'
          }
        ],
        field106: [
          {
            required: true,
            message: '是否有咀嚼不适？不能为空',
            trigger: 'change'
          }
        ],
        field107: [
          {
            required: true,
            message: '请输入',
            trigger: 'blur'
          }
        ],
        field108: [
          {
            required: true,
            message: '自己感觉是否需要治疗？不能为空',
            trigger: 'change'
          }
        ],
        field109: [
          {
            required: true,
            message: '请输入',
            trigger: 'blur'
          }
        ],
        field111: [
          {
            required: true,
            message: '请输入',
            trigger: 'blur'
          }
        ],
        field113: [
          {
            required: true,
            message: '请输入',
            trigger: 'blur'
          }
        ],
        field114: [
          {
            required: true,
            message: '请输入',
            trigger: 'blur'
          }
        ],
        field116: [
          {
            required: true,
            message: '是否做过正畸治疗？不能为空',
            trigger: 'change'
          }
        ],
        field117: [
          {
            required: true,
            message: '是否带过牙合垫？不能为空',
            trigger: 'change'
          }
        ],
        field118: [
          {
            required: true,
            message: '紧咬牙？不能为空',
            trigger: 'change'
          }
        ],
        field119: [
          {
            required: true,
            message: '夜磨牙？不能为空',
            trigger: 'change'
          }
        ],
        field120: [
          {
            required: true,
            message: '大张口是否受限？不能为空',
            trigger: 'change'
          }
        ],
        field121: [
          {
            required: true,
            message: '关节是否弹响？不能为空',
            trigger: 'change'
          }
        ],
        field122: [
          {
            required: true,
            message: '关节是否卡顿？不能为空',
            trigger: 'change'
          }
        ],
        field123: [
          {
            required: true,
            message: '关节盘是否做过手术？不能为空',
            trigger: 'change'
          }
        ],
        field124: [
          {
            required: true,
            message: '关节区是否有疼痛？不能为空',
            trigger: 'change'
          }
        ],
        field125: [
          {
            required: true,
            message: '关节是否有噪音？不能为空',
            trigger: 'change'
          }
        ],
        field126: [
          {
            required: true,
            message: '关节区触诊是否疼痛不适？不能为空',
            trigger: 'change'
          }
        ],
        field127: [
          {
            required: true,
            type: 'array',
            message: '请至少选择一个',
            trigger: 'change'
          }
        ],
        field128: [
          {
            required: true,
            type: 'array',
            message: '请至少选择一个',
            trigger: 'change'
          }
        ],
        field129: [
          {
            required: true,
            type: 'array',
            message: '请至少选择一个',
            trigger: 'change'
          }
        ]
      },
      qsqkOptions: [
        {
          label: '高血压',
          value: '高血压'
        },
        {
          label: '血糖',
          value: '血糖'
        },
        {
          label: '心脏',
          value: '心脏'
        },
        {
          label: '传染病',
          value: '传染病'
        },
        {
          label: '呼吸',
          value: '呼吸'
        },
        {
          label: '循环',
          value: '循环'
        },
        {
          label: '内分泌',
          value: '内分泌'
        },
        {
          label: '神经',
          value: '神经'
        },
        {
          label: '泌尿',
          value: '泌尿'
        },
        {
          label: '消化',
          value: '消化'
        },
        {
          label: '打鼾',
          value: '打鼾'
        },
        {
          label: '耳鸣',
          value: '耳鸣'
        },
        {
          label: '偏头疼',
          value: '偏头疼'
        },
        {
          label: '外伤',
          value: '外伤'
        }
      ],
      field102Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field104Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field105Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field106Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field108Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field116Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field117Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field118Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field119Options: [
        {
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }
      ],
      field120Options: [
        {
          label: '左',
          value: '左'
        },
        {
          label: '右',
          value: '右'
        },
        {
          label: '无',
          value: '无'
        }
      ],
      field121Options: [
        {
          label: '左',
          value: '左'
        },
        {
          label: '右',
          value: '右'
        },
        {
          label: '无',
          value: '无'
        }
      ],
      field122Options: [
        {
          label: '左',
          value: '左'
        },
        {
          label: '右',
          value: '右'
        },
        {
          label: '无',
          value: '无'
        }
      ],
      field123Options: [
        {
          label: '左',
          value: '左'
        },
        {
          label: '右',
          value: '右'
        },
        {
          label: '无',
          value: '无'
        }
      ],
      field124Options: [
        {
          label: '左',
          value: '左'
        },
        {
          label: '右',
          value: '右'
        },
        {
          label: '无',
          value: '无'
        }
      ],
      field125Options: [
        {
          label: '左',
          value: '左'
        },
        {
          label: '右',
          value: '右'
        },
        {
          label: '无',
          value: '无'
        }
      ],
      field126Options: [
        {
          label: '左',
          value: '左'
        },
        {
          label: '右',
          value: '右'
        },
        {
          label: '无',
          value: '无'
        }
      ],
      field127Options: [
        {
          label: '颞肌前份',
          value: '颞肌前份'
        },
        {
          label: '颞肌中份',
          value: '颞肌中份'
        },
        {
          label: '颞肌后份',
          value: '颞肌后份'
        }
      ],
       field128Options: [
        {
          label: '咬肌浅层',
          value: '咬肌浅层'
        },
        {
          label: '咬肌深层',
          value: '咬肌深层'
        }
      ],
       field129Options: [
        {
          label: '上颌结节',
          value: '上颌结节'
        },
        {
          label: '翼内肌',
          value: '翼内肌'
        },
        {
          label: '二腹肌',
          value: '二腹肌'
        }
      ],
      valid: false
    }
  },
  created() {
    this.getQuestionDetail()
  },
  mounted() {
    if (!this.required) {
      this.rules = {}
    }
  },
  methods: {
    getQuestionDetail() {
      if (!this.formData || !this.formData.questionReport) {
        return
      }
      if (Object.keys(this.formData.questionReport).length !== 0 && this.formData.questionReport !== '{}') {
        this.questionReport = JSON.parse(this.formData.questionReport)
        this.formData.questionReport = this.questionReport
      } else {
        this.formData.questionReport = this.questionReport
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.elForm {
  .title {
    margin-bottom: 14px;
    color: #333333;
    line-height: 22px;
    span {
      color: $--color-danger;
    }
  }
}
::v-deep .el-form {
  .el-form-item__label,
  .el-input__inner,
  .el-radio,
  .el-checkbox {
    color: #333;
  }
  .el-form-item {
    .el-input {
      .el-input__inner {
        width: 300px;
      }
    }
  }
}
</style>
