<template>
  <!-- 缓存组件内容 -->
  <keep-alive>
    <component
      :ref="componentArr[isActive]"
      :is="componentArr[isActive]"
      :formData="formData"
      :required="false"
    ></component>
  </keep-alive>
</template>

<script>
import BaseInfo from './BaseInfo.vue'
import QuestionContentFuzhu from '@/views/questionnaire/QuestionContentFuzhu.vue'
import VideoInfo from '../video-info/VideoInfo.vue'
import ReportInfo from '../video-info/ReportInfo.vue'
import OrderDetail from '../../mixins/order-detail.js'
import { createChildren, createChildOption } from '../order-form'
export default {
  name: 'HedianOrder',
  mixins: [OrderDetail],
  components: {
    BaseInfo,
    QuestionContentFuzhu,
    ReportInfo,
    VideoInfo
  },
  data() {
    return {
      componentArr: ['BaseInfo', 'QuestionContentFuzhu', 'VideoInfo', 'ReportInfo'],
      isRequired: true,
      formData: {
        // 基础信息
        patientName: '',
        patientSex: '',
        patientAge: '',
        patientPhone: '',
        diagnosisType: '',
        diagnosisDescription: '',
        jawPadType: '',
        jawPadTypeOther: '',
        designRequirement: '',
        // 问诊信息
        questionReport: {},
        // 快递下单
        dentalSendType: '',
        dentalSendAddress: '',
        dentalSendNumber: '',
        // 影像资料及报告
        imageReport: createChildOption({
          mode: '面像与口内照片',
          children: createChildren(
            '正面部正常影像|正面部拉钩像|左侧后牙咬合影像|全牙列正面咬合影像|右侧后牙咬合影像',
            true
          )
        }),
        fileReport: createChildOption(
          {
            mode: '上/下颌牙列数据',
            isSend: true,
            children: createChildOption(
              {
                mode: '上颌牙列数据',
                limit: ['stl']
              },
              {
                mode: '下颌牙列数据',
                limit: ['stl']
              }
            )
          },
          {
            mode: '运动轨迹数据',
            children: createChildOption({
              mode: '运动轨迹数据',
              limit: ['csv', 'pdf', 'jawmotion','zebdb']
            })
          },
          {
            mode: '其他文件',
            children: createChildOption({
              mode: '其他文件',
              limit: ['rar', 'tiff', 'stl', 'dcm', 'png', 'jpg', 'pdf']
            })
          }
        ),
        dataReport: createChildOption({
          mode: '报告',
          children: createChildOption({
            mode: '设计报告',
            limit: ['pdf']
          })
        })
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
