<template>
  <!-- 缓存组件内容 -->
  <keep-alive>
    <component
      :ref="componentArr[isActive]"
      :is="componentArr[isActive]"
      :formData="formData"
    ></component>
  </keep-alive>
</template>

<script>
import BaseInfo from './BaseInfo.vue'
import QuestionContentZuhan from '@/views/questionnaire/QuestionContentZuhan.vue'
import VideoInfo from '../video-info/VideoInfo.vue'
import OrderDetail from '../../mixins/order-detail.js'
import ReportInfo from '../video-info/ReportInfo.vue'
import { createChildren, createChildOption } from '../order-form'
export default {
  name: 'ZuhanqiOrder',
  mixins: [OrderDetail],
  components: {
    BaseInfo,
    QuestionContentZuhan,
    ReportInfo,
    VideoInfo
  },
  data() {
    return {
      componentArr: ['BaseInfo', 'QuestionContentZuhan', 'VideoInfo', 'ReportInfo'],
      formData: {
        // 基础信息
        patientName: '',
        patientSex: '',
        patientAge: '',
        patientPhone: '',
        patientHeight: '',
        patientWeight: '',
        patientBmi: '',
        patientNeck: '',
        designMind: '',
        // 问诊信息
        questionReport: {},
        // 快递下单
        dentalSendType: '',
        dentalSendAddress: '',
        dentalSendNumber: '',
        // 影像资料及报告
        imageReport: createChildOption(
          {
            mode: '面像与口内照片',
            children: createChildren(
              '正面部正常影像|90度侧面部影像|正面下颌最大前伸像|90度侧面下颌最大前伸影像',
              true
            )
          },
          {
            mode: '头颅片',
            children: createChildren('头颅正位片', true)
          }
        ),
        fileReport: createChildOption(
          {
            mode: '上/下颌牙列数据',
            isSend: true,
            children: createChildOption(
              {
                mode: '上颌牙列数据',
                limit: ['stl']
              },
              {
                mode: '下颌牙列数据',
                limit: ['stl']
              }
            )
          },
          {
            mode: 'CT数据',
            children: createChildOption({
              mode: 'CT数据',
              limit: ['rar', 'tiff', 'dcm']
            })
          },
          {
            mode: '其他文件',
            children: createChildOption({
              mode: '其他文件',
              limit: ['rar', 'tiff', 'stl', 'dcm', 'png', 'jpg', 'pdf']
            })
          }
        ),
        dataReport: createChildOption({
          mode: '报告',
          children: createChildOption(
            {
              mode: '睡眠监测报告',
              limit: ['pdf']
            },
            {
              mode: '设计报告',
              limit: ['pdf']
            }
          )
        })
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
