import { render, staticRenderFns } from "./OrderOptRecords.vue?vue&type=template&id=92f72052&scoped=true&"
import script from "./OrderOptRecords.vue?vue&type=script&lang=js&"
export * from "./OrderOptRecords.vue?vue&type=script&lang=js&"
import style0 from "./OrderOptRecords.vue?vue&type=style&index=0&id=92f72052&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f72052",
  null
  
)

export default component.exports