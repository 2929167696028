<template>
  <div class="modify-success">密码重置成功！</div>
</template>

<script>
export default {
  name: 'ModifySuccess',
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.modify-success {
  margin: 80px 0;
  font-size: 24px;
  color: $fontDeepColor;
  line-height: 33px;
  text-align: center;
}
</style>

