<template>
  <el-form-item :label="label" :prop="prop" class="input-list">
    <el-input v-model="dataValue" :placeholder="placeholder" style="width: 70%" />
    <el-button type="primary" @click="addData()" style="margin-left: 10px">新增</el-button>
    <el-popover
      v-if="showPop"
      placement="top-start"
      title="地址填写示例"
      width="300"
      trigger="hover"
      content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
    >
      <hr />
      <div class="pop-title">填写规范：</div>
      <div>省-市-区-详细地址，姓名，手机号</div>
      <div class="pop-title">如：</div>
      <div>北京市-大兴区- 旧宫镇广德大街20号中科电商谷8号楼729，任燕，15010212236</div>
      <el-link type="primary" slot="reference" style="margin-left: 10px">示例</el-link>
    </el-popover>
    <div v-for="(item, index) in dataValues" :key="index" class="input-list-content">
      <span>{{ item }}</span>
      <i class="el-icon-delete" @click="deleteData()" style></i>
    </div>
  </el-form-item>
</template>

<script>
export default {
  name: 'InputList',
  props: {
    value: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    showPop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataValue: '',
      dataValues: []
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newVal, oldVal) {
        // 如果值变化的时候，重新更新值
        this.dataValue = ''
        this.dataValues = []
        if (newVal) {
          this.dataValues = newVal.split(',')
        }
      }
    }
  },
  mounted() {},
  methods: {
    addData() {
      if (this.dataValue) {
        this.dataValues.push(this.dataValue)
        this.$emit('input', this.dataValues.join(','))
      }
    },
    deleteData(index) {
      this.dataValues.splice(index, 1)
      if (this.dataValues.length > 0) {
        this.$emit('input', this.dataValues.join(','))
      } else {
        this.$emit('input', '')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input-list {
  &-content {
    margin-top: 8px;
    display: flex;
    width: 100%;
    align-items: center;
  }
  span {
    width: 70%;
    line-height: 1.4;
  }
  i {
    cursor: pointer;
  }
}
.pop-title {
  color: rgb(185, 184, 184);
}
</style>