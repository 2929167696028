<template>
  <div class="video-file-single">
    <upload-file @handleFileList="handleFileList" :limit="fileObj.limit" :progress="progress" :uploadStatus="uploadStatus" uploadType="file" v-if="isEdit">
      <div class="upload-area-operate">
        <template v-if="uploadStatus.value === 'success'">
          <div class="btn"><i class="iconfont icon-a-bianzu11"></i>上传文件</div>
          <div class="tip">
            格式限:
            <span v-for="(limit, i) in fileObj.limit" :key="i">
              {{ limit }}<i v-if="!(i + 1 === fileObj.limit.length)">/</i>
            </span>
          </div>
        </template>
        <template v-if="uploadStatus.value === 'loading'">
          <svg-icon icon-class="uploading" />
          <p class="text-tip">
            上传中…请等待{{ progress.value && parseInt(progress.value) + '%' }}
          </p>
        </template>
        <template v-if="uploadStatus.value === 'fail'">
          <svg-icon icon-class="fail" />
          <p class="text-tip-red">上传失败，重新上传</p>
        </template>
      </div>
    </upload-file>
    <p :class="{ title: true, 'is-required': fileObj.required }">{{ fileObj.mode }}</p>
    <ul class="file-ul">
      <li v-for="(file, ind) in fileObj.files" :key="ind">
        <p class="file-info">
          <span class="name">{{ file.name }}</span>
        </p>
        <!-- <p class="file-info">
          <span class="name">{{ file.name }}</span
          >（{{ file.size | filterSize }}）
        </p> -->
        <div class="icon-box" v-if="file.path">
          <i v-if="(isCalDownload || !detail) && showPreview(file)" class="iconfont icon-chakan" style="color: #00AEA5" @click="handlePreview(file)"></i>
          <i class="iconfont icon-download" style="color: #00AEA5" @click="handleDownload(file)" v-if="isCalDownload || !detail"></i>
          <i class="iconfont icon-trash" style="color: #00AEA5" @click="handleDelete(ind)" v-if="(isCanDel && isEdit) || !detail"></i>
        </div>
      </li>
    </ul>
    <!-- 3D查看 -->
    <order-model-stl v-if="showDialog3D.dialogVisible" :showDialog="showDialog3D"></order-model-stl>
  </div>
</template>

<script>
import UploadFile from '@/components/UploadFile/index'
import { downloadImg } from '@/utils/download.js'
import OrderModelStl from '../../order-list/OrderModelStl.vue'
export default {
  name: 'VideoFileSingle',
  components: {
    UploadFile,
    OrderModelStl
  },
  props: {
    fileObj: {
      type: Object,
      default: () => {}
    },
    // 待确认报告/方案在上面显示的时候，不可编辑
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  inject: {
    isDisabled: { default: false },
    isCalDownload: { default: 1 },
    isCanDel: { default: 1 },
    detail: { default: false }
  },
  data() {
    return {
      progress: {
        value: ''
      },
      uploadStatus: {
        value: 'success'
      },
      showDialog3D: {
        dialogVisible: false,
        fileUrl: '',
        url3DList: [],
        fileUrlList: []
      }
    }
  },
  filters: {
    filterSize(bytes) {
      if (bytes === 0) return '0 B'
      var k = 1024
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))

      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
    }
  },
  mounted() {},
  methods: {
    downloadImg,
    getFileType(filePath) {
      var startIndex = filePath.lastIndexOf('.')
      if (startIndex != -1) return filePath.substring(startIndex + 1, filePath.length).toLowerCase()
      else return ''
    },
    showPreview(file) {
      const type = this.getFileType(file.name)
      const fileTypeList = ['pdf', 'stl', 'obj', 'dcm']
      if (fileTypeList.indexOf(type) > -1) {
        return true
      }
      return false
    },
    handlePreview(file) {
      // console.log('handlePreview -- file == ', file)
      // console.log('handlePreview -- this.fileList == ', this.fileList)
      // console.log('handlePreview -- this.fileObj == ', this.fileObj)

      // 打开文件
      const type = this.getFileType(file.name)
      if (type == 'pdf') {
        window.open(file.path)
      } else {
        var fileList = this.fileObj.files
        var url3DList = []
        var newList = []
        for (let i = 0; i < fileList.length; i++) {
          if (this.getFileType(fileList[i].name) == 'stl') {
            url3DList.push(fileList[i].path)
            newList.push(fileList[i])
          }
        }
        this.showDialog3D.fileUrl = file.path
        this.showDialog3D.url3DList = url3DList
        this.showDialog3D.fileUrlList = newList
        this.showDialog3D.dialogVisible = true
      }
    },
    // 下载文件
    handleDownload(file) {
      const fileTypeList = ['pdf', 'jpg', 'png', 'jpeg', 'gif']
      if (fileTypeList.indexOf(file.type) > -1) {
        // const { path, name } = this.currentImgInfo
        // const aLink = document.createElement('a')
        // aLink.download = name
        // aLink.href = path
        // aLink.dispatchEvent(new MouseEvent('click', {}))
        this.downloadImg(file)
      } else {
        window.location.href = file.path
      }
    },
    // 删除文件
    handleDelete(index) {
      this.fileObj.files.splice(index, 1)
      this.$forceUpdate()
    },
    // 上传图片列表
    handleFileList(file) {
      this.fileObj.files.push(file)
      // this.fileArr[index].files.push(file)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-file-single {
  .upload-area-operate {
    margin-bottom: 8px;
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    .btn {
      color: $primaryColor;
      line-height: 20px;
      i {
        margin-right: 8px;
        font-size: 20px;
        vertical-align: -2px;
      }
    }
    .tip {
      margin-top: 8px;
      font-size: 12px;
      color: #999999;
      line-height: 17px;
    }
    .svg-icon {
      margin-bottom: 8px;
      width: 20px;
      height: 20px;
    }
    .text-tip {
      font-size: 12px;
      color: #333333;
      line-height: 17px;
    }
    .text-tip-red {
      color: $--color-danger;
    }
  }
  .title {
    margin-bottom: 8px;
    line-height: 20px;
    text-align: center;
  }
  .is-required {
    &:before {
      content: '*';
      color: #e02020;
      margin-right: 4px;
    }
  }

  .file-ul {
    li {
      & + li {
        margin-top: 8px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      .file-info {
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        .name {
          max-width: 190px;
          overflow: hidden;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          white-space: nowrap;
          color: #222222;
        }
      }
      .icon-box {
        i {
          margin-left: 8px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
