<template>
  <el-dialog
    title="标签编辑"
    :visible.sync="show.tag"
    width="800px"
    append-to-body
    class="track-tag"
  >
    <el-form ref="form" :model="form" label-width="150px">
      <el-tag
        :key="tag.id"
        v-for="tag in tagList"
        :class="form.tags.indexOf(tag.id) > -1 ? 'selected' : ''"
        @click="clickTag(tag)"
      >
        {{ tag.tagName }}
      </el-tag>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">提 交</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as dataManageApi from '@/api/data-manage'
import * as tagsApi from '@/api/tags.js'
import { deepClone } from '@/utils/index'
export default {
  name: 'TrackTag',
  components: {},
  props: {
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      initForm: { id: undefined, tags: [] },
      form: {},
      tagList: []
    }
  },
  watch: {
    show: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal.tag) {
          this.init()
        }
      }
    }
  },
  methods: {
    init() {
      this.reset()
      this.getTagList()
      this.detail(this.row)
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.form = deepClone(this.initForm)
    },
    // 获取标签列表
    getTagList() {
      tagsApi.getTagsList({ type: 1 }).then((res) => {
        this.tagList = res.data
      })
    },
    detail(row) {
      if (row && row.id) {
        dataManageApi.detail(row.id).then((res) => {
          const { data } = res
          this.form.id = data.id
          if (data.tags) {
            this.form.tags = data.tags.map((tag) => Number.parseInt(tag))
          } else {
            this.form.tags = []
          }
        })
      }
    },
    clickTag(tag) {
      const index = this.form.tags.indexOf(tag.id)
      if (index > -1) {
        this.form.tags.splice(index, 1)
      } else {
        this.form.tags.push(tag.id)
      }
    },
    submitForm() {
      if (this.form.id) {
        dataManageApi.edit(this.form).then((res) => {
          this.$message({ showClose: true, message: '保存成功', type: 'success' })
          this.$emit('flushList')
        })
      }
    },
    cancel() {
      this.show.tag = false
    }
  }
}
</script>

<style lang="scss" scoped>
.track-tag {
  .selected {
    background-color: #e6f7f6 !important;
    border-color: #e6f7f6 !important;
    color: rgb(102, 102, 102) !important;
  }
  .el-tag {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    color: #999;
    margin: 0px 10px 10px 0px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>
