<template>
  <div>
    <el-form-item v-if="configData && configData.options" :label="label" :prop="fieldName">
      <!-- <div v-if="border" class="cut-line"></div> -->
      <el-checkbox-group
        v-if="configData.isCheckbox && isCheckbox"
        v-model="checkboxValue"
        size="medium"
        :class="{ 'child-check': border }"
      >
        <el-checkbox
          v-for="(item, index) in configData[optionsName]"
          :key="index"
          :label="item.value"
          :disabled="item.disabled"
          >{{ item.label }}</el-checkbox
        >
      </el-checkbox-group>
      <div :class="{ 'child-check': border }" v-else>
        <el-radio-group v-model="formData[fieldName]" size="medium" @change="handleChange">
          <el-radio
            v-for="(item, index) in configData[optionsName]"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <slot></slot>
    </el-form-item>
    <el-form-item v-if="inputName && configData.showInput">
      <el-input
        v-model="formData[inputName]"
        type="textarea"
        placeholder="请输入"
        show-word-limit
        :maxlength="100"
        :autosize="{ minRows: 4, maxRows: 4 }"
      ></el-input>
    </el-form-item>
  </div>
</template>
<script>
import { DESIGN_CONFIG_OBJ } from './design-config'
export default {
  name: 'Diagnosis',
  props: {
    label: {
      type: String,
      default() {
        return ''
      }
    },
    isCheckbox: {
      type: Boolean,
      default() {
        return false
      }
    },
    border: {
      type: Boolean,
      default() {
        return false
      }
    },
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    parentValue: {
      type: String,
      default() {
        return ''
      }
    },
    inputName: {
      type: String,
      default() {
        return ''
      }
    },
    fieldName: {
      type: String,
      default() {
        return ''
      }
    },
    optionsName: {
      type: String,
      default() {
        return 'options'
      }
    }
  },
  data() {
    return {
      configData: {}
    }
  },
  computed: {
    checkboxValue: {
      get() {
        if (this.formData[this.fieldName]) {
          return this.formData[this.fieldName].split(',')
        }
        return []
      },
      set(val) {
        this.formData[this.fieldName] = val.join(',')
      }
    }
  },
  watch: {
    parentValue: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.init()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.configData = this.getConfigData(this.parentValue) || {}
    },
    getConfigData(name) {
      if (name && DESIGN_CONFIG_OBJ[name]) {
        return DESIGN_CONFIG_OBJ[name] || null
      }
      return null
    },
    handleChange() {
      this.clearChildOption(this.fieldName, this.formData)
    },
    clearChildOption(fieldName, formData) {
      // 值改变时，清空子选项的值
      const fieldArray = [
        'diagnosisType',
        'diagnosisTypeTwo',
        'treatmentPlane',
        'treatmentPlaneTwo',
        'treatmentPlaneThree'
      ]
      let isClear = false
      fieldArray.forEach((key) => {
        if (isClear) {
          formData[key] = ''
        }
        if (fieldName == key) {
          isClear = true
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox__inner,
::v-deep .el-radio__inner {
  border: 1px solid #979797;
  background-color: transparent;
}
.cut-line {
  height: 1px;
  border-top: solid #acc0d8 1px;
}
.child-check {
  padding: 0 12px;
  margin: 5px 12px 5px 0;
  background-color: #eeeeee;
  .el-checkbox,
  .el-radio {
    vertical-align: top;
  }
}
</style>
