import request from '@/utils/request'

// 图片列表
export function getImageList(data) {
  return request({
    url: '/web/data/image/list',
    method: 'post',
    data
  })
}

// 轨迹列表
export function getTrackList(data) {
  return request({
    url: '/web/data/track/list',
    method: 'post',
    data
  })
}

// 轨迹数据详情
export function detail(id) {
  return request({
    url: '/web/data/track/detail',
    method: 'post',
    data: { id }
  })
}

// 轨迹数据编辑
export function edit(data) {
  return request({
    url: '/web/data/track/edit',
    method: 'post',
    data
  })
}

// 轨迹数据编辑
export function del(id) {
  return request({
    url: '/web/data/track/delete',
    method: 'post',
    data: { id }
  })
}