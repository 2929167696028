/**
 * 治疗计划详情
 */
const PLANS_DETAIL = {
    'Le Fort': {
        label: 'Le Fort',
        value: 'Le Fort',
        options: [
            {
                label: 'Le Fort  -Ⅰ',
                value: 'Le Fort  -Ⅰ'
            },
            {
                label: 'Le Fort  -Ⅱ',
                value: 'Le Fort  -Ⅱ'
            },
            {
                label: 'Le Fort  -Ⅲ',
                value: 'Le Fort  -Ⅲ'
            }
        ]
    },
    '分块': {
        label: '分块',
        value: '分块',
        options: [
            {
                label: '分块2',
                value: '分块2'
            },
            {
                label: '分块3',
                value: '分块3'
            },
            {
                label: '分块4',
                value: '分块4'
            }
        ]
    }
}
/**
 * 治疗计划
 */
const PLANS = {
    '上颌骨截骨术': {
        label: '上颌骨截骨术',
        value: '上颌骨截骨术',
        options: [
            PLANS_DETAIL['Le Fort'],
            PLANS_DETAIL['分块'],
            {
                label: '不分块',
                value: '不分块'
            }
        ]

    },
    '下颌骨截骨术': {
        label: '下颌骨截骨术',
        value: '下颌骨截骨术',
        options: [
            {
                label: '矢状劈开截骨',
                value: '矢状劈开截骨'
            },
            {
                label: '倒L型截骨',
                value: '倒L型截骨'
            },
            {
                label: '下颌支垂直骨截开术',
                value: '下颌支垂直骨截开术'
            },
            {
                label: '根尖下截骨',
                value: '根尖下截骨'
            },
            {
                label: '下颌角截骨',
                value: '下颌角截骨'
            }
        ]
    },
    '颌骨重建': {
        label: '颌骨重建',
        value: '颌骨重建',
        isCheckbox: true,
        options: [
            {
                label: '术前模型',
                value: '术前模型'
            },
            {
                label: '术后模型',
                value: '术后模型'
            },
            {
                label: '数字化导板',
                value: '数字化导板'
            }
        ]
    },
    '腓骨重建': {
        label: '腓骨重建',
        value: '腓骨重建'
    },
    '髂骨重建': {
        label: '髂骨重建',
        value: '髂骨重建'
    },
    '牵引成骨': {
        label: '牵引成骨',
        value: '牵引成骨'
    },
    '颌骨模型': {
        label: '颌骨模型',
        value: '颌骨模型'
    },
    '单颗': {
        label: '单颗',
        value: '单颗'
    },
    '多颗': {
        label: '多颗',
        value: '多颗'
    },
    '无牙颌': {
        label: '无牙颌',
        value: '无牙颌'
    },
    '埋伏牙': {
        label: '埋伏牙',
        value: '埋伏牙'
    },
    '多生牙': {
        label: '多生牙',
        value: '多生牙'
    }
}
/**
 * 患者类型
 */
export const DESIGN_CONFIG = [
    {
        label: '牙颌面畸形正颌外科',
        value: '牙颌面畸形正颌外科',
        showInput: true,
        isCheckbox: true,
        options: [
            {
                label: '骨性Ⅱ类',
                value: '骨性Ⅱ类'
            },
            {
                label: '骨性Ⅲ类',
                value: '骨性Ⅲ类'
            },
            {
                label: '偏颌',
                value: '偏颌'
            },
            {
                label: '唇腭裂',
                value: '唇腭裂'
            }
        ],
        planeOptions: [
            PLANS['上颌骨截骨术'],
            PLANS['下颌骨截骨术'],
            PLANS['颌骨重建'],
        ]
    },
    {
        label: '头颈肿瘤外科',
        value: '头颈肿瘤外科',
        isCheckbox: true,
        options: [
            {
                label: '上颌肿瘤',
                value: '上颌肿瘤'
            },
            {
                label: '下颌肿瘤',
                value: '下颌肿瘤'
            }
        ],
        planeOptions: [
            PLANS['腓骨重建'],
            PLANS['髂骨重建'],
            PLANS['牵引成骨'],
            PLANS['颌骨模型'],
        ]
    },
    {
        label: '种植',
        value: '种植',
        isCheckbox: true,
        options: [
            {
                label: '上颌',
                value: '上颌'
            },
            {
                label: '下颌',
                value: '下颌'
            },
            {
                label: '双颌',
                value: '双颌'
            }
        ],
        planeOptions: [
            PLANS['单颗'],
            PLANS['多颗'],
            PLANS['无牙颌']
        ]
    },
    {
        label: '牙槽外科',
        value: '牙槽外科',
        isCheckbox: true,
        options: [
            {
                label: '上颌',
                value: '上颌'
            },
            {
                label: '下颌',
                value: '下颌'
            },
            {
                label: '双颌',
                value: '双颌'
            }
        ],
        planeOptions: [
            PLANS['埋伏牙'],
            PLANS['多生牙'],
        ]
    },
    {
        label: '其他',
        value: '其他'
    }

]
function convertToObj() {
    let obj = {}
    DESIGN_CONFIG.forEach(design => {
        obj[design.value] = design
    })
    obj = { '0': { options: DESIGN_CONFIG }, ...obj, ...PLANS, ...PLANS_DETAIL }
    return obj
}

export const DESIGN_CONFIG_OBJ = convertToObj()
