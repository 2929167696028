<template>
  <div class="base-info">
    <div class="base-info-box person-info">
      <h2 class="com-h2-title">患者基础信息</h2>
      <el-form
        class="demo-form-base"
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="80px"
        :disabled="isDisabled"
      >
        <el-form-item label="患者姓名" prop="patientName">
          <el-input v-model="formData.patientName" placeholder="请输入患者姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="patientSex">
          <el-radio-group v-model="formData.patientSex" size="medium">
            <el-radio
              v-for="(item, index) in patientGenderOptions"
              :key="index"
              :label="item.value"
              :disabled="item.disabled"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄" prop="patientAge">
          <el-input v-model.number="formData.patientAge" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号" prop="patientPhone">
          <el-input v-model="formData.patientPhone" placeholder="请输入手机号" :maxlength="11">
          </el-input>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="base-info-box design-info">
      <h2 class="com-h2-title">设计信息</h2>
      <el-form
        class="demo-form-design"
        ref="elForm2"
        :model="formData"
        size="medium"
        label-width="80px"
        :disabled="isDisabled"
      >
        <el-form-item label="患者类型" prop="diagnosisType">
          <el-radio-group v-model="formData.diagnosisType" size="medium">
            <el-radio
              v-for="(item, index) in diagnosisTypeOptions"
              :key="index"
              :label="item.value"
              :disabled="item.disabled"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="diagnosisDescription">
          <el-input
            v-model="formData.diagnosisDescription"
            type="textarea"
            placeholder="请输入诊断说明"
            :maxlength="100"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="颌垫类型" prop="jawPadType">
          <el-radio-group v-model="formData.jawPadType" size="medium">
            <el-radio
              v-for="(item, index) in jawPadTypeOptions"
              :key="index"
              :label="item.value"
              :disabled="item.disabled"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
          <el-input v-if="formData.jawPadType === '其他'" style="margin-top: 4px;" v-model="formData.jawPadTypeOther" placeholder="请输入颌垫类型"></el-input>
        </el-form-item>
        <el-form-item label="设计方案" prop="designRequirement">
          <el-input
            v-model="formData.designRequirement"
            type="textarea"
            placeholder="请输入设计方案要求"
            :maxlength="100"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import order from '@/mixins/order.js'
export default {
  name: 'BaseInfo',
  mixins: [order],
  data() {
    return {
      rules: {
        patientName: [
          {
            required: true,
            message: '请输入患者姓名',
            trigger: 'blur'
          }
        ],
        patientSex: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'change'
          }
        ],
        patientAge: [
          {
            required: true,
            message: '请输入年龄',
            trigger: 'blur'
          }
        ]
        // patientPhone: [
        //   {
        //     required: true,
        //     message: '请输入手机号',
        //     trigger: 'blur'
        //   },
        //   {
        //     pattern: /^1(1|2|3|4|5|6|7|8|9|0)\d{9}$/,
        //     message: '手机号格式错误',
        //     trigger: 'blur'
        //   }
        // ]
      },
      patientGenderOptions: [
        {
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 2
        }
      ],
      diagnosisTypeOptions: [
        {
          label: '可复性关节盘移位',
          value: '可复性关节盘移位'
        },
        {
          label: '不可复性关节盘移位',
          value: '不可复性关节盘移位'
        },
        {
          label: '关节骨质病变',
          value: '关节骨质病变'
        },
        {
          label: '关节囊内紊乱',
          value: '关节囊内紊乱'
        },
        {
          label: '关节腔积液',
          value: '关节腔积液'
        },
        {
          label: '其他',
          value: '其他'
        }
      ],
      jawPadTypeOptions: [
        {
          label: '放松颌垫',
          value: '放松颌垫'
        },
        {
          label: '稳定颌垫',
          value: '稳定颌垫'
        },
        {
          label: '再定位颌垫',
          value: '再定位颌垫'
        },
        {
          label: '其他',
          value: '其他'
        }
      ]
    }
  },
  computed: {},
  watch: {
    'formData.jawPadType'(val) {
      if (val !== '其他') {
        this.formData.jawPadTypeOther = ''
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // submitForm() {
    //   this.$refs['elForm'].validate((valid) => {
    //     if (!valid) return
    //     // TODO 提交表单
    //   })
    // },
    resetForm() {
      this.$refs['elForm'].resetFields()
    }
  }
}
</script>
<style lang="scss" scoped></style>
