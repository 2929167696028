import request from '@/utils/request'

// 标签列表
export function getTagsList(data) {
  return request({
    url: '/web/tags/queryAll',
    method: 'post',
    data
  })
}

// 标签新增
export function addTags(data) {
  return request({
    url: '/web/tags/add',
    method: 'post',
    data
  })
}

// 标签编辑/删除
export function editTags(data) {
  return request({
    url: '/web/tags/edit',
    method: 'post',
    data
  })
}
