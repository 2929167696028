<template>
  <!-- 添加或修改参数配置对话框 -->
  <el-dialog
    :title="title"
    :visible.sync="show.userDetail"
    width="600px"
    append-to-body
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="上级菜单">
        <treeselect
          v-model="form.parentId"
          :options="menuOptions"
          :normalizer="normalizer"
          :show-count="true"
          placeholder="选择上级菜单"
        />
      </el-form-item>
      <el-form-item label="菜单类型" prop="menuType">
        <el-radio-group v-model="form.menuType">
          <el-radio v-for="dict in MENU_TYPE" :key="dict.value" :label="dict.value">{{
            dict.label
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.menuType != 'F'" label="菜单图标">
        <el-popover
          placement="bottom-start"
          width="460"
          trigger="click"
          @show="$refs['iconSelect'].reset()"
        >
          <IconSelect ref="iconSelect" @selected="selected" />
          <el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
            <svg-icon
              v-if="form.icon"
              slot="prefix"
              :icon-class="form.icon"
              class="el-input__icon"
              style="height: 32px; width: 16px"
            />
            <i v-else slot="prefix" class="el-icon-search el-input__icon" />
          </el-input>
        </el-popover>
      </el-form-item>
      <el-form-item label="菜单名称" prop="menuName">
        <el-input v-model="form.menuName" placeholder="请输入菜单名称" />
      </el-form-item>
      <el-form-item label="显示排序" prop="orderNum">
        <el-input-number v-model="form.orderNum" controls-position="right" :min="0" />
      </el-form-item>
      <el-form-item v-if="form.menuType != 'F'" label="路由地址" prop="path">
        <el-input v-model="form.path" placeholder="请输入路由地址" />
      </el-form-item>
      <el-form-item v-if="form.menuType != 'F'" label="组件路径" prop="component">
        <el-input v-model="form.component" placeholder="请输入组件路径" />
      </el-form-item>
      <el-form-item v-if="form.menuType != 'M'" label="权限标识">
        <el-input v-model="form.perms" placeholder="请权限标识" maxlength="50" />
      </el-form-item>
      <el-form-item v-if="form.menuType != 'F'" label="显示状态">
        <el-radio-group v-model="form.visible">
          <el-radio v-for="dict in MENU_VISIBLE" :key="dict.value" :label="dict.value">{{
            dict.label
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.menuType != 'F'" label="菜单状态">
        <el-radio-group v-model="form.status">
          <el-radio v-for="dict in MENU_STATUS" :key="dict.value" :label="dict.value">{{
            dict.label
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { MENU_STATUS, MENU_VISIBLE, MENU_TYPE } from '@/utils/constants.js'
import * as menuApi from '@/api/menu'
import IconSelect from '@/components/IconSelect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { deepClone, handleTree } from '@/utils/index'
export default {
  name: 'MenuDetail',
  components: { Treeselect, IconSelect },
  props: {
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    parentId: {
      type: Number,
      default() {
        return 0
      }
    },
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    const validateComponent = (rule, value, callback) => {
      if (this.form.menuType == 'C' && !value) {
        callback(new Error('组件路径不能为空'))
      } else {
        callback()
      }
    }
    return {
      MENU_STATUS,
      MENU_VISIBLE,
      MENU_TYPE,
      menuOptions: [],
      form: {},
      initForm: {
        menuId: undefined,
        parentId: 0,
        menuName: undefined,
        icon: undefined,
        menuType: 'M',
        orderNum: undefined,
        visible: '0',
        status: '0'
      },
      // 表单校验
      rules: {
        menuName: [{ required: true, message: '菜单名称不能为空', trigger: 'blur' }],
        orderNum: [{ required: true, message: '菜单顺序不能为空', trigger: 'blur' }],
        path: [{ required: true, message: '路由地址不能为空', trigger: 'blur' }],
        component: [
          {
            required: true,
            validate: validateComponent,
            message: '组件路径不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    show: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal.userDetail) {
          this.init()
        }
      }
    }
  },
  methods: {
    // 选择图标
    selected(name) {
      this.form.icon = name
    },
    init() {
      this.reset()
      this.detail(this.row)
      if (this.parentId) {
        this.form.parentId = this.parentId
      }
      this.getTreeselect()
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children
      }
      return {
        id: node.id,
        label: node.menuName,
        children: node.children
      }
    },
    getTreeselect() {
      menuApi.queryAll({}).then((response) => {
        this.menuOptions = []
        const menu = { id: 0, menuName: '主类目', children: [] }
        menu.children = handleTree(response.data, 'id')
        this.menuOptions.push(menu)
      })
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.form = deepClone(this.initForm)
    },
    detail(row) {
      if (row && row.id) {
        menuApi.detail(row.id).then((res) => {
          this.form = res.data
        })
      }
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          menuApi.save(this.form).then((res) => {
            this.$message({ showClose: true, message: '保存成功', type: 'success' })
            this.$emit('flushList')
          })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.show.userDetail = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
