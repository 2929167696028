<template>
  <!-- 缓存组件内容 -->
  <keep-alive>
    <component
      :ref="componentArr[isActive]"
      :is="componentArr[isActive]"
      :formData="formData"
    ></component>
  </keep-alive>
</template>

<script>
import BaseInfo from './BaseInfo.vue'
import VideoInfo from '../video-info/VideoInfo.vue'
import ReportInfo from '../video-info/ReportInfo.vue'
import OrderDetail from '../../mixins/order-detail.js'
import { createChildren, createChildOption } from '../order-form'
export default {
  name: 'WaikeOrder',
  mixins: [OrderDetail],
  components: {
    BaseInfo,
    ReportInfo,
    VideoInfo
  },
  data() {
    return {
      componentArr: ['BaseInfo', 'VideoInfo', 'ReportInfo'],
      formData: {
        patientName: '',
        patientSex: '',
        patientAge: '',
        patientPhone: '',
        patientStartAt: '',
        diagnosisType: '',
        diagnosisTypeTwo: '',
        diagnosisDescription: '',
        treatmentPlane: '',
        treatmentPlaneTwo: '',
        treatmentPlaneThree: '',
        // treatmentPlaneTwo: [],
        treatmentPlaneDescription: '',
        // 快递下单
        dentalSendType: '',
        dentalSendAddress: '',
        dentalSendNumber: '',
        imageReport: createChildOption(
          {
            mode: '面像与口内照片',
            children: createChildren(
              '90度侧面部影像|正面部正常影像|正面部微笑影像|左侧后牙咬合影像|全牙列正面咬合影像|右侧后牙咬合影像',
              true
            )
          },
          {
            mode: '全景片、面扫',
            children: createChildren('曲面断层片|面扫', true)
          },
          {
            mode: '头颅片',
            children: createChildren('头颅正位片|头颅侧位片', true)
          }
        ),
        fileReport: createChildOption(
          {
            mode: '上/下颌牙列数据',
            isSend: true,
            children: createChildOption(
              {
                mode: '上颌牙列数据',
                limit: ['stl']
              },
              {
                mode: '下颌牙列数据',
                limit: ['stl']
              }
            )
          },
          {
            mode: 'CT数据',
            children: createChildOption({
              mode: 'CT数据',
              limit: ['rar', 'tiff', 'dcm']
            })
          },
          {
            mode: '其他文件',
            children: createChildOption({
              mode: '其他文件',
              limit: ['rar', 'tiff', 'stl', 'dcm', 'png', 'jpg', 'pdf']
            })
          }
        ),
        dataReport: createChildOption({
          mode: '报告',
          children: createChildOption(
            {
              mode: '设计报告',
              limit: ['pdf']
            },
            {
              mode: '3D导板',
              limit: ['stl', 'obj', 'dcm']
            }
          )
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped></style>
