<template>
  <!-- 添加或修改参数配置对话框 -->
  <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
    <el-tree
      :data="menuOptions"
      show-checkbox
      ref="menu"
      node-key="id"
      empty-text="加载中，请稍后"
      :default-expand-all="true"
      :props="defaultProps"
    ></el-tree>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as menuApi from '@/api/menu'
export default {
  name: 'RolePri',
  data() {
    return {
      open: false,
      title: '权限设置',
      menuOptions: [],
      form: {
        id: undefined,
        menuIds: []
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    init(row) {
      this.open = true
      if (this.$refs.menu) {
        this.$refs.menu.setCheckedKeys([])
      }
      this.form.id = row.id
      this.title = row.roleName + '权限设置'
      if (row.id) {
        this.getRoleMenuTreeselect(row.id)
      }
    },
    /** 根据角色ID查询菜单树结构 */
    getRoleMenuTreeselect(roleId) {
      menuApi.roleMenuTreeselect(roleId).then(({ data }) => {
        this.menuOptions = data.menus
        if (data.checkedKeys) {
          this.$refs.menu.setCheckedKeys(data.checkedKeys)
        }
      })
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getHalfCheckedKeys()
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getCheckedKeys()
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys)
      return checkedKeys
    },
    submitForm() {
      if (this.form.id) {
        this.form.menuIds = this.getMenuAllCheckedKeys()
        if (!this.form.menuIds || this.form.menuIds.length == 0) {
          this.$message({
            message: '未选中任何菜单',
            type: 'warning'
          })
          return
        }
        menuApi.saveRoleMenus(this.form).then((res) => {
          this.$message({ showClose: true, message: '保存成功', type: 'success' })
          this.cancel()
        })
      }
    },
    cancel() {
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
