<template>
  <el-dialog :title="title" :visible.sync="show.dialogVisible" width="800px">
    <div class="send-dialog">
      <div class="left-area">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px">
          <el-form-item label="选择快递方式" prop="sendType">
            <el-select v-model="ruleForm.sendType" placeholder="选择快递方式">
              <el-option v-for="item in SEND_TYPE" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择发货地址" prop="receiveAddress" v-if="sendTypeUrl">
            <div class="el-form-item-box">
              <el-select v-model="receiveAddress" placeholder="选择收货地址" style="width: 520px">
                <el-option v-for="item in ADDRESS.from" :key="item" :label="item" :value="item">
                  <span style="float: left">{{ item }}</span>
                  <el-button @click.stop="copyUrl(item)" style="float: right; margin: 3px 0 0 4px" type="primary" plain size="mini">复制</el-button>
                </el-option>
              </el-select>
              <el-button @click.stop="copyUrl(receiveAddress)" type="primary" plain>复制地址</el-button>
            </div>
            <div v-if="receiveAddress" style="margin-top: 10px;">
              <span style="line-height: 24px; color: #999;">{{ receiveAddress }}</span>
            </div>
          </el-form-item>

          <el-form-item label="选择收货地址" prop="sendAddress" v-if="sendTypeUrl">
            <div class="el-form-item-box">
              <el-select v-model="ruleForm.sendAddress" placeholder="选择收货地址" style="width: 520px">
                <el-option v-for="item in addressList" :key="item" :label="item" :value="item">
                  <span style="float: left">{{ item }}</span>
                  <el-button @click.stop="copyUrl(item)" style="float: right; margin: 3px 0 0 4px" type="primary" plain size="mini">复制</el-button>
                </el-option>
              </el-select>
              <el-button @click.stop="copyUrl(ruleForm.sendAddress)" type="primary" plain>复制地址</el-button>
            </div>
            <div v-if="ruleForm.sendAddress" style="margin-top: 10px;">
              <span style="line-height: 24px; color: #999;">{{ ruleForm.sendAddress }}</span>
            </div>
          </el-form-item>

          <el-form-item label="输入快递单号" prop="sendNumber" v-if="sendTypeUrl">
            <div class="el-form-item-box">
              <el-input v-model="ruleForm.sendNumber" placeholder="请将快递单号复制到此处" style="width: 485px"></el-input>
              <div class="right-area" v-if="sendTypeUrl">
                <el-button class="com-primary-btn" type="primary" icon="el-icon-circle-plus-outline" @click="createExpress">创建快递单</el-button>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="submit-box">
              <el-button @click="show.dialogVisible = false">取 消</el-button>
              <el-button :loading="loading" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="right-area" v-if="sendTypeUrl">
        <el-button class="com-primary-btn" type="primary" icon="el-icon-circle-plus-outline" @click="createExpress">创建快递单</el-button>
      </div> -->
    </div>
  </el-dialog>
</template>

<script>
import { SEND_TYPE, ADDRESS } from '@/utils/constants.js'
import { sendAddressList } from '@/api/order.js'
import { copyUrl } from '@/utils/index.js'
export default {
  name: 'SendDialog',
  props: {
    show: {
      type: Object,
      default: () => {
        return {
          dialogVisible: false,
          orderId: ''
        }
      }
    },
    title: {
      type: String,
      default: '寄送物品'
    }
  },
  data() {
    return {
      SEND_TYPE,
      ADDRESS,
      receiveAddress: ADDRESS.from[0],
      ruleForm: {
        sendType: '顺丰快递',
        sendAddress: '',
        sendNumber: ''
      },
      rules: {
        sendType: { required: true, message: '请选择快递方式', trigger: 'blur' },
        sendAddress: { required: true, message: '请选择收货地址', trigger: 'change' },
        sendNumber: { required: true, message: '请输入快递单号', trigger: 'change' }
      },
      addressList: [],
      loading: false
    }
  },
  computed: {
    sendTypeUrl() {
      switch (this.ruleForm.sendType) {
        case '顺丰快递':
          return 'https://www.sf-express.com/we/ow/chn/sc/ship/home'
        case '京东快递':
          return 'https://www.jdl.cn/'
        case '中通快递':
          return 'https://my.zto.com/create'
        default:
          return ''
      }
    },
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    this.getAddressList()
  },
  methods: {
    copyUrl,
    copyAddress() {
      copyUrl(this.ADDRESS.from[0])
    },
    createExpress() {
      window.open(this.sendTypeUrl)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('confirmSend', this.ruleForm)
          setTimeout(() => {
            this.show.dialogVisible = false
            this.loading = false
          }, 0)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getAddressList() {
      sendAddressList({
        orderId: this.show.orderId
      }).then((res) => {
        this.addressList = res.data ? res.data.split(',') : []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.send-dialog {
  display: flex;
  .left-area {
    margin-right: 12px;
    flex: 1;
    ::v-deep .el-form {
      .el-form-item__label {
        line-height: 36px;
      }
      .el-form-item__content {
        line-height: 1;
        .el-input {
          .el-input__inner {
            height: 36px;
            line-height: 36px;
          }
        }
        .el-select {
          display: block;
        }
        .submit-box {
          margin-top: 20px;
          margin-bottom: -15px;
          text-align: right;
        }
      }
    }
    .show-info {
      .text {
        color: #333;
        & + .text {
          margin-top: 18px;
        }
      }
    }
  }
  .right-area {
    color: red;
    ::v-deep .el-button {
      padding: 8px 16px;
      i {
        font-size: 20px;
        font-weight: bold;
        vertical-align: -2px;
      }
    }
  }
  .el-form-item-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
