<template>
  <el-dialog title="分享账单链接" :visible.sync="showDialog.dialogVisible" width="500px">
    <p class="text">账单金额：￥{{ money }}</p>
    <div v-if="userInfo.currentRole === '管理员'">
      <p class="title">结账二维码链接：</p>
      <div class="grey-box">
        <p class="name">微信账户：&emsp;<el-switch v-model="value1"></el-switch></p>
        <p class="link">{{ wechatUrl }}
          <el-button type="primary" plain size="mini" @click="copyUrl(wechatUrl)">复制</el-button>
        </p>
        <p class="name">支付宝账号：<el-switch v-model="value2"></el-switch></p>
        <p class="link">{{ alipayUrl }}
          <el-button type="primary" plain size="mini" @click="copyUrl(alipayUrl)">复制</el-button>
        </p>
      </div>
    </div>
    <div v-else>
      <p class="name" v-if="wechatUrl">微信支付链接：</p>
      <p class="link" v-if="wechatUrl">{{ wechatUrl }}
        <el-button type="primary" plain size="mini" @click="copyUrl(wechatUrl)">复制</el-button>
      </p>
      <p class="name" v-if="alipayUrl">支付宝支付链接：</p>
      <p class="link" v-if="alipayUrl">{{ alipayUrl }}
        <el-button type="primary" plain size="mini" @click="copyUrl(alipayUrl)">复制</el-button>
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showDialog.dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="operatePayMethod">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { createPayUrl, operatePayMethod } from '@/api/order.js'
import { copyUrl } from '@/utils/index.js'
export default {
  name: 'OrderSharePay',
  props: {
    showDialog: {
      type: Object,
      default: () => {
        return {
          dialogVisible: false,
          orderNumber: '',
          id: ''
        }
      }
    }
  },
  data() {
    return {
      money: '',
      alipayUrl: '',
      wechatUrl: '',
      value1: true,
      value2: true
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    this.createPayUrl()
  },
  methods: {
    copyUrl,
    // 格式化状态值
    typeFormatter(row, prop, arr, filterKey, returnKey) {
      if (!row[prop] && row[prop] !== 0) {
        return ''
      }
      const item = arr.find((item) => Number(item[filterKey || 'value']) === Number(row[prop]))
      return item[returnKey || 'label']
    },
    // 结账链接
    createPayUrl() {
      const { orderNumber } = this.showDialog
      createPayUrl({
        orderNumber
      }).then(res => {
        const { alipayUrl, wechatUrl, money, closePayMethod } = res.data
        this.alipayUrl = alipayUrl
        this.wechatUrl = wechatUrl
        this.money = money
        if (closePayMethod) {
          this.value1 = closePayMethod.indexOf('3') > -1 ? false : true
          this.value2 = closePayMethod.indexOf('4') > -1 ? false : true
        }
      })
    },
    // 控制结账链接
    operatePayMethod() {
      if (this.userInfo.currentRole !== '管理员') {
        this.showDialog.dialogVisible = false
        return
      }
      let closePayMethod = []
      if (!this.value1) {
        closePayMethod.push(3)
      }
      if (!this.value2) {
        closePayMethod.push(4)
      }
      const { id } = this.showDialog
      operatePayMethod({
        orderId: id,
        closePayMethod: closePayMethod.join(',')
      }).then(res => {
        this.showDialog.dialogVisible = false
        this.$message.success('设置' + res.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  margin-bottom: 16px;  
}
.link {
  margin-bottom: 16px;
  display: flex;
  // align-items: center;
  font-size: 12px;
  .el-button {
    margin: -4px 0 0 16px;
    height: 27px;
  }
}
.name {
  margin-bottom: 8px;
}
.title {
  margin-bottom: 10px;
}
.grey-box {
  padding: 10px;
  border-radius: 4px;
  background-color: #F9F9F9;
}
</style>
