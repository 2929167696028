<!--
 * @Author: Ellie
 * @Date: 2022-02-14
 * @LastEditTime: 2022-02-15
 * @LastEditors: Please set LastEditors
 * @Description: 订单管理 > 创建订单 > 订单表单
 * @FilePath: src/views/order/OrderForm.vue
-->
<template>
  <div class="order-form">
    <div>
      <!-- 新增操作区域 -->
      <div class="operate-bar operate-bar-add" v-if="!detail">
        <div class="h2-title-box">
          <h2 class="h2-title">订单信息</h2>
          <div class="order-type">{{ orderTypeTitle }}</div>
        </div>
        <div class="search-box">
          <div class="label"><span>*</span>机构名称</div>
          <el-select v-model="company" placeholder="请选择" @change="changeCompany">
            <el-option v-for="item in options" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          <div class="btn-box">
            <el-button @click="handleCancel" class="com-plain-btn">取消</el-button>
            <el-button @click="lastStep" type="primary" v-if="isActive > 0">上一步</el-button>
            <el-button @click="nextStep" type="primary" v-if="isActive < tabList.length - 1">下一步</el-button>
            <el-button @click="submitFormData" :loading="loading" type="primary" class="com-orange-btn" v-else>提交</el-button>
            <el-button @click="saveFormData" :loading="loading" type="primary">暂存</el-button>
          </div>
        </div>
      </div>
      <!-- 详情操作区域 -->
      <!-- <operate-bar-detail :activeComp="activeComp" :formData="formData" @submitFormData="submitFormData" v-else></operate-bar-detail> -->
      <operate-bar-detail-1 :activeComp="activeComp" :formData="formData" @submitFormData="submitFormData" :params="params" v-else></operate-bar-detail-1>
      <!-- <div class="operate-line"></div> -->
    </div>

    <!-- 蛐蛐添加 -->
    <div class="operate-content">
      <!-- 详情操作区域 -->
      <operate-bar-detail-2 :activeComp="activeComp" :formData="formData" @submitFormData="submitFormData" :params="params" v-if="detail"></operate-bar-detail-2>
      <div class="operate-line"></div>
      <!-- 新增操作区域 -->
      <div class="operate-bar operate-bar-add" v-if="!detail">
        <div class="file-show" v-if="fileUpload">
          <div class="grey-box grey-box-mar">
            <h2 class="h2-title-border">
              <span>{{ fileUpload.mode }}</span>
              <div class="h2-title-desc-box">
                <div class="h2-title-desc">{{ fileUpload.desc }} </div>
                <div class="h2-title-desc red">（上传数据或寄送实物）</div>
              </div>
            </h2>
            <video-file-list :fileArr="fileUpload.children" :mode="fileUpload.mode" :isSend="fileUpload.isSend"></video-file-list>
          </div>
        </div>
      </div>
      <!-- 详情操作区域 -->
      <!-- <operate-bar-detail :activeComp="activeComp" :formData="formData" @submitFormData="submitFormData" v-else></operate-bar-detail> -->

      <div class="scheme-box" v-if="formData && formData.isShowConfirm">
        <h2 v-if="schemeArr.children && schemeArr.children.length">待确认报告/方案</h2>
        <h2 class="center-title" v-else>请在"报告数据"中查看并确认报告</h2>
        <div class="video-list-box">
          <div class="upload-area" v-for="(item, i) in schemeArr.children" :key="i">
            <video-file-single :fileObj="item" :isEdit="false"></video-file-single>
          </div>
        </div>
      </div>
      <!-- 表单区域 -->
      <div class="form-area">
        <div class="tab-header">
          <ul :class="['tab-list', { 'tab-list-detail': detail }]">
            <li v-for="(item, i) in tabList" :key="i" @click="handleTabList(i)">
              <div :class="i === isActive ? 'item-box item-box-sel' : 'item-box'">
                <div class="icon-box"><img :src="item.iconActive" alt="" height="24px" /></div>
                <p class="tab-title">{{ item.name }}</p>
                <!-- <div class="icon-box"><img :src="i === isActive ? item.iconActive : item.icon" alt="" height="24px" /></div> -->
                <!-- <p><template v-if="tabList.length > 1">{{ i + 1 }}.</template>{{ item.name }}</p> -->
              </div>
              <div class="border-box" v-show="i === isActive"></div>
            </li>
          </ul>
          <div class="right-btns" v-if="detail">
            <el-link @click="viewRecords" type="primary">操作记录</el-link>
            <el-link @click="viewFiles" type="primary">查看全部文件</el-link>
          </div>
        </div>
        <!-- 信息组件 -->
        <component :ref="componentArr[activeComp - 1]" :is="componentArr[activeComp - 1]" :isActive="isActive" :detail="detail" :detailData="formData"></component>
        <!-- 查看全部文件 -->
        <order-file-list v-if="showDialogFile.dialogVisible" :showDialog="showDialogFile"></order-file-list>
        <!-- 操作记录 -->
        <order-opt-records v-if="showRecordsFile.dialogVisible" :showDialog="showRecordsFile"></order-opt-records>
      </div>
    </div>
  </div>
</template>

<script>
import WaikeOrder from '../components/waike-order/index.vue'
import ZuhanqiOrder from '../components/zuhanqi-order/index.vue'
import HedianOrder from '../components/hedian-order/index.vue'
import FuzhuOrder from '../components/fuzhu-order/index.vue'
import OperateBarDetail from '../order-detail/OperateBarDetail.vue'
import OperateBarDetail1 from '../order-detail/OperateBarDetail1.vue'
import OperateBarDetail2 from '../order-detail/OperateBarDetail2.vue'
import OrderFileList from '../order-list/OrderFileList.vue'
import OrderOptRecords from '../order-list/OrderOptRecords.vue'
import VideoFileList from '../components/video-info/VideoFileList.vue'
import VideoFileSingle from '../components/video-info/VideoFileSingle.vue'
import * as order from '@/api/order.js'
import { addTabList, detailTabList, copyFileUpload, copySameChild } from './order-form'
import { deepClone } from '@/utils/index'
export default {
  name: 'OrderForm',
  components: {
    WaikeOrder,
    ZuhanqiOrder,
    HedianOrder,
    FuzhuOrder,
    // 操作区域组件
    OperateBarDetail,
    OperateBarDetail1,
    OperateBarDetail2,
    OrderFileList,
    OrderOptRecords,
    VideoFileList,
    VideoFileSingle
  },
  props: {
    activeComp: {
      type: Number,
      default: 1
    },
    showReport: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  provide() {
    return {
      showUpload: this.showUpload
    }
  },
  data() {
    return {
      loading: false,
      company: '',
      options: [],
      // 保存未通过验证的tab页面
      tabValidList: [],
      isActive: 0,
      componentArr: ['WaikeOrder', 'ZuhanqiOrder', 'HedianOrder', 'FuzhuOrder'],
      componentTitleArr: ['外科类订单', '阻鼾器类订单', '颌垫类订单', '辅助分析类订单'],
      showDialogFile: {
        dialogVisible: false,
        id: ''
      },
      showRecordsFile: {
        dialogVisible: false,
        id: ''
      },
      showUpload: {
        ms: false
      },
      currentFileUpload: undefined,
      // currentReportUpload: null,
      changeStatus: 0,
      tabValidList: [],

      params: {
        salesmanId: '',
        engineerId: '',
        amountService: null, // 服务金额
        amountDiscount: null,
        discountCode: '',
        jawAmount: null, // 颌垫服务金额
        jawAmountDiscount: null,
        jawDiscountCode: '',
        res: 1,
        reason: ''
      }
    }
  },
  computed: {
    tabList() {
      let tabList
      if (!this.detail) {
        tabList = addTabList[this.activeComp].tabList
      } else {
        tabList = detailTabList[this.activeComp].tabList
      }
      if (this.showReport) {
        this.isActive = tabList.length - 1
      }
      return tabList
    },
    fileUpload() {
      if (!this.detail) {
        let fileUploadTmp = addTabList[this.activeComp].fileUpload
        if (fileUploadTmp) {
          fileUploadTmp = deepClone(fileUploadTmp)
          copySameChild(fileUploadTmp, this.currentFileUpload, this.showUpload)
          this.currentFileUpload = fileUploadTmp
          return fileUploadTmp
        }
      }
    },
    // reportUpload() {
    //   if (!this.detail && this.activeComp === 2) {
    //     let reportUpload = addTabList[5].fileUpload
    //     if (reportUpload) {
    //       reportUpload = deepClone(reportUpload)
    //       copySameChild(reportUpload, this.currentReportUpload, this.showUpload)
    //       this.currentReportUpload = reportUpload
    //       return reportUpload
    //     }
    //   }
    // },
    refComponent() {
      return this.componentArr[this.activeComp - 1]
    },
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    },
    // 报告/方案
    schemeArr() {
      const obj = this.formData.fileReport.find((item) => item.mode === '报告/方案')
      return obj || {}
    },
    orderTypeTitle() {
      return this.componentTitleArr[this.activeComp - 1]
    }
  },
  mounted() {
    this.getCompanies()
  },
  methods: {
    // 获取机构
    getCompanies() {
      // 有维护的地方测试一下
      this.options = this.userInfo.companys
      if (this.options && this.options.length === 1) {
        this.company = this.options[0]
      }
    },
    // 切换tab
    handleTabList(i) {
      // 蛐蛐注释
      // if (!this.detail) return
      this.saveTabValid(this.isActive)
      this.isActive = i
    },
    changeCompany(value) {
      console.log(value)
    },
    handleCancel() {
      this.$confirm('确定取消创建订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$emit('cancel')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 上一步
    lastStep() {
      this.saveTabValid(this.isActive)
      this.isActive--
    },
    validCurrentActive(index, isNoShowMsg) {
      if (!this.detail) {
        // 新建的时候校验必传
        // 当前标签页
        const currentTab = this.tabList[index]
        // 校验表单信息
        if (currentTab.required) {
          const components = currentTab.component.split('|')
          for (let index = 0; index < components.length; index++) {
            const component = components[index]
            console.log('component == ', component)
            if (this.$refs[this.refComponent].$refs[component]) {
              return this.$refs[this.refComponent].$refs[component].submitForm(isNoShowMsg)
            }
          }
        }
        // 如果是创建，需要校验上传文件信息
        if (this.fileUpload) {
          const formData = this.$refs[this.refComponent].formData
          const validFail = this.fileUpload.children.find((element) => {
            // 如果已经上传了，快递下单，不需要继续校验，如果未上传，需要校验
            if (element.requireSendNumber) {
              // 校验快递下单的快递地址、发货单号 dentalSendNumber dentalSendAddress
              if (formData.dentalSendNumber && formData.dentalSendAddress) {
                return false
              }
            }
            return element.required && (!element.files || element.files.length == 0)
          })
          if (validFail) {
            if (isNoShowMsg) {
              this.$message({
                type: 'error',
                message: validFail.failMsg
              })
            }
            return false
          }
          if (this.fileUpload.requireSend) {
            // 校验快递下单的快递地址、发货单号 dentalSendNumber dentalSendAddress
            if (!formData.dentalSendNumber || !formData.dentalSendAddress) {
              if (isNoShowMsg) {
                this.$message({
                  type: 'error',
                  message: '请填写快递单号'
                })
              }
              return false
            }
          }
        }
      }
      return true
    },
    // 蛐蛐添加 验证全部页面
    // 保存每个页面是否验证通过状态
    saveTabValid(index) {
      var list = this.tabValidList
      if (!this.validCurrentActive(index, false)) {
        list.push(index)
      } else {
        var ls = []
        for (let i = 0; i < list.length; i++) {
          if (list[i] != index) {
            ls.push(list[i])
          }
        }
        list = ls
      }
      this.tabValidList = list
    },
    // 获取最前面 未通过验证的页面 -1 则全部通过
    getTabValid() {
      if (this.tabValidList.length <= 0) {
        return -1
      }
      // 排序
      this.tabValidList.sort((a, b) => {
        return a - b
      })
      if (this.tabValidList[0] >= 0) {
        return this.tabValidList[0]
      } else {
        return -1
      }
    },
    // 下一步
    nextStep() {
      // 蛐蛐注释
      // 执行下一步之前需要校验当前组件必填项是否都填写
      if (this.validCurrentActive(this.isActive, true)) {
        this.saveTabValid(this.isActive)
        this.isActive++
      }
      // this.saveTabValid(this.isActive)
      // this.isActive++
    },
    //暂存
    saveFormData() {
      if (!this.company) {
        this.$message.warning('请选择机构名称')
        return
      }
      this.changeStatus = 0
      this.finalSaveForm((res) => {
        let formData = this.$refs[this.refComponent].formData
        formData.orderId = res.data
        this.gotoList()
      })
    },
    // 提交
    submitFormData(isEdit, changeStatus) {
      if (!this.company && isEdit !== 'isEdit') {
        this.$message.warning('请选择机构名称')
        return
      }
      this.saveTabValid(this.isActive)
      // 校验组件必填项
      var page = this.getTabValid()
      if (page >= 0) {
        this.isActive = page
        var that = this
        setTimeout(function () {
          that.validCurrentActive(page, true)
        }, 500)
        return
      }

      // return console.log("发起请求");

      this.changeStatus = changeStatus == void 0 ? 1 : changeStatus
      this.finalSaveForm((res) => {
        if (isEdit !== 'isEdit') {
          // 创建订单之后，需要执行流转
          order
            .orderOperate({
              orderId: res.data,
              operateMode: 0
            })
            .then((res) => {
              this.$message.success('订单创建' + res.msg)
              this.gotoList()
            })
        } else {
          // 编辑订单，关闭弹窗，回到列表
          this.$bus.$emit('backList', false)
          this.loading = false
        }
      })
    },
    // 跳转列表
    gotoList() {
      setTimeout(() => {
        // 跳转订单列表
        this.$router.push({
          path: '/order/list'
        })
        this.loading = false
      }, 500)
    },
    finalSaveForm(callback) {
      this.loading = true
      let formData = this.$refs[this.refComponent].formData
      // 上传文件赋值到表单中
      copyFileUpload(formData, this.fileUpload)
      // this.reportUpload && copyFileUpload(formData, this.reportUpload)
      if (this.company) {
        formData.company = this.company
      }
      if (typeof formData.questionReport == 'object') {
        formData.questionReport = JSON.stringify(formData.questionReport)
      }
      order[this.refComponent]({ ...formData, changeStatus: this.changeStatus })
        .then((res) => {
          callback(res)
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 查看操作记录
    viewRecords() {
      this.showRecordsFile.id = this.formData.orderId
      this.showRecordsFile.dialogVisible = true
    },
    viewFiles() {
      this.showDialogFile.id = this.formData.orderId
      this.showDialogFile.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.order-form {
  .operate-content {
    overflow: scroll;
    height: calc(100vh - 270px);
    min-height: 500px;
  }
  .operate-line {
    margin-top: -10px;
    height: 10px;
    width: 100%;
    background: $lightPrimaryColor;
  }
  .operate-bar {
    padding: 20px 24px 24px;
    background-color: #fff;
  }
  .operate-bar-add {
    .h2-title-box {
      display: flex;
      flex-direction: row;
    }
    .order-type {
      margin-bottom: 16px;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      color: $deepPrimaryColor;
      background: $middlePrimaryColor;
      padding: 0px 10px;
      line-height: 20px;
    }
    .h2-title {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 500;
      color: $fontDeepColor;
      line-height: 20px;
    }
    .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        margin-right: 10px;
        span {
          color: $--color-danger;
        }
      }
      .btn-box {
        margin-left: auto;
      }
      .el-button {
        width: 75px;
      }
    }
  }
  .scheme-box {
    margin-top: 10px;
    padding: 20px 24px;
    box-sizing: border-box;
    width: 100%;
    background: rgba(224, 32, 32, 0.08);
    h2 {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $--color-danger;
      line-height: 20px;
    }
    .center-title {
      font-weight: bold;
      text-align: center;
    }
    .video-list-box {
      display: flex;
    }
    .upload-area {
      margin-right: 40px;
      width: 240px;
    }
  }
  .form-area {
    background: #ffffff;
    .tab-header {
      padding: 17px 20px 0;
      display: flex;
      justify-content: space-between;
      position: relative;
      background: linear-gradient(180deg, #f2faf9 0%, #ffffff 100%);
      .tab-list {
        display: flex;
        li {
          min-width: 80px;
          text-align: center;
          & + li {
            margin-left: 80px;
          }
          .item-box {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            padding: 10px 16px;
            .icon-box {
              margin-right: 6px;
            }
            p {
              font-size: 18px;
              font-weight: 500;
              color: $deepPrimaryColor;
              // line-height: 22px;
            }
          }
          .item-box-sel {
            background: #d6f4f4;
            border-radius: 10px 10px 0 0;
          }
          .border-box {
            margin: -4px auto 0;
            width: 80px;
            height: 4px;
            background: $primaryColor;
            border-radius: 4px;
          }
        }
        &-detail {
          li {
            cursor: pointer;
          }
        }
      }
      .right-btns {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
        .el-link {
          & + .el-link {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .file-show {
    margin-top: 0px;
    background: #f9f9f9;
    padding: 16px 0;
    .h2-title-border {
      display: flex;
      span {
        display: block;
        padding-left: 10px;
        border-left: 10px solid $primaryColor;
        font-size: 15px;
        color: #333333;
      }
    }
    .h2-title-desc-box {
      display: flex;
      flex-direction: row;
    }
    .h2-title-desc {
      font-size: 14px;
      font-weight: normal;
      color: #a6a4a4;
      margin-top: 2px;
      margin-left: 20px;
    }
    .red {
      color: $redColor;
    }
  }
  // .merge-show {
  //   display: flex;
  //   background-color: #f9f9f9;
  //   position: relative;
  //   .h2-title {
  //     border: none !important;
  //   }
  // }
}
</style>