<template  >
  <order-form
    :activeComp="showDialogDetail.orderType"
    :showReport="showDialogDetail.showReport"
    :detail="true"
    :orderTypeName="orderTypeName"
    :formData="formData"
    v-if="loading && formData.status != 0"
  ></order-form>
  <order-form-store
    :activeComp="showDialogDetail.orderType"
    :formData="formData"
    v-else-if="loading && formData.status == 0"
  ></order-form-store>
</template>

<script>
import OrderForm from '../components/OrderForm.vue'
import OrderFormStore from '../components/OrderFormStore.vue'
import * as order from '@/api/order.js'
export default {
  name: 'OrderDetail',
  props: {
    showDialogDetail: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    OrderForm,
    OrderFormStore
  },
  provide() {
    return {
      // 获取字段数据时需要sourceId
      detail: true
    };
  },
  data() {
    return {
      componentArr: ['WaikeOrder', 'ZuhanqiOrder', 'HedianOrder', 'FuzhuOrder'],
      formData: {},
      loading: false
    }
  },
  computed: {
    orderTypeName() {
      const { orderType } = this.showDialogDetail
      return this.componentArr[orderType - 1]
    }
  },
  mounted() {
    this.getFormDetail()
  },
  methods: {
    getFormDetail() {
      const { orderId } = this.showDialogDetail
      order[this.orderTypeName]({ orderId }, 'detail')
        .then((res) => {
          this.formData = res.data
          // this.formData.patientName = '章三'
        })
        .finally(() => {
          this.loading = true
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
