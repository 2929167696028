<template>
  <el-dialog title="立即分配" :visible.sync="showDialog.dialogVisible" width="600px">
    <div class="search-form">
      人员名称：
      <el-input v-model="userName"></el-input>
      <el-button type="primary" @click="getPersonList('工程师')">查询</el-button>
    </div>
    <el-table ref="personTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange" @current-change="currentChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="userName" label="人员名称"> </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="showDialog.dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="confirmAssignment">确定分配</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getUserList } from '@/api/common.js'
import { orderOperate } from '@/api/order.js'
import ListUtils from '@/mixins/list-utils'
export default {
  name: 'OrderAssign',
  mixins: [ListUtils],
  props: {
    showDialog: {
      type: Object,
      default: () => {
        return {
          dialogVisible: false
        }
      }
    },
    operate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userName: '',
      params: {
        engineerId: ''
      },
      multipleSelection: [],
    }
  },
  mounted() {
    this.getPersonList('工程师')
  },
  methods: {
    // 获取人员
    getPersonList(roleName) {
      getUserList({
        roleName,
        userName: this.userName
      }).then((res) => {
        this.list = res.data
      })
    },
    // 确认分配
    confirmAssignment() {
      this.params.engineerId = this.multipleSelection.id
      if (!this.params.engineerId) {
        this.$message.warning('请选择分配人员')
        return
      }
      this.handleOperate()
    },
    // 操作接口
    handleOperate() {
      const { orderId, operateMode } = this.showDialog
      orderOperate({
        orderId,
        operateMode,
        ...this.params
      }).then(res => {
        this.$message.success(res.msg)
        if (!this.operate) {
          this.$emit('submitEditForm', false)
        } else {
          this.$emit('callbackFunc', false)
        }
      })
    },
    // 选择人员
    handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.personTable.clearSelection()
        this.$refs.personTable.toggleRowSelection(val[val.length-1])
      }
      this.multipleSelection = val[val.length-1];
    },
    currentChange(val) {
      this.$refs.personTable.toggleRowSelection(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-form {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .el-input {
    margin-right: 8px;
    width: 200px;
  }
}
</style>
