<template>
  <el-upload
    :class="['image-uploader-box', {'is-disabled' : !isCanUpload}]"
    :http-request="ossUpload"
    :show-file-list="false"
    :before-upload="beforeUpload"
    action="/api/upload/sts"
    multiple
    :disabled="uploadStatus.value === 'loading' || !isCanUpload"
  >
    <slot />
  </el-upload>
</template>

<script>
import { sts } from '@/api/upload'
const OSS = require('ali-oss')
export default {
  name: 'UploadFile',
  props: {
    limit: {
      type: Array,
      default: () => []
    },
    progress: {
      type: Object,
      default: () => {
        return {
          value: 0
        }
      }
    },
    limitM: {
      type: Number,
      default: 2000
    },
    uploadType: {
      type: String,
      default: 'image'
    },
    uploadStatus: {
      type: Object,
      default: () => {
        return {
          value: 'success'
        }
      }
    }
  },
  inject: {
    isDisabled: { default: false },
    isCanUpload: { default: 1 }
  },
  data() {
    return {
      file: {},
      // progress: 0,
      options: {
      // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          this.progress.value = p * 100
          // console.log(p)
        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024
      }
    }
  },
  methods: {
    beforeUpload(file) {
      const type = this.handleFileType(file)
      const isLimitType = this.limit.indexOf(type.toLowerCase()) > -1;
      const isLimitM = file.size / 1024 / 1024 < this.limitM;
      if (!isLimitType) {
        if (this.uploadType === 'image') {
          this.$message.error('支持格式为jpeg、jpg、png、gif');
        } else {
          this.$message.error('请按照格式限制的类型上传');
        }
      }
      if (!isLimitM) {
        this.$message.error(`最大可上传${this.limitM}MB`);
      }
      return isLimitType && isLimitM;
    },
    /**
     * 分片上传
     *
     * @param {文件} file
     */
    ossUpload(fileInfo) {
      const file = fileInfo.file
      sts()
        .then((res) => {
          this.uploadStatus.value = 'loading'
          const { data } = res
          const ossClient = this.newOssClient(data)
          const timestamp = new Date().getTime()
          const folder =  (data.folder ? data.folder + timestamp : timestamp) + '/'
          const type = this.handleFileType(file)
          // 文件名称添加时间戳
          // const fileName = this.addTimeStamp(file.name, '.')
          if (ossClient) {
            // 分片上传
            ossClient
              .multipartUpload(folder + file.name, file, this.options)
              .then((result) => {
                this.file = {
                  size: file.size,
                  name: file.name,
                  type: type,
                  path: result.res.requestUrls[0].split('?')[0]
                }
                this.$emit('handleFileList', this.file)
                this.uploadStatus.value = 'success'
              })
              .catch((err) => {
                this.uploadStatus.value = 'fail'
                console.log('上传失败', err)
              })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    /**
     * 创建ossClient
     *
     * @param {临时秘钥} data
     * @returns
     */
    newOssClient({ bucket, regionId, keyId, keySecret, stsToken }) {
      return new OSS({
        region: regionId,
        accessKeyId: keyId,
        accessKeySecret: keySecret,
        secure: true,
        refreshSTSToken: async () => {
          // 向您搭建的STS服务获取临时访问凭证。
          const res = await sts()
          const { keyId, keySecret, stsToken } = res.data
          return {
            accessKeyId: keyId,
            accessKeySecret: keySecret,
            stsToken: stsToken
          }
        },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 1200000,
        stsToken,
        bucket
      })
    },
    /**
     * 处理文件类型
     *
     * @param {Object} file - 文件参数
     * @returns
     */
    handleFileType(file) {
      const fileArr = file.name.split('.')
      const length = fileArr.length
      const type = fileArr[length - 1]
      return type
    },
    /**
     * 文件名称后面添加时间戳
     *
     * @param {String} fileName - 文件名称
     * @param {String} beforeWhich - 在哪里添加时间戳
     * @returns
     */
    addTimeStamp(fileName, beforeWhich) {
      const timestamp = new Date().getTime()
      let strArr = fileName.split('')
      strArr.splice(fileName.lastIndexOf(beforeWhich), 0, timestamp)
      return strArr.join('')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  ::v-deep .el-upload {
    cursor: not-allowed;
    .btn {
      color: #999999 !important;
    }
  }
}
</style>
