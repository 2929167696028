<template>
  <div class="business-statistic">
    <div class="com-page-nav"><span>订单管理 &gt;</span> 收款明细</div>
    <div class="main-area">
      <div class="filter-bar">
        <div class="filter-box">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="收款单号">
              <el-input v-model="searchForm.collectionNumber"></el-input>
            </el-form-item>
            <el-form-item label="收款方式">
              <el-select v-model="searchForm.payMethod" clearable>
                <el-option
                  v-for="item in PAY_METHOD"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="收款时间">
              <el-date-picker
                v-model="collectionDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table v-loading="loading" :data="list" style="width: 100%">
        <el-table-column prop="collectionNumber" label="收款单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="orderNumber" label="订单号" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="amountPaid" label="应收金额"> </el-table-column>
        <el-table-column prop="payMethod" label="收费方式">
          <template slot-scope="scope">
            <span>{{ showLabel(PAY_METHOD, scope.row.payMethod) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="collectionType" label="收款类型">
          <template slot-scope="scope">
            <span>{{ showLabel(PAY_MODE, scope.row.payMode) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="operatorName" label="操作人"> </el-table-column>
        <el-table-column prop="collectionAt" label="收款时间" show-overflow-tooltip> </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.current"
        :limit.sync="listQuery.size"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import * as orderApi from '@/api/order.js'
import { PAY_METHOD, PAY_MODE } from '@/utils/constants.js'
import ListUtils from '@/mixins/list-utils'
export default {
  name: 'CollectionList',
  mixins: [ListUtils],
  data() {
    return {
      PAY_METHOD,
      PAY_MODE,
      collectionDate: [],
      searchForm: {
        collectionNumber: undefined,
        payMethod: undefined,
        collectionStart: undefined,
        collectionEnd: undefined
      },
      tagItem: {}
    }
  },
  watch: {
    collectionDate: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.searchForm.collectionStart = undefined
          this.searchForm.collectionEnd = undefined
        } else {
          this.searchForm.collectionStart = newVal[0]
          this.searchForm.collectionEnd = newVal[1]
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // 获取列表
      this.getList()
    },
    // 获取标签列表
    getList() {
      this.loading = true
      orderApi.collectionList({ ...this.listQuery, ...this.searchForm }).then((res) => {
        const { rows, total } = res.data
        this.list = rows || []
        this.total = total
        this.loading = false
      })
    },
    onSearch() {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.business-statistic {
  .explain {
    color: #aaaaaa;
  }
}
</style>
