<template>
  <div class="order-list">
    <div class="com-page-nav"><span>人员管理 &gt;</span> 人员列表</div>
    <div class="main-area">
      <div class="filter-bar">
        <div class="btn-box">
          <el-button class="com-primary-btn" type="primary" size="small" icon="el-icon-plus" @click="handleCreate">添加人员</el-button>
        </div>
        <div class="filter-box">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input v-model="searchForm.userName"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="searchForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="启用状态">
              <el-select v-model="searchForm.useAble">
                <el-option v-for="item in useAbles" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table :data="list" style="width: 100%">
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="userName" label="姓名" width="100"></el-table-column>
        <el-table-column prop="gender" label="性别" width="80">
          <template slot-scope="scope">
            <span>{{ showLabel(USER_GENDER, scope.row.gender) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="position" label="职位">
          <template slot-scope="scope">
            <span>{{ showLabel(USER_POSITION, scope.row.position) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="roleNames" label="角色" :formatter="(row) => typeFormatter(row, 'roleNames')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="在职状态">
          <template slot-scope="scope">
            <span>{{ showLabel(USER_STATUS, scope.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="useAble" label="启用状态">
          <template slot-scope="scope">
            <span>{{ showLabel(USER_ABLES, scope.row.useAble) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间" width="180"> </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button type="primary" plain size="small" @click="handleUpdate(scope.row)">编辑</el-button>
            <el-button type="primary" plain size="small" @click="handleDelete(scope.row)">删除</el-button>
            <el-button type="primary" plain size="small" @click="handleResetPwd(scope.row)">重置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.size" @pagination="getList" />
    </div>

    <user-detail :title="title" :show="show" @flushList="flushList" :row="currentRow"></user-detail>
  </div>
</template>

<script>
import { USER_ABLES, USER_STATUS, USER_POSITION, USER_GENDER } from '@/utils/constants.js'
import UserDetail from './detail.vue'
import ListUtils from '@/mixins/list-utils'
import * as userApi from '@/api/user'
export default {
  name: 'User',
  components: {
    UserDetail
  },
  mixins: [ListUtils],
  data() {
    return {
      USER_ABLES,
      USER_STATUS,
      USER_POSITION,
      USER_GENDER,
      useAbles: [{ label: '全部', value: '' }, ...USER_ABLES],
      searchForm: {
        orderBy: 'created_at',
        orderMode: 1,
        userType: 0,
        userName: undefined,
        phone: undefined,
        useAble: ''
      }
    }
  },
  created() {
    this.title = '用户详情'
    this.getList()
  },
  methods: {
    // 格式化数组
    typeFormatter(row, prop) {
      if (!row[prop] || !row[prop].length) {
        return ''
      }
      return row[prop].join('，')
    },
    getList() {
      this.loading = true
      userApi.listByType({ ...this.listQuery, ...this.searchForm }).then((res) => {
        // const { rows, total } = res.data
        // this.list = rows
        // this.total = total
        this.getFinishing(res.data)
        this.loading = false
      })
    },
    getFinishing(data) {
      // 前端隐藏 管理员  会产生总条数不对的情况  后面 后端隐藏 可以解决该问题
      const hiddenList = ['15264146176']
      const { rows, total } = data
      var index = 0
      var l = []
      for (let i = 0; i < rows.length; i++) {
        var isFin = false
        for (let j = 0; j < hiddenList.length; j++) {
          if (rows[i].phone == hiddenList[j]) {
            index++
            isFin = true
          }
        }
        if (!isFin) {
          l.push(rows[i])
        }
      }
      this.list = l
      console.log('index == ', index)
      this.total = total - index
    },
    flushList() {
      this.show.userDetail = false
      this.getList()
    },
    onSearch() {
      this.getList()
    },
    handleCreate() {
      this.currentRow = {}
      this.show.userDetail = true
    },
    handleUpdate(row) {
      this.currentRow = row
      this.show.userDetail = true
    },
    handleDelete(row) {
      this.$confirm('是否确认删除?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return userApi.del(row.id)
        })
        .then(() => {
          this.getList()
          this.$message({ showClose: true, message: '删除成功', type: 'success' })
        })
        .catch(function () {})
    },
    handleResetPwd(row) {
      this.$prompt('请输入"' + row.userName + '"的新密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          userApi.resetUserPwd(row.id, value).then((response) => {
            if (response.code === 200) {
              this.$message({
                showClose: true,
                message: '修改成功，新密码是：' + value,
                type: 'success'
              })
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.order-list {
  .main-area {
    .filter-bar {
      padding: 22px;
      background-color: #fff;
      .btn-box {
        margin-bottom: 12px;
        .com-primary-btn {
          // background-color: $deepPrimaryColor;
        }
      }
    }
  }
}
</style>
