import { deepClone } from '@/utils/index';
import moment from 'moment'
import ExportButton from '@/components/ExportButton/index.vue'
export default {
  components: {
    ExportButton
  },
  filters: {
    formatDate: function (value) {
      if (value === undefined || value === '') {
        return value;
      }
      if (value === 'null' || value === null) {
        return '';
      }
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
  },
  data() {
    return {
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      show: {
        userDetail: false,
        tag: false
      },
      // 当前选中行数据
      currentRow: {},
      // 数据列表
      list: [],
      // 总条数
      total: 0,
      // 查询参数
      listQuery: {
        current: 1,
        size: 10
      }
    };
  },
  mounted() {

  },
  methods: {
    showLabel: function (items, value) {
      const lableItem = items.find((item) => item.value == value)
      if (lableItem) {
        return lableItem.label
      }
      return ''
    },
    /**
     * 克隆方法
     * @param {*} obj  克隆的对象
     */
    deepClone(obj) {
      return deepClone(obj)
    },
    validValue(value) {
      if (value === '' || value == null || value == undefined) {
        return false;
      }
      return true
    }
  }
}
