<template>
  <el-dialog :title="title" :visible.sync="show.dialogVisible" width="800px">
    <div class="send-dialog">
      <div class="left-area">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" v-if="title === '快递下单'">
          <el-form-item label="选择快递方式" prop="dentalSendType">
            <el-select v-model="ruleForm.dentalSendType" placeholder="快递方式">
              <el-option v-if="userInfo.currentRole == '医生'" label="顺丰快递" value="顺丰快递"></el-option>
              <el-option v-else v-for="item in SEND_TYPE" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择发货地址" prop="dentalSendAddress">
            <div class="el-form-item-box">
              <el-select v-model="ruleForm.dentalSendAddress" placeholder="选择发货地址" popper-class="send-customer-select" style="width: 520px">
                <el-option v-for="item in userInfo.addresses" :key="item" :label="item" :value="item">
                  <div class="left-text">{{ item }}</div>
                  <el-button @click.stop="copyUrl(item)" type="primary" plain size="mini">复制</el-button>
                </el-option>
              </el-select>
              <el-button @click.stop="copyUrl(ruleForm.dentalSendAddress)" type="primary" plain>复制地址</el-button>
            </div>
            <div v-if="ruleForm.dentalSendAddress" style="margin-top: 10px;">
              <span style="line-height: 24px; color: #999;">{{ ruleForm.dentalSendAddress }}</span>
            </div>
          </el-form-item>

          <el-form-item label="选择收货地址" prop="dentalReceiveAddress">
            <div class="el-form-item-box">
              <el-select v-model="dentalReceiveAddress" placeholder="选择发货地址" popper-class="send-customer-select" style="width: 520px">
                <el-option v-for="item in ADDRESS.to" :key="item" :label="item" :value="item">
                  <div class="left-text">{{ item }}</div>
                  <el-button @click.stop="copyUrl(item)" type="primary" plain size="mini">复制</el-button>
                </el-option>
              </el-select>
              <el-button @click.stop="copyUrl(dentalReceiveAddress)" type="primary" plain>复制地址</el-button>
            </div>
            <div v-if="dentalReceiveAddress" style="margin-top: 10px;">
              <span style="line-height: 24px; color: #999;">{{ dentalReceiveAddress }}</span>
            </div>
          </el-form-item>

          <el-form-item label="输入快递单号" prop="dentalSendNumber">
            <div class="el-form-item-box">
              <el-input v-model="ruleForm.dentalSendNumber" placeholder="请将快递单号复制到此处" style="width: 490px"></el-input>
              <div class="right-area">
                <el-button v-if="title === '快递下单'" class="com-primary-btn" type="primary" icon="el-icon-circle-plus-outline" @click="createExpress">创建快递单</el-button>
                <el-button v-else class="com-primary-btn" type="primary" icon="el-icon-view" @click="viewStatus">查看物流状态</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="submit-box">
              <el-button @click="show.dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </div>
          </el-form-item>
        </el-form>
        <div class="show-info" v-else>
          <p class="text">寄送方式：{{ formData.dentalSendType }}</p>
          <p class="text">快递单号：{{ formData.dentalSendNumber }}</p>
        </div>
      </div>
      <!-- <div class="right-area">
        <el-button v-if="title === '快递下单'" class="com-primary-btn" type="primary" icon="el-icon-circle-plus-outline" @click="createExpress">创建快递单</el-button>
        <el-button v-else class="com-primary-btn" type="primary" icon="el-icon-view" @click="viewStatus">查看物流状态</el-button>
      </div> -->
    </div>
  </el-dialog>
</template>

<script>
import { SEND_TYPE, ADDRESS } from '@/utils/constants.js'
import { copyUrl } from '@/utils/index.js'
export default {
  name: 'SendDialog',
  props: {
    show: {
      type: Object,
      default: () => {
        return {
          dialogVisible: false
        }
      }
    }
    // title: {
    //   type: String,
    //   default: '快递下单'
    // }
  },
  inject: {
    formData: { default: {} }
  },
  data() {
    return {
      SEND_TYPE,
      ADDRESS,
      dentalReceiveAddress: ADDRESS.to[0],
      ruleForm: {
        dentalSendType: '顺丰快递',
        dentalSendAddress: '',
        dentalSendNumber: ''
      },
      rules: {
        dentalSendType: { required: true, message: '请选择快递方式', trigger: 'blur' },
        dentalSendAddress: { required: true, message: '请选择发货地址', trigger: 'change' },
        dentalSendNumber: { required: true, message: '请输入快递单号', trigger: 'change' }
      }
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    },
    title() {
      return this.formData.dentalSendNumber ? '查看物流' : '快递下单'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    copyUrl,
    init() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo.addresses && userInfo.addresses.length == 1) {
        this.ruleForm.dentalSendAddress = userInfo.addresses[0]
      }
    },
    copyAddress(text) {
      copyUrl(text)
    },
    createExpress() {
      window.open('https://www.sf-express.com/we/ow/chn/sc/ship/home')
    },
    viewStatus() {
      window.open(
        'https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/' +
          this.formData.dentalSendNumber
      )
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$bus.$emit('getDentalData', this.ruleForm)
          this.show.dialogVisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.send-dialog {
  display: flex;
  .left-area {
    margin-right: 12px;
    flex: 1;
    ::v-deep .el-form {
      .el-form-item__label {
        line-height: 36px;
      }
      .el-form-item__content {
        line-height: 1;
        .el-input {
          .el-input__inner {
            height: 36px;
            line-height: 36px;
          }
        }
        .el-select {
          display: block;
        }
        .submit-box {
          margin-top: 20px;
          margin-bottom: -15px;
          text-align: right;
        }
      }
    }
    .show-info {
      .text {
        color: #333;
        & + .text {
          margin-top: 18px;
        }
      }
    }
  }
  .right-area {
    color: red;
    ::v-deep .el-button {
      padding: 8px 16px;
      i {
        font-size: 20px;
        font-weight: bold;
        vertical-align: -2px;
      }
    }
  }
  .el-form-item-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
<style lang="scss">
.send-customer-select {
  .el-select-dropdown__item {
    padding: 4px 20px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-text {
      margin: 2px 16px 0 0;
      max-width: 280px;
      line-height: 1.4;
      box-sizing: border-box;
      white-space: normal;
      word-break: break-all;
    }
  }
}
</style>
