<template>
  <div :class="['create-photo', 'create-photo-' + status ]" :style="{ backgroundColor: bgColor }">
    <div class="icon"><i :class="['iconfont', this.icon]" :style="{ color: fontColor }"></i></div>
    <div class="text-name">{{ detail.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'CreatePhoto',
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    status: {
      type: String,
      default: 'open'
    }
  },
  data() {
    return {
      icon: 'icon-waike-wuquanxian',
      fontColor: '',
      bgColor: ''
    }
  },
  mounted() {
    this.chooseDefaultImg()
  },
  methods: {
    chooseDefaultImg() {
      switch(this.detail.name) {
        case '外科类订单':
          this.icon = 'icon-waike-wuquanxian'
          this.fontColor = '#00AEA5'
          this.bgColor = '#EBF9F8'
          break;
        case '阻鼾器类订单':
          this.icon = 'icon-zuhanqi-wuquanxian'
          this.fontColor = '#0000FF'
          this.bgColor = '#EFEFFF'
          break;
        case '颌垫类订单':
          this.icon = 'icon-hedian-wuquanxian'
          this.fontColor = '#F29808'
          this.bgColor = '#FBF0E0'
          break;
        case '辅助分析类订单':
          this.icon = 'icon-fuzhuzhenduan-wuquanxian'
          this.fontColor = '#0091FF'
          this.bgColor = '#EAF6FF'
          break;
        default:
          this.icon = 'icon-waike-wuquanxian'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-photo {
  margin-bottom: 8px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #EBF9F8;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  i {
    font-size: 55px;
  }
  .text-name {
    margin-top: 14px;
    font-size: 22px;
    color: $fontDeepColor;
    line-height: 33px;
  }
  &-close {
    background: #F3F3F3 !important;
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
    i {
      color: $fontLightColor !important;
    }
    .text-name {
      color: $fontLightColor;
    }
  }
}
</style>
