<template>
  <div class="base-info">
    <div class="base-info-box person-info">
      <h2 class="com-h2-title">患者基础信息</h2>
      <el-form
        class="demo-form-base"
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="80px"
        :disabled="isDisabled"
      >
        <el-form-item label="患者姓名" prop="patientName">
          <el-input v-model="formData.patientName" placeholder="请输入患者姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="patientSex">
          <el-radio-group v-model="formData.patientSex" size="medium">
            <el-radio
              v-for="(item, index) in patientGenderOptions"
              :key="index"
              :label="item.value"
              :disabled="item.disabled"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄" prop="patientAge">
          <el-input v-model.number="formData.patientAge" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号" prop="patientPhone">
          <el-input v-model="formData.patientPhone" placeholder="请输入手机号" :maxlength="11">
          </el-input>
        </el-form-item> -->
        <!-- <el-form-item label="入院时间" prop="patientStartAt">
          <el-date-picker v-model="formData.patientStartAt" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            placeholder="请选择入院时间"></el-date-picker>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="base-info-box design-info">
      <h2 class="com-h2-title">设计信息</h2>
      <el-form
        class="demo-form-design"
        ref="elForm2"
        :model="formData"
        size="medium"
        label-width="80px"
        :disabled="isDisabled"
      >
        <el-form-item label="患者类型" prop="diagnosisType">
          <el-radio-group v-model="formData.diagnosisType" size="medium">
            <el-radio
              v-for="(item, index) in diagnosisTypeOptions"
              :key="index"
              :label="item.value"
              :disabled="item.disabled"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <template v-if="formData.diagnosisType != '其他'">
          <el-form-item label="" prop="diagnosisTypeTwo">
            <el-radio-group v-model="formData.diagnosisTypeTwo" size="medium">
              <el-radio
                v-for="(item, index) in diagnosisTypeTwoOptions"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-radio
              >
              <el-radio v-if="formData.diagnosisType == '牙槽外科'" label="唇腭裂">唇腭裂</el-radio>
            </el-radio-group>
            <br />
            <el-input
              v-model="formData.diagnosisTypeTwoDescription"
              type="textarea"
              placeholder="请输入"
              show-word-limit
              :maxlength="100"
              :autosize="{ minRows: 4, maxRows: 4 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="诊断说明" prop="diagnosisDescription">
            <el-input
              v-model="formData.diagnosisDescription"
              type="textarea"
              placeholder="请输入"
              show-word-limit
              :maxlength="100"
              :autosize="{ minRows: 4, maxRows: 4 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="治疗计划" prop="treatmentPlane">
            <el-radio-group v-model="formData.treatmentPlane" size="medium">
              <el-radio
                v-for="(item, index) in treatmentPlaneOptions"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
            <el-radio-group
              v-model="formData.treatmentPlaneTwo"
              size="medium"
              v-if="formData.treatmentPlane === '上颌骨截骨术'"
            >
              <el-radio
                v-for="(item, index) in treatmentPlaneTwoOptions1"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
            <el-radio-group
              v-model="formData.treatmentPlaneTwo"
              size="medium"
              v-if="formData.treatmentPlane === '下颌骨截骨术'"
            >
              <el-radio
                v-for="(item, index) in treatmentPlaneTwoOptions2"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
            <el-checkbox-group
              v-model="formData.treatmentPlaneTwo"
              size="medium"
              v-if="formData.treatmentPlane === '颌骨重建'"
            >
              <el-checkbox
                v-for="(item, index) in treatmentPlaneTwoOptions3"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group>
            <el-radio-group
              v-model="formData.treatmentPlaneTwo"
              size="medium"
              v-if="formData.treatmentPlane === '种植技术'"
            >
              <el-radio
                v-for="(item, index) in treatmentPlaneTwoOptions4"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </template>
        <el-form-item label="备注" prop="treatmentPlaneDescription">
          <el-input
            v-model="formData.treatmentPlaneDescription"
            type="textarea"
            placeholder="请输入备注"
            show-word-limit
            :maxlength="100"
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import order from '@/mixins/order.js'
export default {
  name: 'BaseInfo',
  mixins: [order],
  data() {
    return {
      rules: {
        patientName: [
          {
            required: true,
            message: '请输入患者姓名',
            trigger: 'blur'
          }
        ],
        patientSex: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'change'
          }
        ],
        patientAge: [
          {
            required: true,
            message: '请输入年龄',
            trigger: 'blur'
          }
        ]
        // patientStartAt: [{
        //   required: true,
        //   message: '请选择入院时间',
        //   trigger: 'change'
        // }],
      },
      patientGenderOptions: [
        {
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 2
        }
      ],
      diagnosisTypeOptions: [
        {
          label: '牙槽外科',
          value: '牙槽外科'
        },
        {
          label: '头颈肿瘤外科',
          value: '头颈肿瘤外科'
        },
        {
          label: '种植',
          value: '种植'
        },
        {
          label: '其他',
          value: '其他'
        }
      ],
      diagnosisTypeTwoOptions: [
        {
          label: '骨性Ⅱ类',
          value: '骨性Ⅱ类'
        },
        {
          label: '骨性Ⅲ类',
          value: '骨性Ⅲ类'
        },
        {
          label: '偏颌',
          value: '偏颌'
        }
      ],
      treatmentPlaneOptions: [
        {
          label: '上颌骨截骨术',
          value: '上颌骨截骨术'
        },
        {
          label: '下颌骨截骨术',
          value: '下颌骨截骨术'
        },
        {
          label: '颏成型术',
          value: '颏成型术'
        },
        {
          label: '颌骨重建',
          value: '颌骨重建'
        },
        {
          label: '种植技术',
          value: '种植技术'
        }
      ],
      // 上颌骨截骨术
      treatmentPlaneTwoOptions1: [
        {
          label: 'Le Fort  -Ⅰ',
          value: 'Le Fort  -Ⅰ'
        },
        {
          label: 'Le Fort  -Ⅱ',
          value: 'Le Fort  -Ⅱ'
        },
        {
          label: 'Le Fort  -Ⅲ',
          value: 'Le Fort  -Ⅲ'
        },
        {
          label: '分段',
          value: '分段'
        },
        {
          label: '不分段',
          value: '不分段'
        },
        {
          label: '分块数',
          value: '分块数'
        }
      ],
      // 下颌骨截骨术
      treatmentPlaneTwoOptions2: [
        {
          label: '矢状劈开截骨',
          value: '矢状劈开截骨'
        },
        {
          label: '倒L型截骨',
          value: '倒L型截骨'
        },
        {
          label: '下颌支垂直骨截开术',
          value: '下颌支垂直骨截开术'
        }
      ],
      // 颌骨重建
      treatmentPlaneTwoOptions3: [
        {
          label: '术前模型',
          value: '术前模型'
        },
        {
          label: '术后模型',
          value: '术后模型'
        },
        {
          label: '数字化导板',
          value: '数字化导板'
        }
      ],
      // 种植技术
      treatmentPlaneTwoOptions4: [
        {
          label: '单颗',
          value: '单颗'
        },
        {
          label: '局部/无牙颌',
          value: '局部/无牙颌'
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // submitForm() {
    //   this.$refs['elForm'].validate(valid => {
    //     if (!valid) {
    //       this.valid = false
    //     } else {
    //       this.valid = true
    //     }
    //     // TODO 提交表单
    //   })
    //   return this.valid
    // },
    // resetForm() {
    //   this.$refs['elForm'].resetFields()
    // },
  }
}
</script>
<style lang="scss" scoped>
</style>
