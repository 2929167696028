import moment from 'moment'
export const tags = [
    { label: '按日', value: '1', dateType: 'date', valueFormat: 'yyyy-MM-dd' },
    { label: '按月', value: '2', dateType: 'month', valueFormat: 'yyyy-MM-dd' },
    { label: '按年', value: '3', dateType: 'year', valueFormat: 'yyyy-MM-dd' }
]

/**
 * 重置开始结束日期
 * 
 * @param {标签对象} tagItem 
 * @param {查询参数} searchForm 
 */
export function resetStartEnd(tagItem, searchForm) {
    if (tagItem.value == '1') {
        // 如果是日，开始时间为30天之前，结束时间为当前时间
        searchForm.start = moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD')
        searchForm.end = moment().startOf('day').format('YYYY-MM-DD')
    } else if (tagItem.value == '2') {
        // 如果是月，默认是今年的一月到12月
        searchForm.start = moment().startOf('day').format('YYYY') + '-01-01'
        searchForm.end = moment().startOf('day').format('YYYY') + '-12-31'
    } else {
        // 如果是年，默认值5年
        searchForm.start = moment()
            .year(moment().year() - 5)
            .startOf('year')
            .format('YYYY-MM-DD')
        searchForm.end = moment().startOf('day').format('YYYY-MM-DD')
    }
}

/**
 * 时间校验
 * 
 * @param {开始时间} startTime 
 * @param {结束时间} endTime 
 * @returns 
 */
export function validTime(startTime, endTime, callback) {
    let result = true
    let msg = ''
    if (!startTime) {
        msg = '开始时间不得为空'
        result = false
    }
    if (!endTime) {
        msg = '结束时间不得为空'
        result = false
    }
    const diff1 = moment(endTime).diff(moment(startTime), 'days')
    if (diff1 < 0) {
        msg = '结束时间不得小于开始时间'
        result = false
    }
    if (callback) {
        callback(result, msg)
    }
    return result
}
/**
 * 拼接请求参数
 * 
 * @param {对象} items 
 * @returns 
 */
export function concatParams(items) {
    let result = ''
    if (items) {
        Object.keys(items).forEach(key => {
            const item = items[key]
            if (result.length == 0) {
                result += key + "=" + item
            } else {
                result += "&" + key + "=" + item
            }
        })
    }
    return result
}