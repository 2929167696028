export default {
  props: {
    isActive: {
      type: Number,
      default: 0
    },
    detail: {
      type: Boolean,
      default: false
    },
    detailData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {}
  },
  created() {
    this.getFormDetail()
  },
  mounted() {
    // 给表单赋值
    this.$bus.$on('getDentalData', data => {
      const { dentalSendType, dentalSendAddress, dentalSendNumber } = data
      this.formData.dentalSendType = dentalSendType
      this.formData.dentalSendAddress = dentalSendAddress
      this.formData.dentalSendNumber = dentalSendNumber
    })
  },
  methods: {
    getFormDetail() {
      if (this.detail) {
        let stateData = this.detailData
        stateData.fileReport = this.handleArr(this.formData.fileReport, this.detailData.fileReport)
        stateData.dataReport = this.handleArr(this.formData.dataReport, this.detailData.dataReport)
        // 移动端不提交图片数据
        if (!this.detailData.imageReport || this.detailData.imageReport.length === 0) {
          stateData.imageReport = this.formData.imageReport
        }
        this.formData = stateData
      }
    },
    // 处理详情和现有数据
    handleArr(arr1, arr2) {
      return arr1.map((item) => {
        const detailItem = arr2 && arr2.find((detail) => item.mode === detail.mode)
        if (detailItem) {
          item.children = item.children.map((child) => {
            const findChildren = detailItem.children.find((detailChild) => {
              return child.mode === detailChild.mode
            })
            return findChildren || child
          })
        }
        return item
      })
    }
  }
}
