<template>
  <div class="order-list">
    <div class="com-page-nav"><span>数据管理 &gt;</span> 轨迹数据管理</div>
    <div class="main-area">
      <div class="filter-bar">
        <div class="filter-box">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="标签">
              <el-select v-model="searchForm.tags" multiple clearable>
                <el-option
                  v-for="item in customTags"
                  :key="item.id"
                  :label="item.tagName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据名称">
              <el-input v-model="searchForm.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="id" label="ID" width="180"> </el-table-column>
        <el-table-column prop="name" label="数据名称"> </el-table-column>
        <el-table-column prop="tagsName" label="标签">
          <template slot-scope="scope">
            <order-list-tags :tagName="scope.row.tagsName"></order-list-tags>
          </template>
        </el-table-column>
        <el-table-column prop="orderNumber" label="关联订单号"> </el-table-column>
        <el-table-column prop="createdAt" label="创建时间" width="180"> </el-table-column>
        <el-table-column label="操作" width="280">
          <template slot-scope="scope">
            <el-button type="primary" plain size="small" @click="addTag(scope.row)"
              >添加标签</el-button
            >
            <el-button type="primary" plain size="small" @click="handleUpdate(scope.row)"
              >编辑</el-button
            >
            <el-button type="primary" plain size="small" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.current"
        :limit.sync="listQuery.size"
        @pagination="getList"
      />
    </div>

    <track-detail
      :title="title"
      :show="show"
      @flushList="flushList"
      :row="currentRow"
    ></track-detail>
    <detail-tag :show="show" @flushList="flushList" :row="currentRow"></detail-tag>
  </div>
</template>

<script>
import TrackDetail from './detail.vue'
import DetailTag from './detail-tag.vue'
import ListUtils from '@/mixins/list-utils'
import * as dataManageApi from '@/api/data-manage'
import * as tagsApi from '@/api/tags.js'
import OrderListTags from '../../order/order-list/OrderListTags.vue'
export default {
  name: 'Track',
  components: {
    TrackDetail,
    DetailTag,
    OrderListTags
  },
  mixins: [ListUtils],
  data() {
    return {
      customTags: [],
      searchForm: {
        tags: undefined,
        dataName: undefined
      }
    }
  },
  created() {
    this.title = '轨迹数据文件'
    this.getTagList()
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      dataManageApi.getTrackList({ ...this.listQuery, ...this.searchForm }).then((res) => {
        const { rows, total } = res.data
        this.list = rows
        this.total = total
        this.loading = false
      })
    },
    // 获取标签列表
    getTagList() {
      let params = { type: 1 }
      tagsApi.getTagsList(params).then((res) => {
        this.customTags = res.data
      })
    },
    flushList() {
      this.show.userDetail = false
      this.show.tag = false
      this.getList()
    },
    onSearch() {
      this.getList()
    },
    handleUpdate(row) {
      this.currentRow = row
      this.show.userDetail = true
    },
    addTag(row) {
      this.currentRow = row
      this.show.tag = true
    },
    handleDelete(row) {
      this.$confirm('是否确认删除?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return dataManageApi.del(row.id)
        })
        .then(() => {
          this.getList()
          this.$message({ showClose: true, message: '删除成功', type: 'success' })
        })
        .catch(function () {})
    }
  }
}
</script>

<style lang="scss" scoped>
.order-list {
  .el-tag {
    background-color: #e6f7f6;
    border-color: #e6f7f6;
    color: rgb(102, 102, 102);
  }
  .main-area {
    .filter-bar {
      padding: 22px;
      background-color: #fff;
      .btn-box {
        margin-bottom: 12px;
        .com-primary-btn {
          // background-color: $deepPrimaryColor;
        }
      }
    }
  }
}
</style>
