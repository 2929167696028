<template>
  <div class="base-info">
    <div class="base-info-box person-info">
      <h2 class="com-h2-title">患者基础信息</h2>
      <el-form
        class="demo-form-base"
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="80px"
        :disabled="isDisabled"
      >
        <el-form-item label="患者姓名" prop="patientName">
          <el-input v-model="formData.patientName" placeholder="请输入患者姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="patientSex">
          <el-radio-group v-model="formData.patientSex" size="medium">
            <el-radio
              v-for="(item, index) in USER_GENDER"
              :key="index"
              :label="item.value"
              :disabled="item.disabled"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄" prop="patientAge">
          <el-input v-model.number="formData.patientAge" placeholder="请输入年龄"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="base-info-box design-info">
      <h2 class="com-h2-title">设计信息</h2>
      <el-form
        class="demo-form-design"
        ref="elForm2"
        :model="formData"
        :rules="rules2"
        size="medium"
        label-width="80px"
        :disabled="designEditable"
      >
        <diagnosis
          label="患者类型"
          parentValue="0"
          fieldName="diagnosisType"
          :formData="formData"
        ></diagnosis>
        <!-- 患者类型子选项-->
        <diagnosis
          fieldName="diagnosisTypeTwo"
          :border="true"
          :parentValue="formData.diagnosisType"
          :isCheckbox="true"
          :formData="formData"
          inputName="diagnosisTypeTwoDescription"
        ></diagnosis>
        <diagnosis
          label="治疗计划"
          fieldName="treatmentPlane"
          optionsName="planeOptions"
          :parentValue="formData.diagnosisType"
          :formData="formData"
        >
          <diagnosis
            fieldName="treatmentPlaneTwo"
            optionsName="options"
            :isCheckbox="true"
            :border="true"
            :parentValue="formData.treatmentPlane"
            :formData="formData"
          >
            <diagnosis
              fieldName="treatmentPlaneThree"
              optionsName="options"
              :border="true"
              :parentValue="formData.treatmentPlaneTwo"
              :formData="formData"
            >
            </diagnosis>
          </diagnosis>
        </diagnosis>

        <el-form-item label="备注" prop="treatmentPlaneDescription">
          <el-input
            v-model="formData.treatmentPlaneDescription"
            type="textarea"
            placeholder="请输入备注"
            show-word-limit
            :maxlength="100"
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import order from '@/mixins/order.js'
import diagnosis from './diagnosis.vue'
import { USER_GENDER } from '@/utils/constants'
export default {
  name: 'BaseInfo',
  mixins: [order],
  components: {
    diagnosis
  },
  data() {
    return {
      USER_GENDER,
      rules: {
        patientName: [
          {
            required: true,
            message: '请输入患者姓名',
            trigger: 'blur'
          }
        ],
        patientSex: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'change'
          }
        ],
        patientAge: [
          {
            required: true,
            message: '请输入年龄',
            trigger: 'blur'
          }
        ]
      },
      rules2: {
        // diagnosisType: [
        //   {
        //     required: true,
        //     message: '请选择患者类型',
        //     trigger: 'blur'
        //   }
        // ],
        // diagnosisTypeTwo: [
        //   {
        //     required: true,
        //     message: '请选择患者类型',
        //     trigger: 'blur'
        //   }
        // ],
        // treatmentPlane: [
        //   {
        //     required: true,
        //     message: '请选择诊断计划',
        //     trigger: 'blur'
        //   }
        // ],
        // treatmentPlaneTwo: [
        //   {
        //     required: true,
        //     message: '请选择诊断计划',
        //     trigger: 'blur'
        //   }
        // ],
        // treatmentPlaneThree: [
        //   {
        //     required: true,
        //     message: '请选择诊断计划',
        //     trigger: 'blur'
        //   }
        // ]
        
      }
    }
  },
  inject: {
    showUpload: { default: {} }
  },
  computed: {},
  watch: {
    'formData.diagnosisType': {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal == '牙颌面畸形正颌外科') {
          this.showUpload.ms = true
        } else {
          this.showUpload.ms = false
        }
      }
    }
  },
  created() {},
  mounted() {
    // 如果
    if (this.formData.diagnosisType == '牙颌面畸形正颌外科') {
      this.showUpload.ms = true
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
</style>
