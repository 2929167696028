<!--
 * @Author: Ellie
 * @Date: 2022-02-10
 * @LastEditTime: 2022-02-11
 * @LastEditors: Please set LastEditors
 * @Description: 订单管理
 * @FilePath: src/views/order/index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'OrderView',
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
</style>
