<template>
  <!-- 缓存组件内容 -->
  <keep-alive>
    <component
      :ref="componentArr[isActive]"
      :is="componentArr[isActive]"
      :formData="formData"
      :orderType="4"
    ></component>
  </keep-alive>
</template>

<script>
import BaseInfo from './BaseInfo.vue'
import QuestionContentFuzhu from '@/views/questionnaire/QuestionContentFuzhu.vue'
import OrderDetail from '../../mixins/order-detail.js'
import ReportInfo from '../video-info/ReportInfo.vue'
import { createChildren, createChildOption } from '../order-form'
export default {
  name: 'FunzhuOrder',
  mixins: [OrderDetail],
  components: {
    BaseInfo,
    QuestionContentFuzhu,
    ReportInfo
  },
  data() {
    return {
      componentArr: ['BaseInfo', 'QuestionContentFuzhu', 'ReportInfo'],
      isRequired: true,
      formData: {
        // 基础信息
        patientName: '',
        patientSex: '',
        patientAge: '',
        patientPhone: '',
        jawPadType: '',
        designRequirement: '',
        jawPadTypeOther: '',
        // 问诊信息
        questionReport: {},
        // 快递下单
        dentalSendType: '',
        dentalSendAddress: '',
        dentalSendNumber: '',
        isCanUpload: true,
        // 影像资料及报告
        imageReport: createChildOption(
          {
            mode: '面像与口内照片',
            children: createChildren(
              '正面部正常影像|正面部拉钩像|左侧后牙咬合影像|全牙列正面咬合影像|右侧后牙咬合影像',
              true
            )
          },
          {
            mode: '头颅片、面扫',
            children: createChildren('头颅侧位片|面扫', true)
          }
        ),
        fileReport: createChildOption(
          {
            mode: '上/下颌牙列数据',
            isSend: true,
            children: createChildOption(
              {
                mode: '上颌牙列数据',
                requireSendNumber: true,
                limit: ['stl']
              },
              {
                mode: '下颌牙列数据',
                requireSendNumber: true,
                limit: ['stl']
              }
            )
          },
          {
            mode: '运动轨迹数据',
            children: createChildOption({
              mode: '运动轨迹数据',
              limit: ['zebdb']
            })
          },
          {
            mode: 'CT数据',
            children: createChildOption({
              mode: 'CT数据',
              limit: ['rar', 'tiff', 'dcm']
            })
          },
          {
            mode: '其他文件',
            children: createChildOption({
              mode: '其他文件',
              limit: ['rar', 'tiff', 'stl', 'dcm', 'png', 'jpg', 'pdf']
            })
          }
        ),
        dataReport: createChildOption({
          mode: '报告',
          children: createChildOption(
            {
              mode: '辅助分析报告',
              limit: ['pdf']
            },
            {
              mode: '颌垫方案',
              limit: ['pdf']
            }
          )
        })
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
