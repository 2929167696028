<!--
 * @Author: Ellie
 * @Date: 2022-02-10
 * @LastEditTime: 2022-02-11
 * @LastEditors: Please set LastEditors
 * @Description: 订单管理 > 创建订单
 * @FilePath: src/views/order/order-create/CreateOrder.vue
-->
<template>
  <div class="create-order">
    <div class="com-page-nav"><span>订单管理 &gt;</span> 创建订单</div>
    <div class="flow-box" v-if="!showForm">
      <div class="flow-area" v-if="openService && openService.length > 0">
        <h2 class="flow-area-title">已开通服务类型</h2>
        <ul class="flow-area-list">
          <li v-for="(item, i) in openService" :key="i" @click="createForm(item)">
            <create-photo :detail="item" status="open"></create-photo>
            <p class="text-desc">{{ item.description }}</p>
          </li>
        </ul>
      </div>
      <div class="flow-area" v-if="closeService && closeService.length > 0">
        <h2 class="flow-area-title">待开通服务类型</h2>
        <ul class="flow-area-list">
          <li v-for="(item, i) in closeService" :key="i">
            <create-photo :detail="item" status="close"></create-photo>
            <p class="text-desc">{{ item.description }}</p>
          </li>
        </ul>
      </div>
    </div>
    <order-form @cancel="handleCancel" :activeComp="activeComp" v-else></order-form>
  </div>
</template>

<script>
import OrderForm from '../components/OrderForm.vue'
import CreatePhoto from './CreatePhoto.vue'
import { createIndex } from '@/api/order.js'
import { loginConfirm } from '@/api/login.js'

export default {
  name: 'CreateOrder',
  components: {
    CreatePhoto,
    OrderForm
  },
  data() {
    return {
      openService: [],
      closeService: [],
      showForm: false,
      activeComp: 1
    }
  },
  mounted() {
    this.user = this.getUser()
    this.getCreateOrderList()
    this.showConfirmLogin()
  },
  methods: {
    // 获取不同表单列表
    getCreateOrderList() {
      createIndex().then((res) => {
        const { openService, closeService } = res.data
        this.openService = openService
        this.closeService = closeService
      })
    },
    // 创建表单
    createForm(item) {
      switch (item.name) {
        case '外科类订单':
          this.activeComp = 1
          break
        case '阻鼾器类订单':
          this.activeComp = 2
          break
        case '颌垫类订单':
          this.activeComp = 3
          break
        case '辅助分析类订单':
          this.activeComp = 4
          break
        default:
          this.activeComp = 1
          break
      }
      this.showForm = true
    },
    // 取消创建表单
    handleCancel() {
      this.showForm = false
    },
    getUser() {
      return JSON.parse(localStorage.getItem('userInfo'))
    },
    // 显示修改初始密码
    showConfirmLogin() {
      if (!this.user.confirmLogin) {
        this.$confirm('为了账户安全，请您修改初始密码?', '提示', {
          confirmButtonText: '不在提示',
          cancelButtonText: '知道了',
          cancelButtonClass: 'btn-custom-cancel',
          type: 'warning'
        })
          .then(() => {
            this.user.confirmLogin = true
            localStorage.setItem('userInfo', JSON.stringify(this.user))
            loginConfirm({})
              .then((res) => {})
              .finally(() => {})
          })
          .catch(() => {
            this.user.confirmLogin = true
            localStorage.setItem('userInfo', JSON.stringify(this.user))
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-order {
  .flow-area {
    padding: 20px 24px;
    background-color: #fff;
    & + .flow-area {
      margin-top: 20px;
    }
    &-title {
      margin-bottom: 24px;
      font-size: 15px;
      font-weight: 500;
      color: $fontDeepColor;
      line-height: 20px;
    }
    &-list {
      margin-top: -16px;
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 16px 20px 0 0;
        width: 340px;
        .text-desc {
          padding: 0 15px;
          color: $fontPrimaryColor;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>