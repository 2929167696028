<template>
  <el-dialog
    title="订单标签"
    :visible.sync="showDialog.dialogVisible"
    width="800px"
    append-to-body
    class="track-tag"
  >
    <el-form ref="form" :model="form" label-width="150px">
      <el-tag
        :key="tag.id"
        v-for="tag in tagList"
        :class="form.tagsId.indexOf(tag.id) > -1 ? 'selected' : ''"
        @click="clickTag(tag)"
      >
        {{ tag.tagName }}
      </el-tag>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">提 交</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as tagsApi from '@/api/tags.js'
import * as orderApi from '@/api/order.js'
export default {
  name: 'OrderTags',
  components: {},
  props: {
    showDialog: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      form: { id: undefined, tagsId: [] },
      tagList: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getTagList()
      this.detail(this.showDialog.id)
    },
    // 获取标签列表
    getTagList() {
      tagsApi.getTagsList({ type: 1 }).then((res) => {
        this.tagList = res.data
      })
    },
    detail(id) {
      if (id) {
        this.form.id = id
        orderApi.orderTag({ id }).then((res) => {
          const { data } = res
          if (data && data.length) {
            this.form.tagsId = data.map((tag) => Number.parseInt(tag))
          } else {
            this.form.tagsId = []
          }
        })
      }
    },
    clickTag(tag) {
      const index = this.form.tagsId.indexOf(tag.id)
      if (index > -1) {
        this.form.tagsId.splice(index, 1)
      } else {
        this.form.tagsId.push(tag.id)
      }
    },
    submitForm() {
      if (this.form.id) {
        orderApi.editTag(this.form).then((res) => {
          this.$message({ showClose: true, message: '保存成功', type: 'success' })
          this.cancel()
          this.$emit('flushList')
        })
      }
    },
    cancel() {
      this.showDialog.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.track-tag {
  .selected {
    background-color: #e6f7f6 !important;
    border-color: #e6f7f6 !important;
    color: rgb(102, 102, 102) !important;
  }
  .el-tag {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    color: #999;
    margin: 0px 10px 10px 0px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>
