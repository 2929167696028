import request from '@/utils/request'

// 统计列表
export function business(data) {
  return request({
    url: '/web/analysis/business',
    method: 'post',
    data
  })
}
// 医生
export function doctor(data) {
  return request({
    url: '/web/analysis/doctor',
    method: 'post',
    data
  })
}
// 工程师
export function engineer(data) {
  return request({
    url: '/web/analysis/engineer',
    method: 'post',
    data
  })
}