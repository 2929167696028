import request from '@/utils/request'

// 菜单列表
export function list(data) {
  return request({
    url: '/menu/list',
    method: 'post',
    data
  })
}
// 所有菜单
export function queryAll(data) {
  return request({
    url: '/menu/queryAll',
    method: 'post',
    data
  })
}

// 查询菜单下拉树结构
export function treeselect(data) {
  return request({
    url: '/menu/treeselect',
    method: 'post',
    data
  })
}

// 根据角色ID查询菜单下拉树结构
export function roleMenuTreeselect(id) {
  return request({
    url: '/menu/roleMenuTreeselect',
    method: 'post',
    data: { id }
  })
}

// 保存角色菜单
export function saveRoleMenus(data) {
  return request({
    url: '/menu/saveRoleMenus',
    method: 'post',
    data
  })
}

// 菜单详细
export function detail(id) {
  return request({
    url: '/menu/detail',
    method: 'post',
    data: { id }
  })
}

// 保存菜单
export function save(data) {
  return request({
    url: '/menu/save',
    method: 'post',
    data: data
  })
}
// 删除
export function del(id) {
  return request({
    url: '/menu/del',
    method: 'post',
    data: { id }
  })
}
