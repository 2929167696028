
import { submitForm } from '@/utils/index.js'
export default {
  props: {
    formData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    isDisabled() {
      return this.formData && this.formData.isCanEdit === 0
    },
    designEditable() {
      return this.formData && this.formData.designEditable === 0
    }
  },
  methods: {
    submitForm() {
      const result = submitForm(this.$refs['elForm'])
      if (result && this.$refs['elForm2']) {
        return submitForm(this.$refs['elForm2'])
      }
      return result
    }
  }
}
