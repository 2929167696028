<template>
  <div class="forget-password">
    <h2 class="h2-title">忘记密码</h2>
    <el-steps :active="activeNum" align-center>
      <el-step title="填写账号"></el-step>
      <el-step title="设置密码"></el-step>
      <el-step title="设置完成"></el-step>
    </el-steps>
    <!-- 组件展示 -->
    <component  :ref="activeTabs[activeNum - 1]" :is="activeTabs[activeNum - 1]"></component>
    <div class="footer-btn-box">
      <el-button @click="backLogin" class="com-plain-btn">返回登录页</el-button>
      <el-button v-if="activeNum === 1" @click="nextStep" type="primary">下一步</el-button>
      <el-button v-if="activeNum === 2" @click="confirmPwd" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
import FillPhoneNumber from './components/forget/FillPhoneNumber.vue'
import FillPassword from './components/forget/FillPassword.vue'
import ModifySuccess from './components/forget/ModifySuccess.vue'
export default {
  name: 'ForgetPassword',
  components: {
    FillPhoneNumber,
    FillPassword,
    ModifySuccess
  },
  data() {
    return {
      activeNum: 1,
      activeTabs: ['FillPhoneNumber', 'FillPassword', 'ModifySuccess'],
      updatePwdCode: ''
    }
  },
  mounted() {},
  methods: {
    backLogin() {
      this.$router.push({
        path: '/login'
      })
    },
    nextStep() {
      this.$refs['FillPhoneNumber'].validCode((data) => {
        this.updatePwdCode = data
        this.activeNum = 2
      })
    },
    confirmPwd() {
      this.$refs['FillPassword'].updatePwd(this.updatePwdCode, (valid) => {
        this.activeNum = 3
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.forget-password {
  position: static;
  z-index: 1;
  padding: 60px 80px 20px;
  width: 660px;
  height: 67%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  .h2-title {
    margin-bottom: 24px;
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    text-align: center;
  }
  ::v-deep .el-steps {
    margin-bottom: 40px;
    .el-step__title {
      font-size: 16px;
    }
    .el-step__head {
      .el-step__icon {
        color: #fff;
        width: 28px;
        height: 28px;
        border: none;
        background: #cccccc;
      }
      .el-step__line {
        top: 50%;
        margin-top: -1px;
        background: #cccccc;
      }
      &.is-finish {
        .el-step__icon {
          border: none;
          background-color: $primaryColor;
        }
      }

      &.is-finish {
        .el-step__line::before {
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 2px;
          background-color: $primaryColor;
          content: '';
          z-index: 1;
        }
      }
    }
    .el-step__main {
      .el-step__title {
        margin-top: 10px;
        font-weight: 500;
        line-height: 22px;
        &.is-process {
          color: #999;
        }
        &.is-finish {
          color: $deepPrimaryColor;
        }
      }
    }
  }
  .footer-btn-box {
    display: flex;
    justify-content: space-between;
    .el-button {
      margin-top: 40px;
      height: 45px;
      flex: 1;
    }
  }
}
::v-deep .el-input {
  .el-input__inner {
    height: 45px;
    line-height: 45px;
    border: none;
    background: #f0f0f0;
  }
  &.phone {
    .el-input__inner {
      padding-left: 35px;
      background: #f0f0f0 url('../../assets/img/login/icon_phone.png') no-repeat 10px center;
    }
  }
  &.code {
    .el-input__inner {
      padding-left: 35px;
      background: #f0f0f0 url('../../assets/img/login/icon_code.png') no-repeat 10px center;
    }
  }
}
</style>

