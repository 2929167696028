import request from '@/utils/request'

// 上传文件
export function createIndex(data) {
  return request({
    url: '/upload',
    method: 'post',
    data
  })
}

// 文件列表
export function getFileList(data) {
  return request({
    url: '/file/fileList',
    method: 'post',
    data
  })
}

// 机构列表
export function getCompanyList(data) {
  return request({
    url: '/web/company/list',
    method: 'post',
    data
  })
}

// 人员列表(立即分配选择工程师+开始任务选择业务员)
export function getUserList(data) {
  return request({
    url: '/user/list',
    method: 'post',
    data
  })
}
