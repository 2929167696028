<template>
  <div>
    <div class="com-page-nav">{{ $route.meta.title }}</div>
  </div>
</template>

<script>
export default {
  name: 'ModelView',
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
</style>
