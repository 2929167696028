<template>
  <div class="fuzhu-base-info">
    <div class="base-info-box person-info">
      <h2 class="com-h2-title">患者基础信息</h2>
      <el-form class="demo-form-base" ref="elForm" :model="formData" :rules="rules" size="medium" label-width="80px" :disabled="isDisabled" :inline="true">
        <el-form-item label="患者姓名" prop="patientName">
          <el-input v-model="formData.patientName" placeholder="请输入患者姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="patientSex">
          <el-radio-group v-model="formData.patientSex" size="medium">
            <el-radio v-for="(item, index) in patientGenderOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄" prop="patientAge">
          <el-input v-model.number="formData.patientAge" placeholder="请输入年龄"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="person-info">
      <h2 class="com-h2-title" style="margin-left: 20px">影像数据</h2>
      <video-info :orderType="orderType" :formData="formData"></video-info>
    </div>
  </div>
</template>
<script>
import VideoInfo from '../video-info/VideoInfo.vue'
import { submitForm } from '@/utils/index.js'
import order from '@/mixins/order.js'
export default {
  name: 'BaseInfo',
  mixins: [order],
  props: {
    orderType: {
      type: Number,
      default: null
    }
  },
  components: {
    VideoInfo
  },
  data() {
    return {
      rules: {
        patientName: [
          {
            required: true,
            message: '请输入患者姓名',
            trigger: 'blur'
          }
        ],
        patientSex: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'change'
          }
        ],
        patientAge: [
          {
            required: true,
            message: '请输入年龄',
            trigger: 'blur'
          }
        ]
        // patientPhone: [
        //   {
        //     required: true,
        //     message: '请输入手机号',
        //     trigger: 'blur'
        //   },
        //   {
        //     pattern: /^1(1|2|3|4|5|6|7|8|9|0)\d{9}$/,
        //     message: '手机号格式错误',
        //     trigger: 'blur'
        //   }
        // ]
      },
      patientGenderOptions: [
        {
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 2
        }
      ],
      jawPadTypeOptions: [
        {
          label: '放松颌垫',
          value: '放松颌垫'
        },
        {
          label: '稳定颌垫',
          value: '稳定颌垫'
        },
        {
          label: '再定位颌垫',
          value: '再定位颌垫'
        },
        {
          label: '其他',
          value: '其他'
        }
      ]
    }
  },
  computed: {},
  watch: {
    'formData.jawPadType'(val) {
      if (val !== '其他') {
        this.formData.jawPadTypeOther = ''
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    submitForm(isNoShowMsg) {
      // 校验表单、校验图片、校验文件
      if (
        submitForm(this.$refs['elForm']) &&
        this.validData(this.formData.imageReport, isNoShowMsg) &&
        this.validData(this.formData.fileReport, isNoShowMsg)
      ) {
        return true
      }
      return false
    },
    validData(data, isNoShowMsg) {
      const noValids = ['面扫', '其他文件']
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        if (element.children) {
          const validFail = element.children.find((element) => {
            // 如果已经上传了，快递下单，不需要继续校验，如果未上传，需要校验
            if (element.requireSendNumber) {
              // 校验快递下单的快递地址、发货单号 dentalSendNumber dentalSendAddress
              if (this.formData.dentalSendNumber && this.formData.dentalSendAddress) {
                return false
              }
            }
            return (
              noValids.indexOf(element.mode) < 0 &&
              (!element.files || element.files.length == 0 || !element.files[0].path)
            )
          })
          if (validFail) {
            if (isNoShowMsg) {
              this.$message({
                type: 'error',
                message: '请上传' + validFail.mode
              })
            }
            return false
          }
        }
      }
      return true
    }

    // resetForm() {
    //   this.$refs['elForm'].resetFields()
    // }
  }
}
</script>
<style lang="scss" scoped>
.fuzhu-base-info {
  .person-info {
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
