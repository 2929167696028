<template>
  <div class="operate-bar operate-bar-detail">
    <div class="left-info-box" style="margin-top: -20px">
      <div class="text-box">
        <span class="font-text">机构：{{ formData.company || '--' }}</span>
        <span class="font-text" v-if="formData.salesman">业务员：{{ formData.salesman }}</span>
        <span class="font-text" v-if="formData.isShowSalesman"
          >业务员：
          <el-select v-model="params.salesmanId" placeholder="请选择">
            <el-option
              v-for="item in list"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </span>
      </div>
      <!-- 服务金额编辑 -->
      <div class="text-box" v-if="formData.isShowEditAmount">
        <span class="font-text"
          ><i>*</i><span v-if="activeComp === 4">辅助分析</span>服务金额：
          <el-input type="number" v-model.number="params.amountService"></el-input>元
        </span>
        <span class="font-text"
          >优惠金额： <el-input type="number" v-model.number="params.amountDiscount"></el-input>元
        </span>
        <span class="font-text"
          >优惠渠道码：
          <el-input v-model="params.discountCode"></el-input>
        </span>
        <span class="font-text font-text-red">待支付金额：¥{{ amountUnpaid }}</span>
      </div>
      <!-- 服务金额展示 -->
      <div class="text-box" v-if="!formData.isShowEditAmount && formData.isShowAmount">
        <span class="font-text"
          ><i>*</i><span v-if="activeComp === 4">辅助分析</span>服务金额：¥{{
            formData.amountService
          }}</span
        >
        <span class="font-text">优惠金额：¥{{ formData.amountDiscount }}</span>
        <span class="font-text font-text-red">{{ payName }}：¥{{ formData.amountPaid }}</span>
      </div>
      <!-- 颌垫服务金额编辑 -->
      <div class="text-box" v-if="formData.isShowEditJawAmount">
        <span class="font-text"
          ><i>*</i>颌垫服务金额：
          <el-input type="number" v-model.number="params.jawAmount"></el-input>元
        </span>

        <span class="font-text"
          >优惠金额：
          <el-input type="number" v-model.number="params.jawAmountDiscount"></el-input>元
        </span>
        <span class="font-text"
          >优惠渠道码：
          <el-input v-model="params.jawDiscountCode"></el-input>
        </span>
        <span class="font-text font-text-red">待支付金额：¥{{ jawUnpaid }}</span>
      </div>
      <!-- 颌垫服务金额展示 -->
      <div class="text-box" v-if="!formData.isShowEditJawAmount && formData.isShowJawAmount">
        <span class="font-text"><i>*</i>颌垫服务金额：¥{{ formData.jawAmount }}</span>
        <span class="font-text">优惠金额：¥{{ formData.jawAmountDiscount }}</span>
        <span class="font-text font-text-red">{{ payName }}：¥{{ formData.jawAmountPaid }}</span>
      </div>
      <div class="text-box" v-if="formData.isShowSendMethod">
        <span class="font-text">寄送方式：{{ formData.isShowSendMethod }}</span>
        <span class="font-text" v-if="formData.isShowSendNumber"
          >快递单号：{{ formData.isShowSendNumber }}</span
        >
        <span
          class="font-text font-text-link"
          @click="viewDeliveryStatus"
          v-if="formData.isShowSendMethod !== '闪送' && formData.isShowSendMethod !== '自送'"
          >查看物流状态</span
        >
      </div>
      <div class="reason-box" v-if="formData.refuseReason">
        <p class="red-text">审核过单:已拒绝</p>
        <p class="reason">拒绝原因：{{ formData.refuseReason }}</p>
      </div>
    </div>
    <!-- 物品寄送 -->
    <send-goods-dialog
      v-if="showGoodsDialog.dialogVisible"
      :show="showGoodsDialog"
      @confirmSend="confirmSend"
    ></send-goods-dialog>
    <!-- 立即分配 -->
    <order-assign
      v-if="showAssignDialog.dialogVisible"
      :showDialog="showAssignDialog"
      @submitEditForm="submitEditForm"
    ></order-assign>
    <!-- 拒绝 -->
    <el-dialog
      custom-class="refuse-dialog"
      title="提示"
      width="450px"
      :visible.sync="showRefuseDialog"
    >
      <p class="text">拒绝原因</p>
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请填写拒绝原因"
        v-model="params.reason"
        minlength="1"
        maxlength="100"
        show-word-limit
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleRefuse" :disabled="params.reason === ''"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ListUtils from '@/mixins/list-utils'
import { STATUS, ORDER_TYPE } from '@/utils/constants.js'
import { getUserList } from '@/api/common.js'
import { orderOperate } from '@/api/order.js'
import SendGoodsDialog from '../components/send-goods/SendDialog.vue'
import OrderAssign from '../components/order-assign/OrderAssign.vue'
import { validReportData } from '../components/order-form'
export default {
  name: 'OperateBarDetail',
  mixins: [ListUtils],
  components: {
    SendGoodsDialog,
    OrderAssign
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    orderTypeName: {
      type: String,
      default: ''
    },
    activeComp: {
      type: Number,
      default: 1
    },
    // 蛐蛐添加
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      STATUS,
      ORDER_TYPE,
      dialogVisible: false,
      multipleSelection: [],
      // 业务员
      value: '',
      userName: '',
      tips: '',
      showButton: 0,
      // params: {
      //   salesmanId: '',
      //   engineerId: '',
      //   amountService: null, // 服务金额
      //   amountDiscount: null,
      //   discountCode: '',
      //   jawAmount: null, // 颌垫服务金额
      //   jawAmountDiscount: null,
      //   jawDiscountCode: '',
      //   res: 1,
      //   reason: ''
      // },
      showGoodsDialog: {
        dialogVisible: false,
        id: this.formData.orderId
      },
      showAssignDialog: {
        dialogVisible: false,
        orderId: this.formData.orderId,
        operateMode: this.formData.status
      },
      showRefuseDialog: false
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    },
    statusInfo() {
      return Number(this.formData.status)
    },
    // 待支付金额
    amountUnpaid() {
      const { amountService, amountDiscount } = this.params
      if (amountService < amountDiscount) {
        this.handleMoney('normal')
        return 0
      }
      return amountService - amountDiscount
    },
    jawUnpaid() {
      const { jawAmount, jawAmountDiscount } = this.params

      if (jawAmount < jawAmountDiscount) {
        this.handleMoney('jaw')
        return 0
      }
      return jawAmount - jawAmountDiscount
    },
    payName() {
      return this.formData.payStatus === 1 ? '已支付' : '待支付'
    }
  },
  mounted() {
    // 按钮赋值
    this.getBtnText()
    // 参数赋值
    this.setParams()
    // 人员选项赋值
    this.getPersonList('业务员')
  },
  methods: {
    handleMoney(type) {
      if (type === 'normal') {
        this.params.amountDiscount = 0
      } else {
        this.params.jawAmountDiscount = 0
      }
      this.$message.warning('优惠金额不能大于服务金额')
    },
    // 格式化状态值
    typeFormatter(status, arr, filterKey, returnKey) {
      if (!status && status !== 0) {
        return '--'
      }
      const item = arr.find((item) => Number(item[filterKey || 'value']) === Number(status))
      return item[returnKey || 'label']
    },
    // 立即分配
    handleAssignment() {
      this.showAssignDialog.dialogVisible = true
    },
    // 获取人员
    getPersonList(roleName) {
      getUserList({
        roleName,
        userName: this.userName
      }).then((res) => {
        this.list = res.data
      })
    },
    setParams() {
      const {
        salesmanId,
        amountService,
        amountDiscount,
        discountCode,
        jawAmount,
        jawAmountDiscount,
        jawDiscountCode
      } = this.formData
      // 服务金额
      amountService !== null && (this.params.amountService = Number(amountService))
      amountDiscount !== null && (this.params.amountDiscount = Number(amountDiscount))
      discountCode && (this.params.discountCode = discountCode)
      // 颌垫服务金额
      jawAmount !== null && (this.params.jawAmount = Number(jawAmount))
      jawAmountDiscount !== null && (this.params.jawAmountDiscount = Number(jawAmountDiscount))
      jawDiscountCode && (this.params.jawDiscountCode = jawDiscountCode)
      salesmanId !== null && (this.params.salesmanId = salesmanId)
    },
    getBtnText() {
      /**
       *所有单据共用的状态：1 待分配 2 订单待处理 5 审核过单 6 确认方案和价格中 9 物品寄送中  10 物品已签收
       *外科类订单的状态：3 设计手术方案中 4 设计导板中 7 手术模板和导板打印中 8 交付已完成（手术模板和导板打印已完成）
       *阻鼾器类订单的状态：11 制作阻鼾器中 12 交付已完成（方案和价格已确认）
       *颌垫类订单的状态：13 设计颌垫方案中 14 制作颌垫中 15 交付已完成（颌垫制作已完成）
       *辅助分析类订单的状态：16 辅助分析处理中 17 辅助订单交付已完成
       */
      switch (this.statusInfo) {
        case 2:
          this.tips = '确定开始执行任务？'
          this.showButton = this.formData.isCanStartTask
          return '开始任务'
        case 3:
          this.tips = '已与医生确定过方案？'
          this.showButton = this.formData.isShowConfirmPlan
          return '确定方案'
        case 4:
          this.tips = '确定已完成设计，并已上传报告与3D导板？'
          this.showButton = this.formData.isShowFinishDesign
          return '完成设计'
        case 5:
          this.tips = '确定审核通过？'
          this.showButton = this.formData.isShowAgree
          // this.params.res = 1
          return '同意'
        case 6:
          if (this.activeComp === 4 && this.params.needJaw !== 1) {
            this.tips = '确定方案与价格，是否要继续制作颌垫？'
          } else {
            this.tips = '已确认方案与价格？'
          }
          this.showButton = this.formData.isShowConfirm
          return '确认方案和价格'
        case 7:
          this.tips = '确定已完成打印？'
          this.showButton = this.formData.isShowFinishPrint
          return '完成打印'
        case 9:
          this.tips = '确定已签收？'
          this.showButton = this.formData.isShowSign
          return '签收'
        case 11:
          this.tips = '确定已完成制作，并已上传报告？'
          this.showButton = this.formData.isShowFinishHandle
          return '完成制作'
        case 13:
          this.tips = '确定已完成设计？'
          this.showButton = this.formData.isShowFinishPlan
          return '完成设计'
        case 14:
          this.tips = '确定已完成制作，并已上传报告？'
          this.showButton = this.formData.isShowFinishHandle
          return '完成制作'
        case 16:
          this.tips = '已完成方案设计？'
          this.showButton = this.formData.isShowFinishPlan
          return '完成设计'
        default:
          return ''
      }
    },
    // 确认提交
    handleConfirm() {
      if (this.activeComp === 4 && this.statusInfo === 6 && this.formData.needJaw !== 1) {
        // 辅助订单颌垫制作
        this.$confirm(this.tips, '提示', {
          distinguishCancelAndClose: true,
          customClass: 'special-message',
          confirmButtonText: '制作颌垫',
          cancelButtonText: '不制作颌垫',
          type: 'warning'
        })
          .then(() => {
            this.params.needJaw = 1
            this.handleOperate()
          })
          .catch((action) => {
            // 区分取消与关闭
            // [取消]=[不制作颌垫]
            console.log(action)
            if (action === 'cancel') {
              this.params.needJaw = 2
              this.handleOperate()
            }
          })
      } else {
        // 辅助分析金额不能为空
        if (this.formData.isShowEditAmount && !this.params.amountService) {
          let failMsg = '服务金额'
          if (this.activeComp === 4) {
            failMsg = '辅助分析' + failMsg
          }
          this.$message({
            type: 'error',
            message: '未填写' + failMsg
          })
          return
        }
        // 颌垫服务金额不能为空
        if (this.formData.isShowEditJawAmount && !this.params.jawAmount) {
          this.$message({
            type: 'error',
            message: '未填写颌垫服务金额'
          })
          return
        }
        // 校验报告是否上传
        if (!validReportData(this.formData)) {
          return
        }

        // 其他订单操作
        this.$confirm(this.tips, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.handleOperate()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },

    // 拒绝
    openRefuse() {
      this.showRefuseDialog = true
    },
    handleRefuse() {
      this.params.res = 2
      this.handleOperate()
    },
    handleCancel() {
      this.showRefuseDialog = false
      this.params.reason = ''
    },
    // 打开寄送物品
    handleSend() {
      this.showGoodsDialog.dialogVisible = true
      this.showGoodsDialog.orderId = this.formData.orderId
    },
    // 确定寄送物品
    confirmSend(form) {
      this.params = Object.assign(this.params, form)
      this.handleOperate()
    },
    // 查看物流状态
    viewDeliveryStatus() {
      let url = ''
      switch (this.formData.isShowSendMethod) {
        case '顺丰快递':
          url = `https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/${this.formData.isShowSendNumber}`
          break
        case '京东快递':
          url = `https://www.jdl.cn/`
          break
        case '中通快递':
          url = 'https://www.zto.com/express/expressCheck.html'
          break
        default:
          url = ''
          break
      }
      window.open(url)
    },
    // 操作接口
    handleOperate(operate) {
      orderOperate({
        orderId: this.formData.orderId,
        operateMode: this.formData.status,
        ...this.params
      }).then((res) => {
        this.$message.success(res.msg)
        // operate为true不跳转
        if (!operate) {
          this.submitEditForm()
        }
      })
    },
    handleOperateSalesmanId() {
      if (this.params.salesmanId) {
        this.formData.salesmanId = this.params.salesmanId
      }
    },
    saveForm() {
      this.handleOperateSalesmanId()
      // 保存按钮
      this.$emit('submitFormData', 'isEdit', 0)
    },
    // 可编辑触发提交
    submitEditForm() {
      this.handleOperateSalesmanId()
      this.$emit('submitFormData', 'isEdit', 1)
    },
    backList() {
      this.$bus.$emit('backList')
    }
  }
}
</script>

<style lang="scss" scoped>
.operate-bar-detail {
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
  .right-btn-box {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .text-box {
    color: #333333;
    line-height: 20px;
    display: flex;
    .font-text {
      margin-right: 24px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      i {
        color: $--color-danger;
        margin-right: 6px;
      }
      &-bold {
        font-weight: bold;
      }
      &-red {
        color: $--color-danger;
      }
      &-link {
        cursor: pointer;
        color: $primaryColor;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    & + .text-box {
      margin-top: 21px;
    }
  }
  .search-form {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    .el-input {
      margin-right: 8px;
      width: 200px;
    }
  }
  .reason-box {
    margin-top: 8px;
    padding: 8px;
    border: 1px dashed $--color-danger;
    // max-width: 80%;
    .red-text {
      margin-bottom: 5px;
      color: $--color-danger;
    }
  }
}
.font-text-bold {
  background: $middlePrimaryColor;
  color: $deepPrimaryColor;
  padding: 5px 10px;
  border-radius: 4px;
}
</style>
<style lang="scss">
.refuse-dialog {
  .el-dialog__body {
    padding: 20px 20px 10px;
    .text {
      margin-bottom: 10px;
    }
  }
}
.special-message {
  .el-message-box__btns {
    .el-button {
      border: none;
      color: #fff;
      background-color: #00aea5;
    }
    .el-button--primary {
      background-color: $orangeColor;
    }
  }
}
</style>
