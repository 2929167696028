<template>
  <el-dialog title="3D查看" :visible.sync="showDialog.dialogVisible" width="1200px">
    <template slot="title">
      <div class="title-box">
        <span>3D查看</span>
        <div class="title-box" style="width: 470px; margin-top: -5px;">
          <el-select v-model="currentStlUrl" style="width: 400px; margin-top: 0px;">
            <el-option v-for="item in modelList" :key="item.name" :label="item.name" :value="item.path"></el-option>
          </el-select>
          <el-button class="btn" type="text" icon="el-icon-share" @click="shareClick">分享</el-button>
        </div>
      </div>
    </template>
    <model-stl id="water" :lights="lights" :src="currentStlUrl" @onLoad="onLoad"></model-stl>
  </el-dialog>
</template>

<script>
import watermark from '@/utils/watermark'
import { copyUrl } from '@/utils/index.js'
import { ModelStl } from 'vue-3d-model'
export default {
  name: 'OrderModelStl',
  props: {
    showDialog: {
      type: Object,
      default: () => {
        return {
          dialogVisible: false,
          fileUrl: '',
          url3DList: [],
          fileUrlList: []
        }
      }
    }
  },
  components: {
    ModelStl
  },
  data() {
    return {
      loading: true,
      lights: [
        {
          type: 'HemisphereLight',
          position: { x: 0, y: 1, z: 0 },
          skyColor: 0xffffff,
          groundColor: 0x000000,
          intensity: 0.2
        },
        {
          type: 'HemisphereLight',
          position: { x: 1, y: 1, z: 1 },
          skyColor: 0x888888,
          groundColor: 0xffffff,
          intensity: 0.8
        }
      ],
      modelList: [],
      currentStlUrl: ''
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // let content = '为开数字科技'
      // if (this.userInfo && this.userInfo.userName) {
      //   content = this.userInfo.userName
      // }
      // const date = new Date().Format('MMdd')
      const date = ''
      let content = '为开数字科技'
      watermark.set(`${content} ${date}`)

      console.log('this.showDialog == ', this.showDialog)

      var modelList = this.showDialog.fileUrlList
      this.modelList = modelList
      if (modelList && modelList.length > 0) {
        this.currentStlUrl = modelList[0].path
      } else {
        this.currentStlUrl = this.showDialog.fileUrl
      }
    },

    onLoad() {
      this.loading = false
    },
    onError() {
      this.loading = false
      this.$message.error('加载失败，请确认上传的3D文件是否正常')
    },
    // 分享
    shareClick() {
      var BASE_URL = 'https://wk.zebris.com.cn'
      var h5url = BASE_URL + '/h5/#/3d'
      var url3d = this.showDialog.fileUrl
      var url = h5url + '?url3d=' + encodeURIComponent(url3d)
      copyUrl(url)
      this.$message.success('已复制3D地址')
    }
  }
}
</script>

<style lang="scss" scoped>
.title-box {
  width: 1120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 17px;
  .btn {
    margin-top: 0px;
  }
}
</style>
