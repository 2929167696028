<template>
  <div class="base-info">
    <div class="base-info-box person-info">
      <h2 class="com-h2-title">患者基础信息</h2>
      <el-form class="demo-form-base" ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px" :disabled="isDisabled">
        <el-form-item label="患者姓名" prop="patientName">
          <el-input v-model="formData.patientName" placeholder="请输入患者姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="patientSex">
          <el-radio-group v-model="formData.patientSex" size="medium">
            <el-radio v-for="(item, index) in patientGenderOptions" :key="index" :label="item.value"
              :disabled="item.disabled">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄" prop="patientAge">
          <el-input v-model.number="formData.patientAge" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="patientPhone">
          <el-input v-model="formData.patientPhone" placeholder="请输入手机号" :maxlength="11">
          </el-input>
        </el-form-item>
        <el-form-item label="身高 (m)" prop="patientHeight">
          <el-input v-model="formData.patientHeight" placeholder="请输入身高" @blur="calculateBmi()">
          </el-input>
        </el-form-item>
        <el-form-item label="体重 (kg)" prop="patientWeight">
          <el-input v-model="formData.patientWeight" placeholder="请输入体重" @blur="calculateBmi()">
          </el-input>
        </el-form-item>
        <el-form-item label="BMI" prop="patientBmi">
          <el-input v-model="formData.patientBmi" disabled placeholder="BMI由身高体重计算得出">
          </el-input>
        </el-form-item>
        <el-form-item label="颈围 (cm)" prop="patientNeck">
          <el-input v-model="formData.patientNeck" placeholder="请输入颈围">
          </el-input>
        </el-form-item>

      </el-form>
    </div>
    <div class="base-info-box design-info">
      <h2 class="com-h2-title">设计信息</h2>
      <el-form class="demo-form-design" ref="elForm2" :model="formData" size="medium" label-width="80px" :disabled="isDisabled">
        <el-form-item label="设计思路" prop="designMind">
          <el-input
            v-model="formData.designMind"
            type="textarea"
            placeholder="请输入设计思路"
            show-word-limit
            :maxlength="100"
            :autosize="{ minRows: 4, maxRows: 4 }"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import order from '@/mixins/order.js'
export default {
  name: 'BaseInfo',
  mixins: [order],
  data() {
    return {
      rules: {
        patientName: [{
          required: true,
          message: '请输入患者姓名',
          trigger: 'blur'
        }],
        patientSex: [{
          required: true,
          message: '性别不能为空',
          trigger: 'change'
        }],
        patientAge: [{
          required: true,
          message: '请输入年龄',
          trigger: 'blur'
        }],
        patientPhone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: /^1(|1|2|3|4|5|6|7|8|9|0)\d{9}$/,
          message: '手机号格式错误',
          trigger: 'blur'
        }],
        patientHeight: [{
          required: true,
          message: '请输入身高',
          trigger: 'blur'
        }],
        patientWeight: [{
          required: true,
          message: '请输入体重',
          trigger: 'blur'
        }],
        patientBmi: [{
          required: true,
          message: '请输入BMI',
          trigger: 'blur'
        }],
        patientNeck: [{
          required: true,
          message: '请输入颈围',
          trigger: 'blur'
        }]
      },
      patientGenderOptions: [{
        "label": "男",
        "value": 1
      }, {
        "label": "女",
        "value": 2
      }]
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    calculateBmi: function () {
      var height = this.formData.patientHeight;
      var weight = this.formData.patientWeight;
      if (height > 0 && weight > 0) {
        this.formData.patientBmi =  (weight / Math.pow(height, 2)).toFixed(1);
      } else {
        this.formData.patientBmi = '';
      }
    }
    // submitForm() {
    //   this.$refs['elForm'].validate(valid => {
    //     if (!valid) return
    //     // TODO 提交表单
    //   })
    // },
    // resetForm() {
    //   this.$refs['elForm'].resetFields()
    // },
  }
}

</script>
<style lang="scss" scoped>

</style>
