<template>
  <el-dialog title="操作记录" :visible.sync="showDialog.dialogVisible" width="1000px">
    <el-table :data="list" style="width: 100%" @selection-change="handleSelectionChange" v-loading="loading">
      <el-table-column label="序号" type="index" width="60"></el-table-column>
      <el-table-column prop="createdAt" label="变更时间"></el-table-column>
      <el-table-column prop="userName" label="变更人"></el-table-column>
      <el-table-column prop="beforeStatus" label="变更前状态" :formatter="(row) => typeFormatter(row, 'beforeStatus', STATUS)"></el-table-column>
      <el-table-column prop="afterStatus" label="变更后状态" :formatter="(row) => typeFormatter(row, 'afterStatus', STATUS)"></el-table-column>
      <el-table-column prop="reason" label="其他"></el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.current"
      :limit.sync="listQuery.size"
      @pagination="getList"
    />
  </el-dialog>
</template>

<script>
import { getActionLog } from '@/api/order.js'
import ListUtils from '@/mixins/list-utils'
import { STATUS } from '@/utils/constants.js'
export default {
  name: 'OrderOptRecords',
  mixins: [ListUtils],
  props: {
    showDialog: {
      type: Object,
      default: () => {
        return {
          dialogVisible: false,
          id: ''
        }
      }
    }
  },
  data() {
    return {
      STATUS,
      list: [
        {
          id: '111', // id
          name: 'xxx数据.stl', // 文件名
          createdAt: '2022-12-11 11:11:11' // 上传时间
        }
      ],
      multipleSelection: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 格式化状态值
    typeFormatter(row, prop, arr, filterKey, returnKey) {
      if (!row[prop] && row[prop] !== 0) {
        return ''
      }
      const item = arr.find((item) => Number(item[filterKey || 'value']) === Number(row[prop]))
      return item[returnKey || 'label']
    },
    getList() {
      this.loading = true
      getActionLog({ ...this.listQuery, id: this.showDialog.id }).then((res) => {
        const { rows, total } = res.data
        this.list = rows
        this.total = total
      }).finally(() => {
        this.loading = false
      })
    },
    // 选择文件
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-button {
    margin-bottom: 12px;
  }
}
</style>
