<template>
  <el-button type="primary" @click="handleExport" :loading="loading">导出</el-button>
</template>

<script>
import service from '@/utils/down.js'
export default {
  name: 'ExportButton',
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    url: {
      type: String,
      default: '',
      required: true
    },
    fileName: {
      type: String,
      default: '列表导出',
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handleExport() {
      this.loading = true
      service({
        url: this.url,
        method: 'get',
        responseType: 'blob',
        params: this.params
      }).then(res => {
        if (!res) {
          this.$message.error('文件下载失败')
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(
            new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' }),
            this.fileName + '.xls'
          )
        } else {
          let url = window.URL.createObjectURL(
            new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' })
          )
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', this.fileName + '.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) // 下载完成移除元素
          window.URL.revokeObjectURL(url) // 释放掉blob对象
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
