

import icon_tab01 from '@/assets/img/order/icon_tab01.png'
import icon_tab02 from '@/assets/img/order/icon_tab02.png'
import icon_tab03 from '@/assets/img/order/icon_tab03.png'
import icon_tab04 from '@/assets/img/order/icon_tab04.png'
import icon_tab_active01 from '@/assets/img/order/icon_tab_active01.png'
import icon_tab_active02 from '@/assets/img/order/icon_tab_active02.png'
import icon_tab_active03 from '@/assets/img/order/icon_tab_active03.png'
import icon_tab_active04 from '@/assets/img/order/icon_tab_active04.png'
import { Message } from 'element-ui'
/**
 * 标签页集合
 */
const tabList = {
  BASE: {
    name: '基础信息',
    component: "BaseInfo",
    required: true,
    icon: icon_tab01,
    iconActive: icon_tab_active01
  },
  BASE_RADIO: {
    name: '基本信息&影像数据',
    component: "BaseInfo",
    required: true,
    icon: icon_tab01,
    iconActive: icon_tab_active01
  },
  QUESTION: {
    name: '问诊信息',
    component: "QuestionContentFuzhu|QuestionContentZuhan",
    required: true,
    icon: icon_tab02,
    iconActive: icon_tab_active02
  },
  REDIO: {
    name: '影像数据',
    component: "VideoInfo",
    required: true,
    icon: icon_tab03,
    iconActive: icon_tab_active03
  },
  REPORT: {
    name: '报告数据',
    component: "ReportInfo",
    required: true,
    icon: icon_tab04,
    iconActive: icon_tab_active04
  }
}
/**
 * 新建时标签页集合
 */
export const addTabList = {
  1: {
    tabList: [tabList.BASE],
    fileUpload: {
      mode: '文件上传/寄送',
      isSend: true,
      requireSend: true,
      children: [
        {
          mode: '其他文件',
          mapping: 'fileReport.其他文件.children.其他文件',
          required: true,
          failMsg: '请上传其他文件',
          limit: ['rar', 'tiff', 'stl', 'dcm', 'png', 'jpg', 'pdf'],
          files: []
        },
        {
          mode: '上传面扫',
          mapping: 'imageReport.全景片、面扫.children.面扫',
          required: false,
          showCondition: 'showUpload.ms==true',
          failMsg: '请上传面扫文件',
          limit: ['png'],
          files: []
        }
      ]
    }
  },
  2: {
    tabList: [tabList.BASE, tabList.QUESTION],
    fileUpload: {
      mode: '上/下颌牙列数据',
      desc: '请确认咬合关系',
      isSend: true,
      children: [
        {
          mode: '上颌牙列数据',
          mapping: 'fileReport.上/下颌牙列数据.children.上颌牙列数据',
          required: true,
          requireSendNumber: true,
          failMsg: '请上传上颌牙列数据',
          limit: ['stl'],
          files: []
        },
        {
          mode: '下颌牙列数据',
          mapping: 'fileReport.上/下颌牙列数据.children.下颌牙列数据',
          required: true,
          requireSendNumber: true,
          failMsg: '请上传下颌牙列数据',
          limit: ['stl'],
          files: []
        },
        {
          mode: '睡眠监测报告',
          mapping: "dataReport.报告.children.睡眠监测报告",
          limit: ['pdf'],
          files: []
        }
      ]
    }
  },
  3: {
    tabList: [tabList.BASE, { ...tabList.QUESTION, required: false }],
    fileUpload: {
      mode: '上/下颌牙列数据',
      isSend: true,
      children: [
        {
          mode: '上颌牙列数据',
          mapping: 'fileReport.上/下颌牙列数据.children.上颌牙列数据',
          required: true,
          requireSendNumber: true,
          failMsg: '请上传上颌牙列数据',
          limit: ['stl'],
          files: []
        },
        {
          mode: '下颌牙列数据',
          mapping: 'fileReport.上/下颌牙列数据.children.下颌牙列数据',
          required: true,
          requireSendNumber: true,
          failMsg: '请上传下颌牙列数据',
          limit: ['stl'],
          files: []
        }
      ]
    }
  },
  4: {
    tabList: [tabList.BASE_RADIO, tabList.QUESTION]
  },
  5: {
    tabList: [tabList.BASE, tabList.QUESTION],
    fileUpload: {
      mode: '报告',
      desc: '',
      isSend: false,
      children: [
        {
          mode: '睡眠监测报告',
          mapping: 'dataReport.报告.children.睡眠监测报告',
          required: false,
          // failMsg: '请上传睡眠监测报告',
          limit: ['pdf'],
          files: []
        }
      ]
    }
  }
}
/**
 * 详情时标签页集合
 */
export const detailTabList = {
  1: {
    tabList: [tabList.BASE, tabList.REDIO, tabList.REPORT]
  },
  2: {
    tabList: [tabList.BASE, tabList.QUESTION, tabList.REDIO, tabList.REPORT]
  },
  3: {
    tabList: [tabList.BASE, tabList.QUESTION, tabList.REDIO, tabList.REPORT]
  },
  4: {
    tabList: [tabList.BASE_RADIO, tabList.QUESTION, tabList.REPORT]
  }
}

/**
 * 拷贝上传文件信息到表单信息中
 * 
 * @param {表单信息} formData 
 * @param {文件上传信息} fileUpload 
 * @param {反向拷贝} reverse 
 */
export function copyFileUpload(formData, fileUpload, reverse) {
  if (fileUpload) {
    fileUpload.children.forEach(element => {
      if (element.mapping) {
        const plyObj = getPlyObj(formData, element.mapping)
        if (plyObj) {
          if (reverse) {
            // 反向拷贝
            element.files = plyObj.files
          } else {
            plyObj.files = element.files
          }
        }
      }
    });
  }
}
/**
 * 得到层级对象
 * 
 * @param {数据} data 
 * @param {对象名} fieldName 
 * @returns 
 */
function getPlyObj(data, fieldName) {
  const fieldNames = fieldName.split(".")
  let result = getFieldObj(data, fieldNames[0]);
  for (let index = 1; index < fieldNames.length; index++) {
    result = getFieldObj(result, fieldNames[index])
  }
  return result
}
/**
 * 得到字段对应的对象
 * @param {数据} data 
 * @param {对象名} fieldName 
 * @returns 
 */
function getFieldObj(data, fieldName) {
  if (!data) {
    return
  }
  if (data instanceof Array) {
    return data.find(item => item.mode == fieldName)
  }
  return data[fieldName]
}

/**
 * 生成子对象
 * 
 * @param {名字集合}} name 
 * @param {是否有空值} isNullValue 
 */
export function createChildren(name, isNullValue) {
  const children = []
  const names = name.split("|")
  for (let index = 0; index < names.length; index++) {
    const element = names[index];
    children.push({
      mode: element,
      order: index + 1,
      files: isNullValue ? [{
        name: '',
        path: '',
        size: ''
      }] : []
    })
  }
  return children
}
/**
 * 生成子对象，支持定义属性
 * 
 * @param {子对象集合} 动态参数 
 */
export function createChildOption() {
  const children = []
  for (let i = 0; i < arguments.length; i++) {
    children.push({
      order: i + 1,
      // 如果存在children 不添加files
      ...(arguments[i].children ? null : { files: [] }),
      ...arguments[i]
    })
  }
  return children
}
/**
 * 拷贝相同的子节点
 * 
 * @param {原始对象} fileUpload 
 * @param {当前对象} currentFileUpload 
 */
export function copySameChild(fileUpload, currentFileUpload, showUpload) {
  if (fileUpload.children) {
    // 得到可以展示的子节点
    const childs = fileUpload.children.filter((item) => {
      if (item.showCondition) {
        return eval(item.showCondition)
      }
      return true
    })
    if (currentFileUpload) {
      const newChild = []
      // 将原来的值拷贝到新的节点
      childs.forEach((child) => {
        const findChild = currentFileUpload.children.find(
          (item) => item.mode == child.mode
        )
        if (findChild) {
          newChild.push(findChild)
        } else {
          newChild.push(child)
        }
      })
      fileUpload.children = newChild
    } else {
      fileUpload.children = childs
    }
  }
  return fileUpload
}


/**
 * 报告校验配置
 */
const dataReportValid = {
  1: {
    4: {
      mode: '报告.children.3D导板',
      failMsg: "3D导板"
    }
  },
  2: {
    2: {
      mode: '报告.children.睡眠监测报告',
      failMsg: "睡眠监测报告"
    },
    11: {
      mode: '报告.children.设计报告',
      failMsg: "设计报告"
    }
  },
  3: {
    13: {
      mode: '报告.children.设计报告',
      failMsg: "设计报告"
    }
  },
  4: {
    16: {
      mode: '报告.children.辅助分析报告',
      failMsg: "辅助分析报告"
    },
    13: {
      mode: '报告.children.颌垫方案',
      failMsg: "颌垫方案"
    }
  }
}
/**
 * 校验报告必填
 * @param {表单数据} formData 
 * @returns 
 */

export function validReportData(formData) {
  const { orderType, status, dataReport } = formData
  // 得到需要校验的报告名称
  if (orderType && status && dataReportValid[orderType]) {
    const reportConfig = dataReportValid[orderType][status]
    if (reportConfig) {
      const reportItem = getPlyObj(dataReport, reportConfig.mode)
      if (reportItem && (!reportItem.files || reportItem.files.length == 0 || !reportItem.files[0].path)) {
        Message({
          type: 'error',
          message: '请上传' + reportConfig.failMsg
        })
        return false
      }
    }
  }
  return true
}