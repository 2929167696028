<template>
  <el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="demo-ruleForm">
    <el-form-item prop="pwd">
      <el-input
        prefix-icon="el-icon-unlock"
        type="password"
        v-model="ruleForm.pwd"
        placeholder="*输入新密码"
      ></el-input>
    </el-form-item>
    <el-form-item prop="pwdConfirm">
      <el-input
        prefix-icon="el-icon-unlock"
        type="password"
        v-model="ruleForm.pwdConfirm"
        placeholder="*确认新密码"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import * as loginApi from '@/api/login'
export default {
  name: 'FillPassword',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.pwdConfirm !== '') {
          this.$refs.ruleForm.validateField('pwdConfirm')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        pwd: '',
        pwdConfirm: ''
      },
      rules: {
        pwd: [{ validator: validatePass, trigger: 'blur' }],
        pwdConfirm: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  mounted() {},
  methods: {
    // 修改密码
    updatePwd(updatePwdCode, callback) {
      try {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            loginApi.updatePwd({ updatePwdCode, pwd: this.ruleForm.pwd }).then((res) => {
              if (callback) {
                callback(res.data)
              }
            })
          } else {
            return false
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  ::v-deep .el-input {
    .el-icon-unlock {
      color: $fontDeepColor;
      font-size: 18px;
    }
  }
}
</style>
