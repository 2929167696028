<script>
export default {
  name: 'OrderListTag',
  props: {
    tagName: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isShowTooltip: false
    }
  },

  methods: {
    onMouseOver(e) {
      const { clientWidth, scrollWidth } = this.$refs['contentRef']
      if (clientWidth < scrollWidth) {
        this.isShowTooltip = false
      } else {
        this.isShowTooltip = true
      }
    }
  },
  render() {
    const tagList = this.tagName.map((tag) => {
      return <el-tag style="margin: 0px 0px 5px 5px;" size="small">{tag}</el-tag>
    })
    return (
      <el-tooltip class="item" effect="dark" disabled={this.isShowTooltip} placement="top-start">
        <div slot="content" style="max-width: 400px">{tagList}</div>
        <div
          ref="contentRef"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
          onMouseover={(e) => this.onMouseOver(e)}
        >
          {tagList}
        </div>
      </el-tooltip>
    )
  }
}
</script>

<style lang="scss" scoped>
</style>
