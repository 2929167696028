<template>
  <div class="order-list">
    <div class="com-page-nav"><span>权限管理 &gt;</span> 角色列表</div>
    <div class="main-area">
      <div class="filter-bar">
        <div class="btn-box">
          <el-button
            class="com-primary-btn"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="handleCreate"
            >添加角色</el-button
          >
        </div>
      </div>
      <el-table v-loading="loading" :data="list" style="width: 100%; ">
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column prop="createdAt" label="创建时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain size="small" @click="handlePri(scope.row)"
              >权限设置</el-button
            >
            <template v-if="scope.row.id > 4">
              <el-button type="primary" plain size="small" @click="handleUpdate(scope.row)"
                >编辑</el-button
              >
              <el-button type="primary" plain size="small" @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.current"
        :limit.sync="listQuery.size"
        @pagination="getList"
      />
    </div>

    <role-detail :title="title" :show="show" @flushList="flushList" :row="currentRow"></role-detail>
    <pri ref="rolePri"></pri>
  </div>
</template>

<script>
import RoleDetail from './detail.vue'
import Pri from './pri.vue'
import ListUtils from '@/mixins/list-utils'
import * as roleApi from '@/api/role'
export default {
  name: 'User',
  components: {
    RoleDetail,
    Pri
  },
  mixins: [ListUtils],
  data() {
    return {
      roleId: undefined,
      searchForm: {
        orderBy: 'created_at',
        orderMode: 1
      }
    }
  },
  created() {
    this.title = '角色详情'
    this.getList()
  },
  methods: {
    /** 查询角色列表 */
    getList() {
      this.loading = true
      roleApi.list({ ...this.listQuery, ...this.searchForm }).then((res) => {
        const { rows, total } = res.data
        this.list = rows
        this.total = total
        this.loading = false
      })
    },
    flushList() {
      this.show.userDetail = false
      this.getList()
    },
    handleCreate() {
      this.currentRow = {}
      this.title = '创建角色'
      this.show.userDetail = true
    },
    handlePri(row) {
      this.$refs['rolePri'].init(row)
    },
    handleUpdate(row) {
      this.currentRow = row
      this.title = '角色详情'
      this.show.userDetail = true
    },
    handleDelete(row) {
      this.$confirm('是否确认删除?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return roleApi.del(row.id)
        })
        .then(() => {
          this.getList()
          this.$message({ showClose: true, message: '删除成功', type: 'success' })
        })
        .catch(function () {})
    }
  }
}
</script>

<style lang="scss" scoped>
.order-list {
  .main-area {
    .filter-bar {
      padding: 22px;
      background-color: #fff;
      .btn-box {
        margin-bottom: 12px;
        .com-primary-btn {
          // background-color: $deepPrimaryColor;
        }
      }
    }
  }
}
</style>
