<!--
 * @Author: Ellie
 * @Date: 2022-02-11
 * @LastEditTime: 2022-02-11
 * @LastEditors: Please set LastEditors
 * @Description: 问诊管理
 * @FilePath: src/views/questionnaire/index.vue
-->
<template>
  <div class="questionnaire-view">
    <div class="com-page-nav">{{ $route.meta.title }}</div>
    <ul class="card-list">
      <li>
        <div class="text-box">
          <h2><img src="@/assets/img/question/wenjuan.png">辅助分析</h2>
          <p>用于：</p>
          <p>1.颌垫类订单</p>
          <p>2.辅助分析订单</p>
        </div>
        <el-button @click="viewDetail('QuestionContentFuzhu')" type="primary" plain>查看</el-button>
      </li>
      <li>
        <div class="text-box">
          <h2><img src="@/assets/img/question/wenjuan.png">阻鼾器问诊</h2>
          <p>用于：</p>
          <p>1.阻鼾器类订单</p>
        </div>
        <el-button @click="viewDetail('QuestionContentZuhan')" type="primary" plain>查看</el-button>
      </li>
    </ul>
    <el-dialog :title="questionName" :visible.sync="dialogFormVisible" width="1000px">
      <component :is="activeTab"></component>
    </el-dialog>
  </div>
</template>

<script>
import QuestionContentZuhan from './QuestionContentZuhan.vue'
import QuestionContentFuzhu from './QuestionContentFuzhu.vue'
export default {
  name: 'QuestionnaireView',
  components: {
    QuestionContentZuhan,
    QuestionContentFuzhu
  },
  data() {
    return {
      dialogFormVisible: false,
      activeTab: 'QuestionContentZuhan'
    }
  },
  computed: {
    questionName() {
      switch(this.activeTab) {
        case 'QuestionContentZuhan':
          return '阻鼾器' + '问诊详情'
        case 'QuestionContentFuzhu':
          return '辅助分析' + '问诊详情'
        default:
          return '问诊详情'
      }
    }
  },
  mounted() {

  },
  methods: {
    viewDetail(name) {
      this.dialogFormVisible = true
      this.activeTab = name
    }
  }
}
</script>

<style lang="scss" scoped>
.questionnaire-view {
  padding: 20px;
  background: #FFFFFF;
  .card-list {
    margin-top: -30px;
    display: flex;
    flex-wrap: wrap;
    li {
      margin: 20px 35px 0 0;
      position: relative;
      width: 280px;
      height: 240px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
      .text-box {
        padding: 20px;
        color: $fontDeepColor;
        h2 {
          margin-bottom: 16px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 25px;
          display: flex;
          align-items: center;
          img {
            margin-right: 12px;
          }
        }
        p {
          font-size: 14px;
          line-height: 26px;
        }
      }
      .el-button {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 40px;
        border-radius: 0px;
        border: none;
      }
    }
  }
}
</style>
