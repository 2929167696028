<template>
  <div class="image-view">
    <div class="com-page-nav">{{ $route.meta.title }}</div>
    <div class="main-area com-panel-area" v-loading="loading">
      <div class="filter-bar">
        <h2 class="h2-title">订单信息</h2>
        <div class="filter-box">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="订单类型">
              <el-select v-model="searchForm.orderType" clearable>
                <el-option
                  v-for="item in ORDER_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择标签">
              <el-select v-model="searchForm.tags" multiple clearable>
                <el-option
                  v-for="item in customTags"
                  :key="item.id"
                  :label="item.tagName"
                  :value="item.id"
                ></el-option>
                <!-- <el-option v-for="item in ORDER_TYPE" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="photo-area">
      <div class="photo-list">
        <div class="border-box" v-for="(item, i) in imageList" :key="i">
          <div class="photo">
            <el-image fit="scale-down" :src="item.path" :preview-src-list="[item.path]" ref="previewImg"></el-image>
          </div>
          <p class="text">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <pagination
      :total="total"
      :page.sync="listQuery.current"
      :limit.sync="listQuery.size"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { ORDER_TYPE } from '@/utils/constants.js'
import ListUtils from '@/mixins/list-utils'
import * as tags from '@/api/tags.js'
import { getImageList } from '@/api/data-manage.js'
export default {
  name: 'ImageView',
  mixins: [ListUtils],
  data() {
    return {
      ORDER_TYPE,
      customTags: [],
      searchForm: {
        orderType: '',
        tags: []
      },
      imageList: [],
      total: 0
    }
  },
  mounted() {
    this.getList()
    this.getTagList()
  },
  methods: {
    getList() {
      this.loading = true
      getImageList({
        ...this.listQuery,
        ...this.searchForm
      })
        .then((res) => {
          const { rows, total } = res.data
          this.imageList = rows || []
          this.total = total
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 获取标签列表
    getTagList() {
      tags.getTagsList({ type: 2 }).then((res) => {
        this.customTags = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-view {
  .main-area {
    margin-bottom: 10px;
    .filter-bar {
      .h2-title {
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 500;
        color: $fontDeepColor;
        line-height: 20px;
      }
    }
  }
  .photo-area {
    padding: 32px 24px;
    background-color: #fff;
    .photo-list {
      margin-top: -36px;
      display: flex;
      flex-wrap: wrap;
      .border-box {
        margin: 36px 32px 0 0;
      }
      .photo {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        width: 240px;
        height: 160px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dddddd;
        position: relative;
        ::v-deep .el-image {
          display: block;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .text {
        text-align: center;
        line-height: 20px;
      }
    }
  }
}
</style>
