/**
 * 常量定义
 */
export const ADDRESS = {
  from: [
    '北京市 大兴区 旧宫镇广德大街20号中科电商谷8号楼729，为开数字，15010212236',
    '广州市 海珠区 江燕路99号万科派1416室，为开数字，18666024417'
  ],
  to: [
    '北京市 大兴区 旧宫镇广德大街20号中科电商谷8号楼729，为开数字，15010212236',
    '广州市 海珠区 江燕路99号万科派1416室，为开数字，18666024417'
  ],
}
/**
 * 用户在职状态
 */
export const USER_STATUS = [
  {
    label: '在职',
    value: 1
  },
  {
    label: '离职',
    value: 2
  }
]
/**
 * 用户启用状态
 */
export const USER_ABLES = [
  {
    label: '启用',
    value: 1
  },
  {
    label: '已禁用',
    value: 2
  }
]
/**
 * 用户性别
 */
export const USER_GENDER = [
  {
    label: '男',
    value: 1
  },
  {
    label: '女',
    value: 2
  }
]
/**
 * 用户职位
 */
export const USER_POSITION = [
  {
    label: '业务员',
    value: 'Y'
  },
  {
    label: '工程师',
    value: 'G'
  },
  {
    label: '管理员',
    value: 'A'
  }
]
/**
 * 菜单状态
 */
export const MENU_STATUS = [
  {
    label: '正常',
    value: '0'
  },
  {
    label: '停用',
    value: '1'
  }
]
/**
 * 菜单可见状态
 */
export const MENU_VISIBLE = [
  {
    label: '显示',
    value: '0'
  },
  {
    label: '隐藏',
    value: '1'
  }
]
/**
 * 菜单类型
 */
export const MENU_TYPE = [
  {
    label: '目录',
    value: 'M'
  },
  {
    label: '菜单',
    value: 'C'
  },
  {
    label: '按钮',
    value: 'F'
  }
]
/**
 * 订单类型
 */
export const ORDER_TYPE = [
  {
    label: '外科类订单',
    value: 1
  },
  {
    label: '阻鼾器类订单',
    value: 2
  },
  {
    label: '颌垫类订单',
    value: 3
  },
  {
    label: '辅助分析类订单',
    value: 4
  }
]
/**
 * 支付状态
 */
export const PAY_STATUS = [
  {
    label: '已支付',
    value: 1
  },
  {
    label: '未支付',
    value: 0
  }
]
/**
 * 订单状态
 */
export const ORDER_STATUS = [
  {
    label: '已完成',
    value: 1
  },
  {
    label: '未完成',
    value: 0
  }
]
/**
 * 交付状态
 */
export const STATUS = [
  {
    label: '暂存未提交',
    value: 0
  },
  {
    label: '待分配',
    value: 1
  },
  {
    label: '订单待处理',
    value: 2
  },
  {
    label: '设计手术方案中',
    value: 3
  },
  {
    label: '设计导板中',
    value: 4
  },
  {
    label: '审核过单',
    value: 5
  },
  {
    label: '确认方案和价格中',
    value: 6
  },
  {
    label: '手术模板和导板打印中',
    value: 7
  },
  {
    label: '交付已完成（手术模板和导板打印已完成） ',
    value: 8
  },
  {
    label: '物品寄送中',
    value: 9
  },
  {
    label: '物品已签收',
    value: 10
  },
  {
    label: '制作阻鼾器中',
    value: 11
  },
  {
    label: '交付已完成（方案和价格已确认）',
    value: 12
  },
  {
    label: '设计颌垫方案中',
    value: 13
  },
  {
    label: '制作颌垫中',
    value: 14
  },
  {
    label: '交付已完成（颌垫制作已完成）',
    value: 15
  },
  {
    label: '辅助分析处理中',
    value: 16
  },
  {
    label: '交付已完成（辅助分析已完成）',
    value: 17
  }
]
/**
 * 收费方式/支付方式
 */
export const PAY_METHOD = [
  {
    label: '对公转账',
    value: 1
  },
  {
    label: '预付款储值',
    value: 2
  },
  {
    label: '微信',
    value: 3
  },
  {
    label: '支付宝',
    value: 4
  }
]
/**
 * 快递方式
 */
export const SEND_TYPE = [
  {
    label: '顺丰快递',
    value: '顺丰快递'
  },
  {
    label: '京东快递',
    value: '京东快递'
  },
  {
    label: '中通快递',
    value: '中通快递'
  },
  {
    label: '闪送',
    value: '闪送'
  },
  {
    label: '自送',
    value: '自送'
  }
]
/**
 * 收款类型
 */
export const PAY_MODE = [
  {
    label: '系统内',
    value: 0
  },
  {
    label: '系统外',
    value: 1
  }
]
