/* eslint-disable no-extend-native */
'use strict'
// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(H)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd HH:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d H:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}

/**
  * 全局增加水印工具类
  * TODO 拉取全局水印配置
  */
const watermark = {}
/**
 * 初始配置
 * params :
 *  width: 150,//宽度
    height: 120,//高度
    rotate: 180,//角度
    font: '16px Arial',//字体
    fillStyle: '#dddddd',//颜色
    textAlign: 'left',//文本水平对齐
    textBaseline:'Middle',//文本垂直对齐
 *
 */
let options = {
  width: 220, // 宽度
  height: 180, // 高度
  rotate: -20, // 角度
  font: '20px 微软雅黑', // 字体
  fillStyle: 'rgb(0,0,0,0.1)', // 颜色
  textAlign: 'left', // 文本水平对齐
  textBaseline: 'Middle'// 文本垂直对齐
}

const setWatermark = (str) => {
  const id = '1.23452384164.123412415'
  const water = document.getElementById("water")
  if (document.getElementById(id) !== null) {
    water.removeChild(document.getElementById(id))
  }

  if (!water) {
    return
  }
  const can = document.createElement('canvas')
  can.width = options.width
  can.height = options.height

  const cans = can.getContext('2d')
  cans.rotate(options.rotate * Math.PI / 180)
  cans.font = options.font
  cans.fillStyle = options.fillStyle
  cans.textAlign = options.textAlign
  cans.textBaseline = options.textBaseline
  cans.fillText(str, can.width / 3, can.height / 2)
  cans.globalAlpha = 0.5
  const div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '80px'
  div.style.left = '10px'
  div.style.position = 'absolute'
  div.style.zIndex = '100000'
  div.style.width = water.clientWidth + 'px'
  div.style.height = water.clientHeight + 'px'
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  water.appendChild(div)
  return id
}
// 修改水印配置
watermark.setOptions = (o) => {
  options = Object.assign(options, o)
}
// 该方法只允许调用一次
watermark.set = (str) => {
  let id = setWatermark(str)
  setInterval(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(str)
    }
  }, 500)
  window.onresize = () => {
    setWatermark(str)
  }
}

export default watermark
