<template>
  <div class="report-info">
    <div class="grey-box grey-box-mar" v-for="(item, i) in formData.dataReport" :key="i">
      <h2 class="h2-title-border">
        <span>{{ item.mode }}</span>
      </h2>
      <video-file-list :fileArr="item.children" :mode="item.mode"></video-file-list>
    </div>
  </div>
</template>

<script>
import VideoFileList from './VideoFileList.vue'
export default {
  name: 'ReportInfo',
  components: {
    VideoFileList
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    orderType: {
      type: Number,
      default: 0
    }
  },
  provide() {
    const { isCalDownload, isCanUpload, isCanDel } = this.formData
    return {
      // 获取字段数据时需要sourceId
      isDisabled: this.isDisabled,
      isCalDownload,
      isCanUpload,
      isCanDel,
      formData: this.formData
    };
  },
  data() {
    return {}
  },
  computed: {
    isDisabled() {
      // isCanEdit：0: 不可编辑； 1: 可编辑
      return this.formData.isCanEdit === 0
    }
  },
  mounted() {
    this.handleDataReport()
  },
  methods: {
    // 【订单列表】辅助分析报告，颌垫方案文件，需要医生确定制作颌垫流程后，才可以显示
    handleDataReport() {
      if (this.orderType === 4 && this.formData.needJaw !== 1) {
        this.formData.dataReport[0].children = this.formData.dataReport[0].children.filter(item => item.mode !== '颌垫方案')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.report-info {
  padding: 0 20px 20px;
  .grey-box {
    background: #f9f9f9;
    &-mar {
      & + .grey-box {
        margin-top: 16px;
      }
    }
    .h2-title,
    .h2-title-border {
      padding: 16px 20px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      border-bottom: 1px solid #dddddd;
    }
  }
}
</style>
