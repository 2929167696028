<template>
  <div class="order-list">
    <div class="com-page-nav"><span>权限管理 &gt;</span> 菜单列表</div>
    <div class="main-area">
      <div class="filter-bar">
        <div class="btn-box">
          <el-button
            class="com-primary-btn"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="handleCreate"
            >添加菜单</el-button
          >
        </div>
      </div>

      <el-table
        v-loading="loading"
        :data="list"
        row-key="id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          prop="menuName"
          label="菜单名称"
          :show-overflow-tooltip="true"
          width="160"
        />
        <el-table-column prop="icon" label="图标" align="center" width="100">
          <template slot-scope="scope">
            <svg-icon :icon-class="scope.row.icon" />
          </template>
        </el-table-column>
        <el-table-column prop="orderNum" label="排序" width="60" />
        <el-table-column prop="perms" label="权限标识" :show-overflow-tooltip="true" />
        <el-table-column prop="component" label="组件路径" :show-overflow-tooltip="true" />
        <el-table-column prop="status" label="状态" :formatter="statusFormat" width="80" />
        <el-table-column label="创建时间" align="center" prop="createdAt">
          <template slot-scope="scope">
            <span>{{ scope.row.createdAt }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="primary" plain size="small" @click="handleUpdate(scope.row)">编辑</el-button>
            <el-button type="primary" plain size="small" @click="handleAdd(scope.row)">新增</el-button>
            <el-button type="primary" plain size="small" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <menu-detail
      :title="title"
      :show="show"
      @flushList="flushList"
      :row="currentRow"
      :parentId="parentId"
    ></menu-detail>
  </div>
</template>

<script>
import { MENU_STATUS } from '@/utils/constants.js'
import MenuDetail from './detail.vue'
import ListUtils from '@/mixins/list-utils'
import * as menuApi from '@/api/menu'
import { handleTree } from '@/utils/index'
export default {
  name: 'User',
  components: {
    MenuDetail
  },
  mixins: [ListUtils],
  data() {
    return {
      parentId: undefined,
      searchForm: {
        menuName: undefined,
        visible: undefined
      }
    }
  },
  created() {
    this.title = '菜单详情'
    this.getList()
  },
  methods: {
    statusFormat: function (row) {
      return this.showLabel(MENU_STATUS, row.status)
    },
    getList() {
      this.loading = true
      menuApi.queryAll({ ...this.searchForm }).then((res) => {
        this.list = handleTree(res.data, 'id')
        this.loading = false
      })
    },
    flushList() {
      this.currentRow = {}
      this.show.userDetail = false
      this.getList()
    },
    handleCreate() {
      this.currentRow = {}
      this.parentId = 0
      this.show.userDetail = true
    },
    handleAdd(row) {
      this.currentRow = {}
      this.parentId = row.id
      this.title = '添加菜单'
      this.show.userDetail = true
    },
    handleUpdate(row) {
      this.currentRow = row
      this.title = '菜单详情'
      this.show.userDetail = true
    },
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.menuName + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return menuApi.del(row.id)
        })
        .then(() => {
          this.getList()
          this.$message({ showClose: true, message: '删除成功', type: 'success' })
        })
        .catch(function () {})
    }
  }
}
</script>

<style lang="scss" scoped>
.order-list {
  .main-area {
    .filter-bar {
      padding: 22px;
      background-color: #fff;
      .btn-box {
        margin-bottom: 12px;
        .com-primary-btn {
          // background-color: $deepPrimaryColor;
        }
      }
    }
  }
}
</style>
