<template>
  <!-- 添加或修改参数配置对话框 -->
  <el-dialog :title="title" :visible.sync="show.userDetail" width="600px" append-to-body>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="form.roleName" placeholder="请输入角色名称" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as roleApi from '@/api/role'
import { deepClone } from '@/utils/index'
export default {
  name: 'RoleDetail',
  props: {
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {},
      initForm: {
        id: '',
        roleName: ''
      },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
      }
    }
  },
  watch: {
    show: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal.userDetail) {
          this.init()
        }
      }
    }
  },
  methods: {
    init() {
      this.reset()
      this.detail(this.row)
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.form = deepClone(this.initForm)
    },
    detail(row) {
      if (row && row.id) {
        roleApi.detail(row.id).then((res) => {
          this.form = res.data
        })
      }
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          roleApi.save(this.form).then((res) => {
            this.$message({ showClose: true, message: '保存成功', type: 'success' })
            this.$emit('flushList')
          })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.show.userDetail = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
