import request from '@/utils/request'

// 获取创建订单首页数据
export function createIndex(data) {
  return request({
    url: '/web/order/create/index',
    method: 'post',
    data
  })
}

// 订单列表
export function orderList(data) {
  return request({
    url: '/web/order/list',
    method: 'post',
    data
  })
}

// 创建订单/订单详情-外科订单
export function WaikeOrder(data, detail) {
  return request({
    url: `/web/order/${detail ? 'detail' : 'create'}/surgery`,
    method: 'post',
    data
  })
}

// 创建订单/订单详情-阻鼾器订单
export function ZuhanqiOrder(data, detail) {
  return request({
    url: `/web/order/${detail ? 'detail' : 'create'}/snore`,
    method: 'post',
    data
  })
}

// 创建订单/订单详情-颌垫类订单
export function HedianOrder(data, detail) {
  return request({
    url: `/web/order/${detail ? 'detail' : 'create'}/jaw`,
    method: 'post',
    data
  })
}

// 创建订单/订单详情-辅助分析订单
export function FuzhuOrder(data, detail) {
  return request({
    url: `/web/order/${detail ? 'detail' : 'create'}/diagnosis`,
    method: 'post',
    data
  })
}

// 订单-操作
export function orderOperate(data) {
  return request({
    url: '/web/order/operator',
    method: 'post',
    data
  })
}

// 寄送地址
export function sendAddressList(data) {
  return request({
    url: '/web/order/sendAddressList',
    method: 'post',
    data
  })
}

// 收款明细
export function collectionList(data) {
  return request({
    url: '/web/order/collection/list',
    method: 'post',
    data
  })
}

// 订单结账-生成结果
export function settlePre(data) {
  return request({
    url: '/web/order/settle/pre',
    method: 'post',
    data
  })
}

// 操作记录
export function getActionLog(data) {
  return request({
    url: '/log/list',
    method: 'post',
    data
  })
}

// 生成支付地址
export function createPayUrl(params) {
  return request({
    url: '/web/order/settle/createPayUrl',
    method: 'get',
    params
  })
}

// 订单-操作支付方式
export function operatePayMethod(data) {
  return request({
    url: '/web/order/operatePayMethod',
    method: 'post',
    data
  })
}

// 订单-订单已有标签
export function orderTag(data) {
  return request({
    url: '/web/order/orderTag',
    method: 'post',
    data
  })
}


// 订单-编辑标签
export function editTag(data) {
  return request({
    url: '/web/order/editTag',
    method: 'post',
    data
  })
}
