<template>
  <div class="video-file-list">
    <div class="upload-area" v-for="(item, i) in fileArr" :key="i">
      <video-file-single :fileObj="item"></video-file-single>
    </div>
    <div class="popup-box" v-if="isSend">
      <template v-if="formData && formData.dentalSendNumber">
        <el-button class="com-primary-btn" style="margin-top: 5px" type="primary" icon="el-icon-view" @click="viewStatus">查看物流状态</el-button>
        <div class="show-info">
          <p class="text">寄送方式：{{ formData.dentalSendType }}</p>
          <p class="text">快递单号：{{ formData.dentalSendNumber }}</p>
        </div>
      </template>
      <el-button v-else type="primary" @click="sendDentalCast" :disabled="isAdmin">快递下单</el-button>
      <send-dialog :show="show"></send-dialog>
    </div>
  </div>
</template>

<script>
import SendDialog from '../send-dental/SendDialog.vue'
import VideoFileSingle from './VideoFileSingle.vue'
export default {
  name: 'VideoFileList',
  props: {
    isSend: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ''
    },
    fileArr: {
      type: Array,
      default: () => {
        return []
      }
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    SendDialog,
    VideoFileSingle
  },
  data() {
    return {
      show: {
        dialogVisible: false
      },
      fileList: []
    }
  },
  computed: {
    isAdmin() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      return userInfo.currentRole === '管理员'
    }
  },
  mounted() {},
  methods: {
    sendDentalCast() {
      this.show.dialogVisible = true
    },
    viewStatus() {
      window.open(
        'https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/' +
          this.formData.dentalSendNumber
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.video-file-list {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  .upload-area {
    margin-right: 40px;
    width: 240px;
    ::v-deep .el-upload {
      display: block;
    }
  }
  .popup-box {
    .el-button {
      margin-top: 20px;
    }
  }
  .show-info {
    margin-top: 5px;
    max-width: 200px;
    p {
      word-wrap: break-word;
      word-break: normal;
    }
    .text {
      color: #333;
      & + .text {
        margin-top: 8px;
      }
    }
  }
}
</style>
