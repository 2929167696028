<template>
  <div :class="['video-img-single', {'is-dragging' : isDragging}]" @dragover="dragOverHandle($event)" @drop="dropHandle" @dragleave="dragLeave">
    <img :src="defaultUrl" alt="" v-if="!currentImgInfo.path" />
    <el-image
      v-else
      fit="scale-down"
      :src="currentImgInfo.path || defaultUrl"
      :preview-src-list="[currentImgInfo.path]"
      ref="previewImg"
    >
    </el-image>
    <div class="com-opt-bar" v-if="currentImgInfo.path">
      <img @click="handlePreview" src="@/assets/img/common/icon_preview.png" alt="" />
      <img v-if="isCalDownload || !detail" @click="handleDownload" src="@/assets/img/common/icon_download.png" alt="" />
      <img v-if="isCanDel || !detail" @click="handleDelete" src="@/assets/img/common/icon_delete.png" alt="" />
    </div>
  </div>
</template>

<script>
import photo_side from '@/assets/img/order/photo_side.png'
import photo_front from '@/assets/img/order/photo_front.png'
import photo_smile from '@/assets/img/order/photo_smile.png'
import photo_tooth_left from '@/assets/img/order/photo_tooth_left.png'
import photo_tooth_all from '@/assets/img/order/photo_tooth_all.png'
import photo_tooth_right from '@/assets/img/order/photo_tooth_right.png'
import photo_hook_face from '@/assets/img/order/photo_hook_face.png'
import photo_head from '@/assets/img/order/photo_head.png'
// import photo09 from '@/assets/img/order/photo09.png'
import { downloadImg } from '@/utils/download.js'
export default {
  name: 'VideoImgSingle',
  props: {
    mode: {
      type: String,
      default: ''
    },
    currentImgInfo: {
      type: Object,
      default: () => {}
    }
  },
  inject: {
    isDisabled: { default: false },
    isCalDownload: { default: 1 },
    isCanDel: { default: 1 },
    detail: { default: false }
  },
  data() {
    return {
      defaultUrl: '',
      isDragging: false, // 拖拽进入添加class样式
      dragImgInfo: {},
    }
  },
  mounted() {
    this.chooseDefaultImg()
    this.$bus.$on('getDragImgInfo', item => {
      this.dragImgInfo = item
    })
  },
  methods: {
    downloadImg,
    // 预览图片
    handlePreview() {
      this.$refs.previewImg.showViewer = true
    },
    // 下载图片
    handleDownload() {
      this.downloadImg(this.currentImgInfo)
    },
    // 删除图片
    handleDelete() {
      this.currentImgInfo.path = ''
    },
    // 拖拽进入
    dragOverHandle(e) {
      this.isDragging = true
      e.preventDefault()
    },
    // 拖拽离开
    dragLeave() {
      this.isDragging = false
    },
    // 拖拽结束
    dropHandle() {
      // console.log(this.currentImgInfo, this.dragImgInfo, 'imgInfo')
      this.isDragging = false
      this.currentImgInfo.path = this.dragImgInfo.path
    },
    chooseDefaultImg() {
      switch(this.mode) {
        case '90度侧面部影像':
          this.defaultUrl = photo_side
          break;
        case '正面部正常影像':
          this.defaultUrl = photo_front
          break;
        case '正面部微笑影像':
          this.defaultUrl = photo_smile
          break;
        case '左侧后牙咬合影像':
          this.defaultUrl = photo_tooth_left
          break;
        case '全牙列正面咬合影像':
          this.defaultUrl = photo_tooth_all
          break;
        case '右侧后牙咬合影像':
          this.defaultUrl = photo_tooth_right
          break;
        case '曲面断层片':
          this.defaultUrl = photo_hook_face
          break;
        case '头颅正位片':
          this.defaultUrl = photo_head
          break;
        case '头颅侧位片':
          this.defaultUrl = photo_head
          break;
        case '正面下颌最大前伸像':
          this.defaultUrl = photo_front
          break;
        case '90度侧面下颌最大前伸影像':
          this.defaultUrl = photo_side
          break;
        case '正面部拉钩像':
          this.defaultUrl = photo_smile
          break;
        case 'CT':
          this.defaultUrl = photo_head
          break;
        case '面扫':
          this.defaultUrl = photo_head
          break;
        default:
          this.defaultUrl = photo_front
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-img-single {
  margin-bottom: 8px;
  width: 240px;
  height: 160px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dddddd;
  position: relative;
  &.is-dragging {
    border: 1px dashed #dddddd;
  }
  // .opt-bar {
  //   padding: 0 16px;
  //   display: flex;
  //   justify-content: right;
  //   align-items: center;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 32px;
  //   background: rgba(0, 0, 0, 0.6);
  //   box-sizing: border-box;
  //   img {
  //     margin-left: 16px;
  //     cursor: pointer;
  //     &:hover {
  //       opacity: 0.7;
  //     }
  //   }
  // }
  ::v-deep .el-image {
    display: block;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}
</style>
