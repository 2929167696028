<template>
  <div class="login-box">
    <div class="left-box">
      <div class="left-logo"><img src="@/assets/img/login/left_logo.png" alt="" /></div>
      <div class="left-photo"><img src="@/assets/img/login/left_photo.png" alt="" /></div>
    </div>
    <div class="right-box">
      <h2>用户登录</h2>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="username">
          <el-input v-model="ruleForm.username" prefix-icon="el-icon-user" placeholder="请输入用户名" size="small"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="ruleForm.password" prefix-icon="el-icon-unlock" autocomplete="off" placeholder="请输入密码" size="small"></el-input>
        </el-form-item>
        <el-form-item class="forget-pwd"><span @click="toForgetPwd">忘记密码?</span></el-form-item>
        <el-form-item>
          <el-form-item>
            <el-button class="login-button" type="primary" :loading="loading" @click="submitForm('ruleForm')">登录</el-button>
          </el-form-item>
        </el-form-item>
        <el-form-item class="privacy-msg">
          <el-checkbox v-model="privacyChecked">
            <span style="color: #888888">点击同意</span>
          </el-checkbox>
          <span @click="toPrivacy(true)"> 《隐私条款》</span>
          <span style="color: #888888"> 和 </span>
          <span @click="toPrivacy(false)"> 《服务协议》</span>
        </el-form-item>
      </el-form>
    </div>
    <change-role-view :showRoleDialog="showRoleDialog" :roles="roles" @handleRouterLink="handleRouterLink"></change-role-view>
  </div>
</template>

<script>
import ChangeRoleView from './components/index/ChangeRoleView.vue'
export default {
  name: 'LoginIndex',
  components: {
    ChangeRoleView
  },
  data() {
    return {
      // 隐私协议
      privacyChecked: false,

      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: { required: true, message: '请输入密码', trigger: 'blur' }
      },
      loading: false,
      showRoleDialog: {
        dialogVisible: false
      },
      roles: []
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // return
          if (!this.privacyChecked) {
            this.$confirm(
              '感谢您使用颌一在线服务，登录时将为您自动注册您的账号，以下条款将帮助您了解颌一在线的隐私政策、服务协议以及相关权利。您可以通过《隐私条款》《服务协议》查阅完整内容。',
              { confirmButtonText: '同意', cancelButtonText: '取消', type: 'warning' }
            )
              .then(() => {
                this.privacyChecked = true
              })
              .catch(() => {})
            return
          }
          this.$store
            .dispatch('Login', this.ruleForm)
            .then(() => {
              const { roles } = this.userInfo
              if (roles.length > 1) {
                this.roles = roles
                console.log(this.showRoleDialog.dialogVisible)
                this.showRoleDialog.dialogVisible = true
              } else {
                this.handleRouterLink()
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toForgetPwd() {
      this.$router.push({
        path: '/forget'
      })
    },

    // 跳转协议
    toPrivacy(isPrivacy) {
      const { href } = this.$router.resolve({
        path: '/privacy',
        query: { privacy: isPrivacy ? '1' : '0' }
      })
      window.open(href, '_blank')
    },

    // 验证协议
    validPrivacy() {
      this.$message
    },

    // 根据不同用户跳转链接
    handleRouterLink(currentRole) {
      if (currentRole === '医生') {
        this.$router.push({
          path: '/order/create'
        })
      } else {
        this.$router.push({
          path: '/'
        })
      }
      this.showRoleDialog.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login-box {
  position: static;
  z-index: 1;
  max-width: 980px;
  padding: 40px 4.2% 40px 20px;
  width: 67%;
  height: 60%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  img {
    width: 100%;
  }
  .left-box {
    overflow: hidden;
    width: 540px;
    max-width: calc(60% - 62px);
    .left-logo {
      margin: 0 0 42px 2px;
      width: 44%;
    }
    .left-photo {
      // width: 95%;
    }
  }
  .right-box {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      margin-bottom: 40px;
      font-size: 28px;
      color: #333333;
      line-height: 50px;
    }
    $height: 48px;
    ::v-deep .el-form {
      .forget-pwd {
        margin-top: -20px;
        text-align: right;
        span {
          color: $primaryColor;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .privacy-msg {
        margin-top: -20px;
        text-align: left;
        span {
          color: $primaryColor;
          font-size: 12px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .el-input {
        i {
          font-size: 16px;
          color: #333;
        }
        .el-input__inner {
          height: $height;
          font-size: 16px;
          color: #333;
        }
      }
      .login-button {
        // margin-top: 45px;
        width: 100%;
        height: $height;
        font-size: 20px;
        font-weight: normal;
      }
    }
  }
}
</style>
