<template>
  <!-- 添加或修改参数配置对话框 -->
  <el-dialog :title="title" :visible.sync="show.userDetail" width="600px" append-to-body>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="姓名" prop="userName">
        <el-input v-model="form.userName" placeholder="请输入姓名" />
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号码" maxlength="11" />
      </el-form-item>
      <el-form-item v-if="showPwd" label="登录密码" prop="pwd">
        <el-input v-model="form.pwd" placeholder="请输入登录密码" type="password" />
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="form.gender">
          <el-radio v-for="dict in USER_GENDER" :key="dict.value" :label="dict.value">{{
            dict.label
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="title !== '医生详情'" label="职位" prop="position">
        <el-select v-model="form.position" placeholder="请选择职位" style="width: 100%">
          <el-option
            v-for="dict in USER_POSITION"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <template v-if="userType == 1">
        <InputList
          v-model="form.company"
          label="单位"
          prop="company"
          placeholder="请输入单位名称"
        ></InputList>
        <InputList
          v-model="form.address"
          label="收货地址"
          prop="address"
          :showPop="true"
          placeholder="请输入地址"
        ></InputList>
        <el-form-item label="订单权限" prop="orderAuth">
          <el-checkbox-group v-model="orderAuths">
            <el-checkbox v-for="dict in ORDER_TYPE" :key="dict.value" :label="dict.value">{{
              dict.label
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </template>

      <el-form-item label="状态">
        <el-radio-group v-model="form.status">
          <el-radio v-for="dict in USER_STATUS" :key="dict.value" :label="dict.value">{{
            dict.label
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="启用状态">
        <el-radio-group v-model="form.useAble">
          <el-radio v-for="dict in USER_ABLES" :key="dict.value" :label="dict.value">{{
            dict.label
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="角色" v-if="title !== '医生详情'" prop="roleIds">
        <el-select v-model="form.roleIds" multiple placeholder="请选择" style="width: 100%">
          <el-option
            v-for="item in roleOptions"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
            :disabled="item.status == 1"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  USER_ABLES,
  USER_STATUS,
  USER_POSITION,
  USER_GENDER,
  ORDER_TYPE
} from '@/utils/constants.js'
import * as userApi from '@/api/user'
import * as roleApi from '@/api/role'
import { deepClone } from '@/utils/index'
import InputList from './components/input-list'
export default {
  name: 'UserDetail',
  components: { InputList },
  props: {
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    userType: {
      type: Number,
      default: 0
    },
    show: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    const validateOrderAuth = (rule, value, callback) => {
      if (this.orderAuths && this.orderAuths.length > 0) {
        callback()
      } else {
        callback(new Error('订单权限不能为空'))
      }
    }
    return {
      USER_ABLES,
      USER_STATUS,
      USER_POSITION,
      USER_GENDER,
      ORDER_TYPE,
      initForm: {
        userName: undefined,
        gender: undefined,
        phone: undefined,
        pwd: undefined,
        roleIds: [],
        position: undefined,
        address: undefined,
        company: undefined,
        orderAuth: '',
        status: 1,
        useAble: 1
      },
      orderAuths: [],
      showPwd: false,
      form: {},
      roleOptions: [],
      rules: {
        userName: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        pwd: [{ required: true, message: '登录密码不能为空', trigger: 'blur' }],
        gender: [{ required: true, message: '性别不能为空', trigger: 'blur' }],
        position: [{ required: true, message: '职位不能为空', trigger: 'blur' }],
        orderAuth: [{ validator: validateOrderAuth, trigger: 'change' }],
        company: [{ required: true, message: '单位不能为空', trigger: 'change' }],
        address: [{ required: true, message: '收货地址不能为空', trigger: 'change' }],
        roleIds: [{ required: true, message: '角色不能为空', trigger: 'blur' }]
      }
    }
  },
  watch: {
    show: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal.userDetail) {
          this.init()
        }
      }
    }
  },
  methods: {
    init() {
      this.reset()
      this.detail(this.row)
      this.getRoleOptions()
    },
    reset() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.orderAuths = []
      this.form = deepClone(this.initForm)
    },
    detail(row) {
      if (row && row.id) {
        this.showPwd = false
        userApi.detail(row.id).then((res) => {
          this.form = res.data
          this.orderAuths = this.form.orderAuth ? this.form.orderAuth.split(',').map(Number) : []
        })
      } else {
        this.showPwd = true
      }
    },
    getRoleOptions() {
      this.loading = true
      roleApi.queryRoleAll().then((res) => {
        this.roleOptions = res.data
      })
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.userType = this.userType || 0
          this.form.orderAuth = this.orderAuths.join(',')
          userApi.save(this.form).then((res) => {
            this.$message({ showClose: true, message: '保存成功', type: 'success' })
            this.$emit('flushList')
          })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.show.userDetail = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
