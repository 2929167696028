<template>
  <div class="question-info">
    <el-form ref="elForm" :model="questionReport" :rules="rules" :disabled="isDisabled" size="medium" label-width="100px" label-position="top">
      <el-form-item label="1.打鼾时间多久了？" prop="dhsjdjl">
        <el-input v-model="questionReport.dhsjdjl" placeholder="请输入内容"> </el-input>
      </el-form-item>
      <el-form-item label="2.打鼾程度" prop="dhcd">
        <el-radio-group v-model="questionReport.dhcd" size="medium">
          <el-radio v-for="(item, index) in dhcdOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="3.有无全身系统疾病？" prop="ywqsxtjb">
        <el-radio-group v-model="questionReport.ywqsxtjb" size="medium">
          <el-radio v-for="(item, index) in ywqsxtjbOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
        <el-input v-model="questionReport.ywqsxtjbDesc" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item label="4.有无明显鼻咽喉疾病？" prop="ywmxbyhjb">
        <el-radio-group v-model="questionReport.ywmxbyhjb" size="medium">
          <el-radio v-for="(item, index) in ywmxbyhjbOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
        <el-input v-model="questionReport.ywmxbyhjbDesc" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item label="5. 有无颞下颌关节紊乱（TMD）" prop="ywnxhgjwl">
        <el-radio-group v-model="questionReport.ywnxhgjwl" size="medium">
          <el-radio v-for="(item, index) in ywnxhgjwlOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
        <el-input v-model="questionReport.ywnxhgjwlDesc" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item label="6.有无佩戴呼吸机或其他止鼾器？" prop="ywpdhxjdqtzhq">
        <el-radio-group v-model="questionReport.ywpdhxjdqtzhq" size="medium">
          <el-radio v-for="(item, index) in ywpdhxjdqtzhqOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
        <el-input v-model="questionReport.ywpdhxjdqtzhqDesc" placeholder="佩戴多久了"></el-input>
      </el-form-item>
      <el-form-item label="7.您知道自己为什么打鼾吗？" prop="nzdzjwsmdhm">
        <el-checkbox-group v-model="questionReport.nzdzjwsmdhm" size="medium">
          <el-checkbox v-for="(item, index) in nzdzjwsmdhmOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-checkbox>
        </el-checkbox-group>
        <el-input v-model="questionReport.nzdzjwsmdhmDesc" placeholder="其他备注"></el-input>
      </el-form-item>
      <el-form-item label="8.有无去医院做过睡眠监测？" prop="ywqyyzgsmjc">
        <el-radio-group v-model="questionReport.ywqyyzgsmjc" size="medium">
          <el-radio v-for="(item, index) in ywqyyzgsmjcOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
        <el-input v-model="questionReport.ywqyyzgsmjcDesc" placeholder="请输入AHI指数"></el-input>
      </el-form-item>
      <el-form-item label="9.有无确诊OSAS?" prop="ywqzosas">
        <el-radio-group v-model="questionReport.ywqzosas" size="medium">
          <el-radio v-for="(item, index) in ywqzosasOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="10.有无假牙、龋齿、牙周炎等牙周问题？" prop="ywjyqcyzy">
        <el-radio-group v-model="questionReport.ywjyqcyzy" size="medium">
          <el-radio v-for="(item, index) in fywjyqcyzyOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
        <div :class="['grey-box', {'grey-box-disabled' : isDisabled}]">
          <p class="text"><span>*</span>请先选择类型，再勾选位置</p>
          <ul class="rect-list">
            <li v-for="(item, i) in questionReport.toothTypeArr" :key="item.id" @click="selectToothType(item, i)">
              <div :class="['select-box', { 'select-box-active': questionReport.isChoose === i }]" :style="{ background: item.color }">
                <i class="el-icon-check" v-if="questionReport.isChoose === i"></i>
              </div>
              <p class="desc">{{ item.name }}</p>
            </li>
          </ul>
          <ul class="tooth-list">
            <li v-for="(item, i) in questionReport.toothListArr" :key="i" @click="selectToothDetail(item, i)">
              <div :class="['select-box', { 'select-box-active': item.checked }]" :style="{
                  background: item.type !== '' ? questionReport.toothTypeArr[item.type].color : '#ffffff'
                }">
                <i class="el-icon-check" v-if="item.checked"></i>
              </div>
              <p class="desc">{{ item.id }}</p>
            </li>
          </ul>
        </div>
      </el-form-item>
      <el-form-item label="11.有无拔牙或者做修复体的打算？" prop="ywbyhzzxftdds">
        <el-radio-group v-model="questionReport.ywbyhzzxftdds" size="medium">
          <el-radio v-for="(item, index) in ywbyhzzxftddsOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
        <el-input v-model="questionReport.ywbyhzzxftddsDesc" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item label="12.下颌最大前伸长度（mm）" prop="xhzdqscd">
        <el-input v-model="questionReport.xhzdqscd" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="13.有无夜磨牙？" prop="ywymy">
        <el-radio-group v-model="questionReport.ywymy" size="medium">
          <el-radio v-for="(item, index) in ywymyOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import order from '@/mixins/order.js'
export default {
  name: 'QuestionContentZuhan',
  mixins: [order],
  props: {
    formData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      questionReport: {
        dhsjdjl: '',
        dhcd: '',
        ywqsxtjb: '',
        ywmxbyhjb: '',
        ywnxhgjwl: '',
        ywpdhxjdqtzhq: '',
        nzdzjwsmdhm: [],
        ywqyyzgsmjc: '',
        ywqzosas: '',
        ywjyqcyzy: '',
        ywbyhzzxftdds: '',
        xhzdqscd: '',
        ywymy: '',

        ywqsxtjbDesc: '',
        ywmxbyhjbDesc: '',
        ywnxhgjwlDesc: '',
        ywpdhxjdqtzhqDesc: '',
        nzdzjwsmdhmDesc: '',
        ywqyyzgsmjcDesc: '',
        ywbyhzzxftddsDesc: '',

        toothTypeArr: [
          {
            id: 1,
            name: '假牙',
            color: '#00AEA5'
          },
          {
            id: 2,
            name: '龋齿',
            color: '#FA6400'
          },
          {
            id: 3,
            name: '牙周炎',
            color: '#7F20E0'
          }
        ],
        isChoose: 0,
        toothListArr: [
          {
            id: 18,
            checked: false,
            type: ''
          },
          {
            id: 17,
            checked: false,
            type: ''
          },
          {
            id: 16,
            checked: false,
            type: ''
          },
          {
            id: 15,
            checked: false,
            type: ''
          },
          {
            id: 14,
            checked: false,
            type: ''
          },
          {
            id: 13,
            checked: false,
            type: ''
          },
          {
            id: 12,
            checked: false,
            type: ''
          },
          {
            id: 11,
            checked: false,
            type: ''
          },
          {
            id: 21,
            checked: false,
            type: ''
          },
          {
            id: 22,
            checked: false,
            type: ''
          },
          {
            id: 23,
            checked: false,
            type: ''
          },
          {
            id: 24,
            checked: false,
            type: ''
          },
          {
            id: 25,
            checked: false,
            type: ''
          },
          {
            id: 26,
            checked: false,
            type: ''
          },
          {
            id: 27,
            checked: false,
            type: ''
          },
          {
            id: 28,
            checked: false,
            type: ''
          },

          {
            id: 48,
            checked: false,
            type: ''
          },
          {
            id: 47,
            checked: false,
            type: ''
          },
          {
            id: 46,
            checked: false,
            type: ''
          },
          {
            id: 45,
            checked: false,
            type: ''
          },
          {
            id: 44,
            checked: false,
            type: ''
          },
          {
            id: 43,
            checked: false,
            type: ''
          },
          {
            id: 42,
            checked: false,
            type: ''
          },
          {
            id: 41,
            checked: false,
            type: ''
          },
          {
            id: 31,
            checked: false,
            type: ''
          },
          {
            id: 32,
            checked: false,
            type: ''
          },
          {
            id: 33,
            checked: false,
            type: ''
          },
          {
            id: 34,
            checked: false,
            type: ''
          },
          {
            id: 35,
            checked: false,
            type: ''
          },
          {
            id: 36,
            checked: false,
            type: ''
          },
          {
            id: 37,
            checked: false,
            type: ''
          },
          {
            id: 38,
            checked: false,
            type: ''
          }
        ]
      },
      rules: {},
      dhcdOptions: [
        {
          label: '比正常呼吸时响',
          value: '比正常呼吸时响'
        },
        {
          label: '同说话时一样响',
          value: '同说话时一样响'
        },
        {
          label: '比说话时响',
          value: '比说话时响'
        },
        {
          label: '非常响，其他房间都能听到',
          value: '非常响，其他房间都能听到'
        }
      ],
      ywqsxtjbOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '有',
          value: '有'
        }
      ],
      ywmxbyhjbOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '有',
          value: '有'
        }
      ],
      ywnxhgjwlOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '轻度',
          value: '轻度'
        },
        {
          label: '中度',
          value: '中度'
        },
        {
          label: '重度',
          value: '重度'
        }
      ],
      ywpdhxjdqtzhqOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '有',
          value: '有'
        }
      ],
      nzdzjwsmdhmOptions: [
        {
          label: '过度劳累',
          value: '过度劳累'
        },
        {
          label: '扁桃体肿大',
          value: '扁桃体肿大'
        },
        {
          label: '呼吸道狭窄',
          value: '呼吸道狭窄'
        },
        {
          label: '肥胖',
          value: '肥胖'
        },
        {
          label: '鼻炎',
          value: '鼻炎'
        },
        {
          label: '睡姿不正',
          value: '睡姿不正'
        },
        {
          label: '其他',
          value: '其他'
        }
      ],
      ywqyyzgsmjcOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '有',
          value: '有'
        }
      ],
      ywqzosasOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '有',
          value: '有'
        }
      ],
      fywjyqcyzyOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '有',
          value: '有'
        }
      ],
      ywbyhzzxftddsOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '有',
          value: '有'
        }
      ],
      ywymyOptions: [
        {
          label: '无',
          value: '无'
        },
        {
          label: '有',
          value: '有'
        },
        {
          label: '不清楚',
          value: '不清楚'
        }
      ]
    }
  },
  computed: {},
  created() {
    this.getQuestionDetail()
  },
  mounted() {},
  methods: {
    getQuestionDetail() {
      if (!this.formData || !this.formData.questionReport) {
        return
      }
      if (Object.keys(this.formData.questionReport).length !== 0) {
        this.questionReport = JSON.parse(this.formData.questionReport)
        this.formData.questionReport = this.questionReport
      } else {
        this.formData.questionReport = this.questionReport
      }
    },
    selectToothType(item, i) {
      this.questionReport.isChoose = i
    },
    selectToothDetail(item, i) {
      // item.checked = true
      // item.type = this.questionReport.isChoose
      if (item.checked == true && item.type === this.questionReport.isChoose) {
        item.checked = false
        item.type = ''
      } else {
        item.checked = true
        item.type = this.questionReport.isChoose
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form {
  .el-form-item__label,
  .el-input__inner,
  .el-radio,
  .el-checkbox {
    color: #333;
  }
  .el-form-item {
    .el-input {
      .el-input__inner {
        width: 300px;
      }
    }
  }
}
.question-info {
  .grey-box {
    padding: 20px;
    overflow-x: auto;
    background-color: #f0f0f0;
    &-disabled {
      pointer-events: none;
    }
    .text {
      margin-bottom: 15px;
      line-height: 22px;
      span {
        color: $--color-danger;
      }
    }
    .select-box {
      width: 17px;
      height: 17px;
      opacity: 0.3;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      &.select-box-active {
        opacity: 1;
      }
      i {
        color: #fff;
      }
    }
    .desc {
      margin-left: 4px;
      color: #333333;
      line-height: 22px;
    }
    .rect-list {
      margin-bottom: 23px;
      display: flex;
      li {
        margin-right: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .select-box {
          width: 17px;
          height: 17px;
          opacity: 0.3;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.select-box-active {
            opacity: 1;
          }
          i {
            color: #fff;
          }
        }
      }
    }
    .tooth-list {
      margin-top: -16px;
      display: flex;
      width: 930px;
      flex-wrap: wrap;
      position: relative;
      &::before {
        position: absolute;
        top: 46px;
        left: -10px;
        width: 100%;
        border-top: 1px solid #dddddd;
        content: '';
      }
      &::after {
        position: absolute;
        top: 10px;
        left: 452px;
        height: 100%;
        border-left: 1px solid #dddddd;
        content: '';
      }
      li {
        margin: 16px 21px 0 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        .select-box {
          border: 1px solid #979797;
          opacity: 1;
          &.select-box-active {
            border: none;
          }
        }
        .desc {
          margin-left: 3px;
          width: 17px;
        }
      }
    }
  }
}
</style>
